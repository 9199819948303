.dropzone-component {
  min-height: 3rem;
  border-width: 1px;
  border-style: dashed;

  .drag-effect {
    .guide {
      opacity: #{$opacity-high-emphasis};
    }
  }

  .hover-effect {
    &:hover {
      .guide {
        opacity: #{$opacity-high-emphasis};
      }
    }
  }

  input {
    display: none;
  }
}