.grid-layout-row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  align-items: start;
  @include media-breakpoint-down(md) {
    grid-template-columns: repeat(1, 1fr);
  }

  .grid-layout-col {
    width: 100%;
    height: 100%;
    max-width: 100%;
    //overflow: hidden;
    padding-bottom: 0.325rem;
  }
}

.preview-image {
  overflow: auto;
  display: flex;
  justify-content: center;
  padding: 1rem;

  img {
    //width: 100%;
  }
}