//.ratio-container {
//  position: relative;
//  height: 0;
//  overflow: hidden;
//}

//.ratio-frame {
//  position: absolute;
//  top: 0;
//  left: 0;
//  width: 100%;
//  height: 100%;
//}

//Audio Player
//interface - inline
$audio-theme-color: #fdb832;
$audio-theme-font-color: rgba(255, 255, 255);
$audio-theme-border-color: rgba(255, 255, 255, $opacity-medium-emphasis);
$audio-slider-thumb: 15px;

.mini-audio-player {
  max-width: 150px !important;
}

.audio-theme {
  background-color: #{$audio-theme-color};
  color: #{$audio-theme-font-color};
  font-weight: 400;

  .audio-play-button {
    color: #{$audio-theme-color};
    width: 42px;
    height: 42px;
    padding-left: 2px;

    &.isPlaying {
      padding-left: 0px;
    }

    svg {
      width: 28px;
      height: 28px;
    }
  }

  .border-top {
    border-color: #{$audio-theme-border-color} !important;
  }

  .audio-timeline {
    .audio-time-ranger {
      margin-top: 8px;
    }


  }

  .audio-volumn {
    svg {
      width: 28px;
      height: 28px;
    }
  }

  .range-label {
    background: white;
    color: #d38353;
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
    position: absolute;
    pointer-events: none;
    text-align: center;
    width: 45px;
    height: 22px;
    border-radius: 5px;
    top: -8px;
  }

  //share
  .slider {
    margin-bottom: 3px;
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.4);
    outline: none;
    -webkit-transition: .2s;
    transition: opacity .2s;

    &.fill-color {
      background-image: linear-gradient(#fff, #fff);
      background-repeat: no-repeat;
    }

    &.max-range-size {
      max-width: 150px;
    }

  }

  .audio-speed-indicator, .audio-time-indicator {
    font-size: 14px;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: #{$audio-slider-thumb};
    height: #{$audio-slider-thumb};
    background: #{$audio-theme-font-color};
    border-radius: 50%;
    cursor: pointer;
  }

  .slider::-moz-range-thumb {
    width: #{$audio-slider-thumb};
    height: #{$audio-slider-thumb};
    background: #{$audio-theme-font-color};
    border-radius: 50%;
    cursor: pointer;
  }
}

//interface - restrict