$fake-ckeditor: "../../img/demo/fake-ckeditor.png";
$ckeditor-min-height: 250px;

.min-height-300 {
  .ck-content {
    min-height: 300px;
  }
}

$draw-button-padding-left: 15px;
$draw-button-wide-padding-left: -80px;
$draw-button: 40px;
$draw-button-md: 32px;
$draw-svg: 20px;
$draw-svg-md: 15px;
$draw-group-svg: 40px;
$draw-group-svg-md: 32px;
$draw-color: 25px;
$draw-color-md: 20px;
.draw-container {
  &.draw-editor-fullscreen {
    position: fixed;
    //
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1050;
    background-color: rgba(0, 0, 0, 0.8);
    padding-top: 0;

    @include noselect;
    @include nodrag;

    .draw-editor-container {
      position: relative;
      width: 100%;
      height: auto;
      padding-top: 75%; /*56.25%;  16:9 Aspect Ratio */
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.draw-container {
  position: relative;
  width: 100%;
  padding-top: 75%; /*56.25%;  16:9 Aspect Ratio */
}

.draw-editor {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: center;

  .draw-editor-content {
    float: left;
    display: inline-flex;
    width: calc(100% - 6rem);
    height: calc(100% - 6rem);

    @include media-breakpoint-down(md) {
      width: calc(100%);
      height: calc(100%);
    }

    canvas {
      //image-rendering: pixelated
    }
  }
}
.drawing-layer-border{
  border: #{$drawinglayer-border} dashed 4px;
  display: inline-block;
  padding: 12px 0;
  width: 992px;
}
.drawing-layer-toolbar{
  float: none !important;
}
.sticky-drawing-tool{
  position:sticky;
  top:66px;
}
.drawing-layer-question{
  height: #{$draw-button};
  @include media-breakpoint-down(md) {
    height: #{$draw-button-md};
  }

}
.draw-editor-toolbar {
  &:not(.label-diagram-toolbar) {
    @include media-breakpoint-up(md) {
      float: right;
      left: 0;
    }
  }

  display: inline-flex;
  //position: absolute;
  position: relative;
  //bottom: -5px;
  //height: #{$draw-button + 30px};}

  &.label-diagram-toolbar {
    svg {
      width: #{$draw-svg};
      height: #{$draw-svg};
      @include media-breakpoint-down(md) {
        width: #{$draw-svg} !important;
        height: #{$draw-svg} !important;
      }
    }
  }

  &.wider-screen {
    position: absolute;
    top: 0;
    right: -130px;
    left: auto;
    width: auto;
    height: auto;

    .draw-editor-toolbar-container {
      grid-template-columns: 1fr 1fr;
    }

    .draw-brush-width {
      border: none !important;
    }
  }

  @include media-breakpoint-down(md) {
    top: 0.5rem;
    float: unset;
    width: 100%;
    //height: #{$draw-button-md + 20px};
  }

  .draw-editor-toolbar-container {
    background-color: #{$openended-toolbar-bg};
    box-shadow: 0px 4px 4px #{$shadow};
    gap: 1rem;
    @include media-breakpoint-down(md) {
      gap: 0.2rem;
    }

    &.label-diagram-toolbar-container-shadow {
      box-shadow: 0px 1px 4px #{$shadow};
    }
  }

  .draw-editor-tool {
    width: #{$draw-button};
    height: #{$draw-button};
    justify-content: center;
    display: flex;
    border-radius: 0.5rem;

    &:not(.draw-color):not(.draw-brush-width) {
      &:hover, &.active {
        background-color: $openended-toolbar-active;
        color: white;

        svg {
          color: white;
        }
      }
    }

    &.draw-brush-width {
      //border-left: 1px #cccccc solid;
      //border-radius: 0rem;

      svg {
        width: 30px;
        height: 30px;
      }
    }

    &.draw-color {

      .color-pantone-container {
        background-image: url("../../img/color/blank_transparent.png");
        background-repeat: repeat;
        width: #{$draw-color};
        height: #{$draw-color};
        border: 2px #cccccc solid;
        border-radius: 0.5rem;
        display: block;
        @include media-breakpoint-down(md) {
          width: #{$draw-color-md};
          height: #{$draw-color-md};
        }
      }

      // .color-pantone {


      // }
    }


    &.selected-object-trash {
      svg {
        color: #{$openended-toolbar-trash};
      }
    }

    &.draw-add-a-blank {
      transform: none;
      width: auto;
      height: auto;

      &:hover {
        * {
          color: white;
          transform: none;
        }
      }
    }

    &.selected {
      background-color: $openended-toolbar-active;
      box-shadow: inset 0 0 10px $openended-toolbar-selected;
      color: white;

      svg {
        color: white;
      }
    }

    @include media-breakpoint-down(md) {
      width: #{$draw-button-md};
      height: #{$draw-button-md};
    }

    svg {
      width: #{$draw-svg};
      height: #{$draw-svg};
      @include media-breakpoint-down(md) {
        width: #{$draw-svg-md};
        height: #{$draw-svg-md};
      }
    }
  
    .draw-group{
      padding: 0;
      svg {
        width: #{$draw-group-svg};
        height: #{$draw-group-svg};
        @include media-breakpoint-down(md) {
          width: #{$draw-group-svg-md};
          height: #{$draw-group-svg-md};  
      }
      }
    }
    
    .draw-editor-tool-text{
      font-size: #{$h9-font-size};
      text-align: center;
    }
  }

 .draw-bg{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 10px 8px;
    width: auto;
    height: auto;
    gap: 0.325rem;

    button{
      padding: 0;
    }

  }

  .flex-basis {
    flex-basis: 100%;
  }
}

.draw-option-divider {
  border-bottom: 1px #ccc solid;
}

.draw-image-option {
  padding: 0.325rem;
  border-radius: 0.5rem;
  position: relative;

  &:first-child {
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: -0.5rem;
      height: 100%;
      border-right: 1px #cccccc solid;
    }
  }

  &.remove-background-image {
    color: #{$openended-toolbar-trash};
  }

  &.active, &:hover {
    background-color: $openended-toolbar-active;
    color: white;
  }
}

.draw-option {
  padding: 0.325rem;
  border-radius: 0.5rem;
  position: relative;

  &.active, &:hover {
    background-color: $openended-toolbar-active;
    color: white;
  }
}

.label-diagram-image-item {
  @include fib-setting;
}

.label-the-diagram-editor {
  counter-reset: css-counter 0;

  .label-the-diagram-container {
    &:empty {
      min-height: 300px;
    }

    @include media-breakpoint-down(md) {
      max-height: 500px;
    }

    display: grid;
    overflow: hidden;
    position: relative;
    overscroll-behavior: contain;
    touch-action: none;

    &:active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }

    img {
      @include nodrag;
      @include noselect;
    }

  }

  .draw-editor-toolbar {
    bottom: 0;
  }

  //v2
  .fib-answer-container {
    display: block !important;
    white-space: pre-wrap !important;
    word-break: break-word;
  }

  .label-the-diagram-answer-box {

    [contenteditable=true] {
      word-break: break-word;
    }
  }

  .right-container {
    &.marked {
      .fib-model-answer-container {
        display: none !important;
      }

      .label-the-diagram-answer-container {
        .cus-drop, &::after {
          //display: none !important;
        }

        .drop-items {
          max-width: none;
        }
      }

      .fib-answer-container {
        + .d-flex {
          display: none !important;
        }
      }
    }
  }

  .bottom-container {
    .label-the-diagram-answer-container {
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    &.marked {
      .fib-answer-container {
        width: fit-content;

        &:not(.fib-model-answer-container) {
          display: none !important;
          @include media-breakpoint-down(md) {
            display: block !important;

            &.fib-correct {
              + .fib-model-answer-container {
                display: none !important;
              }
            }
          }
        }
      }

      .fib-model-answer-container {
        margin: 0 0 0 0 !important;
        @include media-breakpoint-down(md) {
          margin: 1rem 0 0 0 !important;
        }
      }

      .label-the-diagram-answer-container {
        display: inline-flex !important;
        @include media-breakpoint-down(md) {
          display: flex !important;
          > .fib-answer-container {
            display: none !important;
          }
        }
      }


    }
  }
}

.label-diagram-image-item {
  display: flex;
  justify-content: center;
  flex-direction: row;

  [data-type=fib-field] {
    overflow: inherit;
  }

  &.marked {
    .fib-border {
      background-color: white;
      border: none;
    }

    &.active {
      .fib-border {
        border: none;
      }
    }

    [data-type="fib-field"] {
      > .fib-indicator {
        display: none;
        @include media-breakpoint-down(md) {
          display: block;
        }
      }
    }

    .fib-answer-container {
      .fib-indicator {
        background-color: transparent !important;
        border: none !important;

        &::before {
          display: none !important;
        }
      }
    }

    .label-the-diagram-answer-container {
      > div {
        > span {
          display: inline-flex;
          white-space: nowrap;
          word-break: break-all;
        }
      }
    }
  }

  //&:not(.reverse){
  //  [data-type=fib-field]{
  //    border-top-left-radius: 0 !important;
  //    border-bottom-left-radius: 0 !important;
  //  }
  //}

  &.active {
    z-index: 1;

    .fib-align-tool {
      //display: block;
    }

    //[data-type="fib-field"] {
    //  border: 2px #{$fib-active} solid;
    //}
    .fib-indicator {
      color: #{$label-the-diagram-active};
      background-color: #{$label-the-diagram-default-bg};
      border: 1px #{$label-the-diagram-active} solid;
    }

    .fib-border {
      border: 1px #{$label-the-diagram-active} solid;
    }

    &:not(.reverse) {
      .fib-indicator {

      }

      .fib-border {
        border-left: none;
      }
    }

    &.reverse {


      .fib-border {
        border-right: none;
      }
    }
  }

  &.reverse {
    flex-direction: row-reverse;
    justify-content: end;
    width: 100%;


    .fib-indicator {

      padding: 0.5rem 0.5rem 0.5rem 0.5rem;

      //border-top-right-radius: var(--bs-border-radius) !important;
      //border-bottom-right-radius: var(--bs-border-radius) !important;
    }

    [data-type=fib-field] { //.fib-border,
      flex-direction: row-reverse;
    }

    .fib-content {
      justify-content: end;
    }
  }

  .fib-indicator {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem !important;

    &.rounded-md-end {
      border-top-right-radius: 0.375rem !important;
      border-bottom-right-radius: 0.375rem !important;
    }

    &.rounded-md-start {
      border-top-left-radius: 0.375rem !important;
      border-bottom-left-radius: 0.375rem !important;
    }

    @include media-breakpoint-down(md) {
      cursor: pointer;
      min-width: 2rem;
      justify-content: center;
      display: flex;
      &.preview-round {
        border-radius: 0.375rem !important;
      }
    }
  }

  .fib-border {
    padding: 0 !important;


    position: relative;
    padding-left: 0;
    border: 1px #{$label-the-diagram-default-bg} solid;


    &::after {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 0.5rem;
      margin-left: 0;
    }
  }

  .fib-content {
    min-width: 100px;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    line-height: normal !important;

    p, [contenteditable] {
      line-height: normal !important;
    }

    [contenteditable] {
      padding: 0;
    }

    .ck-editor__editable_inline {
      min-height: auto;
      padding: 0;
    }
  }


  //v2
  .fib-indicator {
    border-radius: 0.375rem !important;
    cursor: pointer;
  }

  .fib-border {
    display: none !important;
  }
}

.label-the-diagram-answer-container {
  .label-the-diagram-indicator-box {
    color: #cccccc;

    &.focused {
      color: #000000;
    }
  }

  .label-the-diagram-answer-box {
    border-bottom: 1px #cccccc solid;
    color: #cccccc;
    min-width: 200px;

    &.empty {
      max-width: 100%;
    }

    &.focused {
      color: #000000;
      border-bottom: 1px #000000 solid;
    }

    .ck-editor__editable_inline {
      line-height: normal !important;
      min-height: auto !important;
      padding: 0;
    }

    [contenteditable] {
      outline: none;
    }
  }
}


.brush-width {
  padding: 0.325rem;
  border-radius: 0.5rem;

  &.active, &:hover {
    background-color: $openended-toolbar-active;

    div {
      background-color: white !important;
    }
  }
}

//div[contenteditable="false"] {
//  &:empty {
//    min-height: calc(#{$h6-font-size} * 2.60);
//    -webkit-line-break: after-white-space;
//    border: 1px solid transparent;
//  }
//}
//
//div[contenteditable="true"] {
//  position: relative;
//
//  &.paragraph {
//    min-height: $ckeditor-min-height;
//  }
//
//  &:focus {
//    outline: 0px solid transparent;
//
//    //&::before {
//    //  content: '';
//    //  position: absolute;
//    //  background-image: url("#{$fake-ckeditor}");
//    //  background-repeat: no-repeat;
//    //  background-size: contain;
//    //  z-index: 1020;
//    //  top: -30px;
//    //  left: 0;
//    //  height: 30px;
//    //  overflow: hidden;
//    //  width: 100%;
//    //}
//  }
//}

.ck-editor__editable_inline {
  min-height: calc(#{$h6-font-size} * 3.0);
  //&.ck-focused {
  //  border: none !important;
  //  outline: none !important;
  //}
}

.ck {
  &.ck-editor {
    width: 100% !important;
  }

  .ck-toolbar {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
  }

  .ck-content {
    border-bottom: none !important;
    border-left: none !important;
    border-right: none !important;
  }
}

.ck-no-border {
  + .ck {
    .ck-content {
      border: none !important;
    }
  }
}

.mini-editor-component {
  .editor {
    min-height: 150px;
  }

  //.mini-tool-bar {
  //  @mixin mini-tool-bar-theme {
  //    color: #{$dim-400} !important;
  //    &:hover {
  //      color: #{$dim-400} !important;
  //    }
  //  }
  //
  //  button {
  //    color: #{$dim-400};
  //    border: none;
  //    @include mini-tool-bar-theme;
  //    @include gray-hover();
  //  }
  //
  //  .form-drop-down-component {
  //    .dropdown-toggle {
  //      border: none !important;
  //      @include mini-tool-bar-theme;
  //      @include gray-hover();
  //    }
  //
  //    .dropdown-menu {
  //      border-color: #{$dim-400} !important;
  //    }
  //
  //    .dropdown-item {
  //      @include mini-tool-bar-theme;
  //      @include gray-hover();
  //    }
  //  }
  //}
}