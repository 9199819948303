@mixin noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Edge, Opera and Firefox */
}

@mixin nodrag {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

//FIB
@mixin fib-setting {
  [contenteditable=true] {
    outline: none;
    padding: 5.2px 12px;
    counter-reset: css-counter 0;
    line-height: 350%;

  }

  [data-type="fib-field"] {

    line-height: normal;
    //padding: 0.5rem 0.825rem 0.5rem 0.5rem;
    display: inline-flex;
    //margin: 0 0.5rem;
    border: 2px transparent solid;
    overflow: hidden;
    vertical-align: middle;

    &.focused {
      border: 2px #{$fib-active} solid;
    }

    .fib-indicator {
      color: white;
      //padding: 0.5rem 0.825rem 0.5rem 0.5rem;
      padding: 0.5rem 0.5rem 0.5rem 0.5rem;

      background-color: #{$fib-active};
      counter-increment: css-counter 1;
      line-height: normal;

      &:before {
        content: counter(css-counter);
        line-height: normal;
      }
    }

    .fib-border {
      background-color: #{$fib-default-bg};

      padding: 0.5rem 0.825rem 0.5rem 0.5rem;
      //border-bottom: 1px #{$fib-underline} solid;
      padding-bottom: 0.325rem;
      display: inline-block;
      min-width: 3rem;

      .fib-content {
        display: inline-flex;

        p:last-child {
          margin-bottom: 0;
        }
      }

    }


    &[data-answer-type="dropdown"] {
      .fib-border {
        &:after {
          content: ("▾");
          margin-left: 1rem;
          //font-size: 1.5em;
          line-height: normal;
        }
      }
    }
  }
}

//Fix safari 100vh
@mixin safari_100vh {
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available !important;
  }
}

@mixin min_safari_100vh {
  @supports (-webkit-touch-callout: none) {
    min-height: -webkit-fill-available !important;
  }
}

@mixin max_safari_100vh {
  @supports (-webkit-touch-callout: none) {
    max-height: -webkit-fill-available !important;
  }
}