.mcq-table {
  overscroll-behavior: none;
}

.mcq-table {
  table, th, td {
    //border-collapse: separate;
    //border-spacing:2px;
    //border-style: solid;
    //border-width:thin;
    //background-color:White;
  }

  @include media-breakpoint-down(md) {
    max-height: 60vh;
  }


  &.table-mc {
    table {
      thead {
        th {
          &:first-child {
            border: none;
          }
        }
      }

      tbody {
        th {
          border: none;
          padding-left: 3rem;
          padding-right: 3rem;
        }
      }
    }
  }

  table {
    margin: 0;
    border: none;
    //border-collapse: separate;
    ////table-layout: fixed;
    //border-spacing: 0 0.5rem;

    white-space: nowrap;
    margin: 0;
    border: none;
    border-collapse: separate;
    border-spacing: 0;
    @include media-breakpoint-down(md) {
      table-layout: auto !important;
    }

    th, td {
      border: none;
      word-break: break-word;
      text-align: center;
      vertical-align: middle;
      border-bottom: 1px #{$preview-border} solid;
      @include media-breakpoint-down(md) {
        width: auto !important;
        //overflow: hidden;
        white-space: nowrap;
      }

      + td, + th {
        //border-left: 1px solid #{$preview-border};
      }
    }


    th {
      white-space: break-spaces;
      word-break: keep-all;

      &:first-child {

        background-color: white;

      }
    }

    &.scrolled {
      th {
        &:first-child {
          //border-right: 1px solid #{$preview-border};
          //box-shadow: 16px 0px 23px 0px #{$preview-border};
          //-webkit-box-shadow: 16px 0px 23px 0px #{$preview-border};
          //-moz-box-shadow: 16px 0px 23px 0px #{$preview-border};
          //overflow: hidden;

          &:after {
            content: "";
            position: absolute;
            top: 0;
            right: 0px;
            width: 30px;
            height: 100%;
            box-shadow: 6px 0px 13px -5px #{$preview-border};
            -webkit-box-shadow: 6px 0px 13px -5px #{$preview-border};
            -moz-box-shadow: 6px 0px 13px -5px #{$preview-border};
          }
        }
      }
    }

    tr {
      //border-color: #{$preview-border};
    }

    td {
      text-align: center;
      //border-right: 1px solid #{$preview-border};
      //background-color: #{$mcq-table-bg-default};
    }

    thead {
      th {
        position: sticky;
        top: 0;
        z-index: 1;
        background-color: white;

        &:first-child {
          position: sticky;
          left: 0;
          z-index: 2;
          //border-right: 1px solid #{$preview-border};
          //border-left: 0px solid #{$preview-border};
        }
      }
    }

    tbody {
      th {
        position: relative;
        //border-right: 1px solid #{$preview-border};
        //background-color: #{$mcq-table-header-bg-default};

        //&:first-child {
        //  border-right: 1px solid #{$preview-border};
        //  border-left: 0px solid #{$preview-border};
        //}

        @include media-breakpoint-down(md) {
          min-width: 200px;
          word-break: break-word;
          white-space: normal;
        }
        @include media-breakpoint-down(sm) {
          min-width: 125px;
        }
      }

      th {
        position: sticky;
        left: 0;
        //z-index: 1;
        padding-top: 1rem;
        padding-bottom: 1rem;
        z-index: 2;
        //background-color: white;
      }
    }
  }
}

//Scroll
//.scrolltable-indicator {
//  z-index: 10;

.scrolltable-left, .scrolltable-right {
  background-color: #{$scrolltable-bg-indicator};
  //color: #{$scrolltable-color-indicator};
  margin-top: 2.5rem;
  padding: 1rem 0.25rem;
  opacity: #{$opacity-medium-emphasis};

  &:hover {
    opacity: 1;
  }
}

.scrolltable-left {
  margin-left: -5px;
}

.scrolltable-right {
  margin-right: -5px;
}

//}