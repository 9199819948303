@mixin scroll-theme($bg,$scrollbar-bg,$scrollbar-hover-bg,$size:0.5rem) {
  //iOS safari is not support

  /* width */
  &::-webkit-scrollbar {
    width: #{$size};
    height: #{$size};
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #{$bg};
    border-radius: 1rem;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #{$scrollbar-bg};
    border-radius: 1rem;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #{$scrollbar-hover-bg};
  }
}