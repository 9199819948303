.shadow-400 {
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05), 0 5px 15px 0 rgba(0, 0, 0, 0.05);
}

.shadow-200 {
  -webkit-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.05);
}

.shadow-card {
  -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
}

.exercise-third-btn-shadow {
  -webkit-box-shadow: 0px 0px 4px #{$exercise-third-btn};
  box-shadow: 0px 0px 4px #{$exercise-third-btn};
}

.insetShadow {
  position: relative;
  display: block;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.25);
    z-index: 1;


    pointer-events: none;
  }
}