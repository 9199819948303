$max-height-popover: 30%;

@mixin button-close-color($color) {
  $btn-close-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$color}'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>");
  background: transparent escape-svg($btn-close-bg) center / $btn-close-width auto no-repeat;
}

.h-fit-content {
  height: fit-content !important;
}

.offcanvas {
  &.shadow {
    box-shadow: rgb(0 0 0 / 24%) 0px -10px 10px 0px;
  }
}

@mixin popover($borderColor,$backgroundColor,$color) {

  border-color: #{$borderColor};


  .popover-arrow::before {
    border-top-color: #{$borderColor};
  }

  &.bs-popover-top > .popover-arrow::after {
    border-top-color: #{$backgroundColor};
  }

  &.bs-popover-bottom > .popover-arrow::after {
    border-bottom-color: #{$backgroundColor};
  }

  .popover-header {
    background-color: #{$backgroundColor};

    .popover-title {
      color: #{$color};
    }

    .btn-close {
      @include button-close-color($color)
    }
  }

  &.popover-component {
    border-color: #{$borderColor};
    //max-height: #{$max-height-popover};
    //&.offcanvas{
    //  z-index: 1020;
    //}

    .offcanvas-header, .modal-header {
      background-color: #{$borderColor};

      .offcanvas-title, .modal-title {
        color: white;

        svg {
          width: 1rem;
          height: 1rem;
        }
      }

      .btn-close {
        @include button-close-color($white)
      }
    }

    .popover-body, .modal-body, .offcanvas-body {
      //overflow: scroll;
      overflow-y: auto;
    }

  }
}

//#{$preview} {
.offcanvas {
  @include popover($preview-secondary, $preview-secondary-light, $preview-secondary);
}

//}


.modal-90 {
  width: 90%;
  height: 90%;
  max-width: none !important;

  @include media-breakpoint-down(sm) {
    width: initial;
  }

  .modal-content {
    height: 100%;
  }
}

.modal-dialog-90 {
  .modal-dialog {
    width: 90%;
    height: 90%;
    max-width: none !important;

    .modal-content {
      height: 100%;
    }

    @include media-breakpoint-down(md) {
      width: auto;
      //height: 100%;
    }
  }
}

.mw-90 {
  width: fit-content;
  max-width: 90%;
}

.offcanvas-mw-90 {
  width: 90% !important;

  @include media-breakpoint-down(sm) {
    width: 100% !important;
  }
}