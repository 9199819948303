.noFloatCookie #ot-sdk-btn-floating { display: none !important; visibility: hidden !important;}

body, div { box-sizing:border-box; padding:0; margin:0; }
#webpack-dev-server-client-overlay { display: none }

body, html { display:flex; flex-direction: column; min-height: 0%; max-height: 100%;
    height:100%; width:100%; background-color: #F9F9F9;}

pre {font-size:11px;}

textarea, input { font-family: inherit; font-size: inherit; }

.hrat { border: 1px solid #C6C6C6; border-style: none none solid none; margin-bottom: 18px;}

#root {flex:1; background-color:white}

@keyframes spinning { from { transform: rotate(0deg); } to { transform: rotate(360deg); } }
.spin { animation: spinning 2s infinite linear; -webkit-animation: spinning 2s infinite linear; }

.btnqdp { display:inline-block; outline:0; border:0; padding:2px 8px; margin:0 4px; border-radius:6px;}

.noAnmiation { transition:none !important}
.modal-backdrop { z-index: 1055 !important; }
.modalOpacity0 { opacity: 0; }
.screenMask, .screenMaskLoader { position: fixed; top:0; left:0; height:100%; width:100%; 
    overflow:auto; z-index:1050; text-align:center; }
.screenMask2, .screenMaskLoader2 { vertical-align:middle; display: inline-block; width: 0; height: 100%; margin:0; padding:0; }
.screenMask3, .screenMaskLoader3 { vertical-align:middle; display: inline-flex; width: 0; height: 100%; margin:0; padding:0; }

.semi-bold {font-weight: 600;}
.text-truncate { white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
.bold { font-weight: bold; }

.f40 { font-size:40px; }
.f32 { font-size:32px; }
.f31 { font-size:31px; }
.f30 { font-size:30px; }
.f28 { font-size:28px; }
.f26 { font-size:26px; }
.f25 { font-size:25px; }
.f24 { font-size:24px; }
.f23 { font-size:23px; }
.f22 { font-size:22px; }
.f20 { font-size:20px; }
.f18 { font-size:18px; }
.f17 { font-size:17px; }
.f16 { font-size:16px; }
.f15 { font-size:15px; }
.f14 { font-size:14px; }
.f13 { font-size:13px; }
.f12 { font-size:12px; }
.f10 { font-size:10px; }

.clight { color:#aaa; }

.br3 { border-radius: 3px; }
.br6 { border-radius: 6px; }
.br8 { border-radius: 8px; }
.br12 { border-radius: 12px; }

.m0 { margin:0; }
.m4 { margin:4px; }
.mx4 { margin-left:4px; margin-right:4px; }
.m8 { margin:8px; }
.mx8 { margin-left:8px; margin-right:8px; }
.mb8 { margin-bottom:8px; }

.p0 { padding:0; }
.p4 { padding:4px; }
.px4 { padding-left:4px; padding-right:4px; }
.px8 { padding-left:8px; padding-right:8px; }
.py2 { padding-top:2px; padding-bottom:2px;}
.p8 { padding:8px; }
.rlp10 { padding:0 10px; }
.rlpEx { padding:0 15px; }

.hide { width:0; height:0; overflow:hidden; position:absolute; }

.db { display:block; }
.dn { display:none; }
.w, .dib { display:inline-block; }
.w100 { width:100%; }
.w0 { width:0; display:inline-block; }
.w10 { width:10%; display:inline-block; }
.mw10 { min-width:10%; display:inline-block; }
.w15 { width:15%; display:inline-block; }
.mw15 { min-width:15%; display:inline-block; }
.w20 { width:20%; display:inline-block; }
.mw20 { min-width:20%; display:inline-block; }
.w25 { width:25%; display:inline-block; } 
.mw25 { min-width:25%; display:inline-block; } 
.w30 { width:30%; display:inline-block; }
.mw30 { min-width:30%; display:inline-block; }
.w35 { width:35%; display:inline-block; }
.mw35 { min-width:35%; display:inline-block; }
.w40 { width:40%; display:inline-block; }
.mw40 { min-width:40%; display:inline-block; }
.w45 { width:45%; display:inline-block; }
.mw45 { min-width:45%; display:inline-block; }
.w50 { width:50%; display:inline-block; }
.mw50 { min-width:50%; display:inline-block; }
.w60 { width:60%; display:inline-block; }
.w70 { width:70%; display:inline-block; }
.w75 { width:75%; display:inline-block; }
.w80 { width:80%; display:inline-block; }



.h100 { height:100%; }
.hvh { height:100vh; }

.pa { position: absolute; }
.pr { position: relative; }
.pf { position: fixed; }

.oa { overflow: auto; }
.oh { overflow: hidden; }
.ov { overflow: visible }

.vat { vertical-align: top; }
.vam { vertical-align: middle; }
.vab { vertical-align: bottom; }

.tal { text-align: left; }
.tac { text-align: center; }
.tar { text-align: right; }

.floatr { float:right; }
.floatl { float:right; }
.clearboth { clear:both; }

.bgfff { background-color:#fff; }
.bg000 { background-color:#000; }

.cf00 { color:#f00; }

.fxGrowRow { display: flex; flex-direction: row; flex-grow: 1; }
.fxRow { display: flex; flex-direction: row; }
.fxGrow { flex-grow: 1; }

.floatright {float: right;}
.clearboth { clear: both; }

mjx-assistive-mml { right: 0px; bottom: 0px; }

.box1 {border:1px solid #000;}

.clickable { cursor: pointer; }
.clickable:hover { cursor: pointer; }
.unclickable { cursor: none; pointer-events: none; }
.usel { user-select: none; }
.grabbable { cursor: grab; }

.pubBtn, .draftBtn{ text-align:center; color:#000; font-size:10px; min-width:120px;}
.roundBox { border-radius:4px; border:1px solid #888; }

.atpage { padding:12px; min-height: 100vh; position: relative; }
.atsect { margin:12px 4px; }
.atrow { margin:8px 4px; font-size:12px;}

.atpagehead { margin:8px 0; font-size:16px; font-weight:500; }
.atsecthead { margin:8px 0; font-size:14px; font-weight:600; }
.atfieldhead { margin:8px 8px 8px 0; font-size:14px; font-weight:500; display:inline-block}

.atuibtn, .atuibtnlock { box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.20); border-radius: 4px; 
    font-size:12px; margin: 4px; padding:4px 12px; display:inline-block; }
.atuibtn {color: #fff; background-color: #0C8CE9; cursor: pointer; }
.atuibtnlock {color: #fff; background-color: #bbb; }

.ATTab, .ATTabBg, .ATTabLast, .ATTabGap {
    display:flex;
    /*display:inline-block;*/
    padding: 2px 8px;
    border-radius: 10px 10px 0 0;
    border: 1px solid #666; /* 25px top, 10px right, 4px bottom and 35px left */
}
.ATTab {border-bottom-color: rgba(20, 60, 40, 0);}
.ATTabBg {background-color:#ddd; border-width:1px; color: #666; cursor: pointer;}
.ATTabLast, .ATTabGap {display:flex; flex:1 ; border:1px solid #666; border-width:0 0 1px 0;
    /*color: rgba(20, 60, 40, 0); */
}
.ATTabGap {flex:0; padding:2px 4px; margin:0;}

.ATIcoBtnbk {
    display:inline-block; margin:2px; padding:2px 4px 2px 2px; /*border:1px solid #08a; border-radius:6px;*/
    color:#08a; font-size:14px; text-align: center;
}
.ATIcoBtn {
    display:inline-flex; flex-direction: row; justify-content: center; align-items: center;
    margin:2px; padding:2px 4px 2px 2px; /*border:1px solid #08a; border-radius:6px;*/
    color:#08a; font-size:14px;
}
.ATIcoBtnDisable {
    /*border:1px solid #aaa;*/
    color:#aaa;
}

.ATPageContainer {
    display: flex; flex-direction: column; width: 100%; height: 100vh;
    position: relative;
}

/*z-index:5*/
.ATTopBar {
    position:fixed; top:0; left:0;
    height:60px; width:100%;
    display:flex; flex-direction: row; justify-content: space-between; align-items: center;
    background-color:#FFFFFF; padding: 0 20px 0 20px;
    box-shadow: 0 2px 2px #DBDBDB; z-index: 1000;
}

.ATTopBar1, .ATTopBar2 {
    display:flex; flex-direction: row; justify-content: flex-start; align-items: center;
}

.topBarIcon, .topBarIcon2 {
    width: 20px; height:20px; margin-right: 10px; cursor: pointer;
}

.topBarIcon2 { width: 14px; height:8px; }

/*z-index:3*/
.navsideback {
    position:fixed; z-index:999;
    top:0; left:0; height:100%; width:100%;
    background-color:rgba(0,0,0,0.5);
}
.navside.invis {
    left:-100%;
    transition: left 0.2s ease-in;
}

/*z-index:5*/
.navside {
    position:fixed; z-index:999;
    top:0; left:0; height:100%; min-width:244px;
    background-color:#fff;
    box-shadow:0px 0px 8px rgba(0,0,0,1);
    border-radius:0;
    overflow:auto;
    transition: left 0.2s ease-out;
}

.navsidebackR {
    position:fixed; z-index:3;
    top:0; left:0; height:100%; width:100%;
    background-color:rgba(0,0,0,0.5);
}

.navsideR {
    position:fixed; z-index:100;
    top:0; right:0; height:100%; min-width:244px;
    background-color:#fff;
    box-shadow:0px 0px 8px rgba(0,0,0,1);
    border-radius:0;
    /*overflow:auto;*/
    transition: right 0.2s ease-out;

    min-height:100%; height:100%;
}
.navsideR.invis {
    right:-100%;
    transition: right 0.2s ease-in;
}

.ATLeftBarFlowTop  {display:flex; position:relative}
.QtnOptionsFlowTop, .ExOptionsFlowTop {display:flex; position:relative}
.QtnOptionsBtn {display:flex;flex-direction: row;justify-items: flex-start;align-items: center;
    position: absolute; top:5px;right:5px;
}
.ExOptionsBtn {display:flex;flex-direction: row;justify-content:flex-end;align-items: center;
width:100%; padding: 60px 15px 5px 0px}

.ATLeftBarFlow {
    display:flex; flex-direction: column; justify-content: space-between;
    margin-top:60px; padding:4px 10px 80px 4px; background-color:white;
}

.ATLeftBar {
    position:fixed; top:0; left:0; width:244px; height:100vh; margin-top:60px;
    display:none; flex-direction: column; justify-content: space-between;
    padding:4px 10px 80px 4px; background-color:white;
    box-shadow: 0 3px 3px #cdcdcd; overflow: hidden;
}

/*.ATLeftBarNotVis { width:0; margin: 0; padding: 0; box-shadow: none; }*/

.ATHead { padding-top:60px; width:100%; height:100%;}
.ATBody { padding-left:0px;}
.ATBody.woLB { padding-left:0; }

.leftBarlogout {
    display: flex; flex-direction: row; align-items: center; 
    padding-left: 5px; color: #949494;
}

.expfMenuContainer { display: flex; flex-direction: column; width: 100%; }

.menuRowContainer, .menuRowContainer2 {
    display: flex; flex-direction: row; justify-content: space-between; 
    padding: 5px; margin-top: 10px;
}

.menuRowContainer2 { padding: 0 5px 0 5px; margin: 0; }

.menuRowItem1 { display: flex; flex-direction: row; align-items: center; }

.menuIcon { width: 18px; height:18px; }

.menuIcon2 { width: 14px; height:8px; }

.divVBar { width: 2px; height: 20px; margin: 0 9px 0 14px; background-color: #DBDBDB; }

.divCircle {
    width: 6px; height: 6px; border-radius: 50%; margin: 0 8px 0 12px;
    background-color: #DBDBDB; display: inline-block;
}

.treeContainer {
    display: flex; flex-direction: row; width: 100%;
    align-items: flex-start; justify-content: flex-start;
    padding: 0; margin :0;
    border: 1px solid #C6C6C6; border-radius: 5px;
    background-color: white; overflow: auto;
}

.treeZoomContainer { display: flex; flex-direction: column; align-items: flex-start; padding: 5px }

.zoomIcon{
    width: 25px; height: 25px; background-color: #D9D9D9;
    padding: 1px; border-radius: 5px; margin-bottom: 5px; cursor: pointer;
}

.treeBody {
    display: flex; flex-direction: column; width:100%; padding: 5px;
    min-width: 1024px; transform-origin: 0% 0% 0;
}

.treeEleContainer0 {
    display: flex; flex: 1; flex-direction: row; width: 100%;
    margin: 0; padding: 0; border: 0px solid grey;
}

/*
.treeEleContainer1 {
    display: flex; flex: 1; flex-direction: row; justify-content: flex-start; align-items: flex-start;
    margin: 0; padding: 0; border: 1px solid red;
}
*/

.treeEleContainer1 {
    display: flex; flex: 1; flex-direction: column; justify-content: flex-start; align-items: flex-start;
    margin: 0; padding: 0; border: 0px solid red;
}

.treeEleContainer2 {
    display: flex; flex-direction: row; justify-content: flex-start;
    margin-top: 0; padding: 0; align-items: flex-start; border: 0px solid green;  
}

.treeExpand { display: flex; cursor: pointer; margin:0 2px; padding-top: 5px; }

.treeEleContent {
    display: flex; flex-direction: column; justify-content: flex-start;
    margin-bottom: 2px; padding: 4px 8px 4px 4px; align-items: flex-start; 
    border: 1px solid #C6C6C6; border-radius: 6px;
}

.treeEleContent2 { display: flex; flex-direction: row; justify-content: flex-start; align-items: flex-start;
    border: 1px solid #C6C6C6;margin-bottom: 10px; padding: 0 5px 0 5px;border-radius: 6px;
    box-shadow: -1px 3px 2px grey;
}
.treeEleContent3 { display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start;}

.treeEleContentSub {
    display: flex; flex: 1; flex-direction: column; justify-content: flex-start;
    margin: 0; padding: 0; align-items: flex-start; border: 0px solid blue;
}

.DLcontainer {
	display:flex; flex-direction:row; flex:1; max-width:20px; min-width:20px;
    border: 0px solid lightgrey;
}

.DLtop1, .DLtop2, .DLmiddle1, .DLmiddle2,
.DLtail1, .DLtail2, .DLone1, .DLone2 {
    flex:1; max-width:10px; min-width:10px; border-width: 2px;
    border-color: black; margin-top:10px;
}
/*
    flex:1; max-width:10px; min-width:10px; border-width: 2px;
    border-color: black; margin-top:10px;
*/
.DLtop1 { border-style: solid none none none; margin-top: 14px; }

.DLtop2 { border-style: solid none none solid; margin-top: 14px; }

.DLmiddle1 { margin-top:0; margin-left:2px; border-style: none solid none none; }

.DLmiddle2 { margin-top:12px; border-style: solid none none none; max-width:8px; min-width:8px }

.DLtail1 { border-style: none none none none; }

.DLtail2 { margin-top:0; height:15px; border-style: none none solid solid; }

.DLone1 { border-style: solid none none none; margin-top: 14px; }

.DLone2 { border-style: solid none none none; margin-top: 14px; }

.adminAccountTop, .adminSubjectTop, .adminQuesTop
{ padding: 5px; width:100%; height:100%;}

.adminQuesTop {display:flex; flex-direction:column; justify-content: flex-start; min-height: 0; 
    height:100%; max-height: 100%; padding:10px; position: relative;}

.adminToolsContainer { position: sticky; top: 0px; z-index:2; background-color: #fff }
.adminEditToolsContainer { display: flex; }
.adminToolsContainer, .adminEditToolsContainer {
    flex-direction: row; justify-content: space-between; align-items: center;
    width: 100%;
}

.adminEditToolsContainer { justify-content: flex-start; margin:10px 0 10px 0}
.adminTools1 { flex-grow: 1; }
.adminTools1, .adminTools2 {
    display: flex; flex-direction: row; justify-content: flex-start; align-items: center;
    align-content: center; padding: 10px 0 10px 0;
}

.adminToolItem { display: flex; padding: 2px 5px 2px 0; }

.adminToolIcon { padding: 0 2px 0 2px; }

.adminListContainer { display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start; width: 100%; }

.itemRowBlk {display: flex; flex-direction: column; justify-content: flex-start; align-items: center;
    width:100%; max-width: 100%; padding:0; margin:0; border:1px solid #C6C6C6;
    margin-bottom: 5px; background-color: white; border-radius: 6px;
}

.adminItemRow, .adminItemRowHeader, .metaItemRowHeader, .execItemRowHeader, .execItemRowSHeader, .accItemRow {
    display: flex; flex-direction: row; justify-content: flex-start; align-items: center;
    border-radius: 6px; width: 100%; padding: 4px; 
}

.accItemRow {border:1px solid #C6C6C6; background-color: white; margin-bottom: 5px;}
/*.adminItemRow {border:1px solid #C6C6C6; background-color: white; }*/

.sjstItem1, .sjstItem2, .sjstItem3, .sjstItem4, .sjstItem5 {
    text-align:center;
} 
.sjstItem1 {width: 50px; }
.sjstItem2 {width: 180px; }
.sjstItem3 {width: 250px; }
.sjstItem4 {width: 100px; }
.sjstItem5 {width: 250px; }


.adminItem1, .adminItem2, .adminItem3, .adminItem4, .adminItem5,
.metaItem1, .metaItem2, .metaItem3, .metaItem4, .metaItem5, .metaItem6, .metaItem7,
.quesItem1, .quesItem2, .quesItem3, .quesItem4, .quesItem5, .quesItem6, .quesItem7,
.quesSItem1, .quesSItem2, .quesSItem3, .quesSItem4, .quesSItem5, .quesSItem6, .quesSItem7, .quesSItem8,
.execItem1, .execItem2, .execItem3, .execItem4, .execItem5, .execItem6, .execItem7,
.execSItem1, .execSItem2, .execSItem3, .execSItem4, .execSItem5, .execSItem6, .execSItem7, .execSItem8,
.subjectItemBtn { display: flex; justify-content: center; overflow: visible; width:100px; }

.adminItem1 {max-width:3%; min-width:3%; justify-content:center}
.adminItem2 {max-width:21%; min-width:21%; justify-content:flex-start}
.adminItem3 {max-width:10%; min-width:10%}
.adminItem4 {max-width:24%; min-width:24%}
.adminItem5 {max-width:42%; min-width:42%; word-break: break-word;}

.metaItem1 {max-width:5%; min-width:5%; justify-content:flex-start; align-items: center;}
.metaItem2 {max-width:20%; min-width:20%; justify-content:flex-start;
    flex-direction: column; align-items: flex-start;
}
.metaItem3 {max-width:14%; min-width:14%; }
.metaItem4 {max-width:15%; min-width:15%; }
.metaItem5 {max-width:12%; min-width:12%; }
.metaItem6 {max-width:12%; min-width:12%; }
.metaItem7 {max-width:22%; min-width:22%; justify-content:flex-start;
    flex-direction: column; align-items: center;
}

.quesItem1 {max-width:5%; min-width:5%; justify-content:flex-start; align-items: center;}
.quesItem2 {max-width:20%; min-width:20%; justify-content:flex-start;
    flex-direction: column; align-items: flex-start;
}
.quesItem3 {max-width:12%; min-width:12%; }
.quesItem4 {max-width:17%; min-width:17%; }
.quesItem5 {max-width:12%; min-width:12%; }
.quesItem6 {max-width:12%; min-width:12%; }
.quesItem7 {max-width:22%; min-width:22%; justify-content:flex-start;
    flex-direction: column; align-items: center;
}

.quesSItem1 {max-width:5%; min-width:5%; justify-content:flex-start; align-items: center;}
.quesSItem2 {max-width:11%; min-width:11%; justify-content:flex-start;
    flex-direction: column; align-items: flex-start;
}
.quesSItem3 {max-width:11%; min-width:11%; justify-content:flex-start;
    flex-direction: column; align-items: flex-start;}
.quesSItem4 {max-width:21%; min-width:20%; justify-content:flex-start;
    flex-direction: column; align-items: flex-start;}
.quesSItem5 {max-width:12%; min-width:14%; }
.quesSItem6 {max-width:12%; min-width:11%; }
.quesSItem7 {max-width:12%; min-width:12%; justify-content:flex-start;
    flex-direction: column; align-items: center;
}
.quesSItem8 {max-width:16%; min-width:16%; justify-content:flex-start;
    flex-direction: column; align-items: center;
}

.execItem1 {max-width:5%; min-width:5%; justify-content:flex-start; align-items: center;}
.execItem2 {max-width:17%; min-width:17%; justify-content:flex-start;
    flex-direction: column; align-items: flex-start;
}
.execItem3 {max-width:23%; min-width:23%; justify-content:flex-start;
    flex-direction: column; align-items: flex-start;}
.execItem4 {max-width:10%; min-width:10%; }
.execItem5 {max-width:11%; min-width:11%; }
.execItem6 {max-width:11%; min-width:11%; }
.execItem7 {max-width:18%; min-width:18%; justify-content:flex-start;
    flex-direction: column; align-items: center;
}

.execSItem1 {max-width:5%; min-width:5%; justify-content:flex-start; align-items: center;}
.execSItem2 {max-width:14%; min-width:14%; justify-content:flex-start;
    flex-direction: column; align-items: flex-start; word-wrap: break-word;
}
.execSItem3 {max-width:14%; min-width:14%; justify-content:flex-start;
    flex-direction: column; align-items: flex-start; word-wrap: break-word;
}
.execSItem4 {max-width:20%; min-width:20%; justify-content:flex-start;
    flex-direction: column; align-items: flex-start;}
.execSItem5 {max-width:10%; min-width:10%; }
.execSItem6 {max-width:10%; min-width:10%; }
.execSItem7 {max-width:10%; min-width:10%; }
.execSItem8 {max-width:16%; min-width:16%; justify-content:flex-start;
    flex-direction: column; align-items: center;
}

.metaLangEn, .metaLangCh, .metaDraft, .metaPub { border-radius: 5px; margin:2px; padding:4px; }
.metaDPdisplay { display:flex; flex-direction: row; 
    justify-content: center;align-items: center; width:100%}
.metaDraft, .metaPub {min-width: 45%;}
.metaLangEn {background-color: #C0D6F7;}
.metaLangCh {background-color: #F7D7C0; white-space: nowrap;}

.metaLangSel, .metaLangUnsel, .quesLangSel, .quesLangUnsel { border-radius:6px; padding: 2px 5px 2px 5px; margin: 0 2px 0 2px}
.metaLangSel, .quesLangSel {color:white; background-color: #0C8CE9; }
.metaLangUnsel, .quesLangUnsel {color: #48494A; background-color: #C6C6C6;
    border:1px solid #C6C6C6; cursor: pointer;}
.quesLangUnsel {border:none}

.metaDraft {text-align: center; background-color: #DADADA;}
.metaPub {text-align: center; background-color: #B7EFBA;}
.metaUnpub {background-color: #ffccaa;}
.metaHide {opacity: 0;}
/*.metaHide { color:rgba(20, 60, 40, 0)} */

.metaPublishBtn, .metaDraftBtn {
    cursor: pointer; border-radius: 6px; border-width: 1px; margin: 0 2px 0 2px;
}
.metaPublishBtn {background-color: #B7EFBA;}
.metaDraftBtn {background-color: #DADADA;}

.inputInfoContainer { display:flex;flex-direction:column;align-items:flex-start;justify-content:flex-start; width:100%; }

.inputInfoTable, .ATQuesTable {border-collapse: collapse; width:100%; margin-bottom: 10px;}

.accountInputLabel, .subjectInputLabel, .metaCodeLabel { width:75px; text-align: right; padding: 8px 2px 8px 0; }

.subjectInputLabel { width: 110px }
.subjSettingInputLabel { width: 300px }

.inputInfoTable > tr > td > input[type=text], input[type=password] { border: 1px solid #C6C6C6; }

.inputInfoTable > tr > td {border: 0 solid #dddddd; }

.redStar { color:red; white-space:pre; }

.subjectItemContainer { display: flex; flex-direction:row; flex-wrap: wrap; justify-content: flex-start; margin-top: 5px; }

.subjectItem, .subjectItemSel {
    display:flex; flex-direction: row; align-items: center; justify-content: flex-start; 
    width:30%; min-width:280px; padding: 5px; border: 1px solid #C6C6C6; border-radius: 6px;
    background-color:white; margin:4px 2px 4px 2px; word-break: break-all;
}

.subjectItemSel { background-color:#DBF0FF; border-color:#0C8CE9; color:#0C8CE9}

.flexColStart {display:flex; flex-direction: column; justify-content: flex-start; align-items: flex-start;width:100%;height:100%}
.flexColStartFit {display:flex; flex-direction: column; justify-content: flex-start; align-items: flex-start;width:100%;}
.flexColCenter {display:flex; flex-direction: column; justify-content: center; align-items: center; width:100%;height:100%}
.flexColCenterFit {display:flex; flex-direction: column; justify-content: center; align-items: center; width:100%;}
.flexColBetween {display:flex; flex-direction: column; justify-content: space-between; align-items: center; width:100%;height:100%}
.flexColBetweenFit {display:flex; flex-direction: column; justify-content: space-between; align-items: center; width:100%;}
.flexColEnd {display:flex; flex-direction: column; justify-content: flex-end; align-items: flex-end;height:100%}
.flexColCenterFitw0 {display:flex; flex-direction: column; justify-content: center; align-items: center;}


.flexRowStartStart {display:flex; flex-direction: row; justify-content: flex-start; align-items: flex-start; width:100%;}
.flexRowStart {display:flex; flex-direction: row; justify-content: flex-start; align-items: center; width:100%;}
.flexRowStartFit {display:flex; flex-direction: row; justify-content: flex-start; align-items: center; }
.flexRowEnd {display:flex; flex-direction: row; justify-content: flex-end; align-items: center; width:100%;}
.flexRowEndFit {display:flex; flex-direction: row; justify-content: flex-end; align-items: center; }
.flexRowCenter {display:flex; flex-direction: row; justify-content: center; align-items: center;width:100%;}
.flexRowCenterFit {display:flex; flex-direction: row; justify-content: center; align-items: center;}
.flexRowBetween {display:flex; flex-direction: row; justify-content: space-between; align-items: center; width:100%;}
.flexRowBetweenFit {display:flex; flex-direction: row; justify-content: space-between; align-items: center; }


.tblTest, .tblTest > tbody> tr, .tblTest > tbody> tr > td { padding: 5px; border: 1px solid grey; border-collapse: collapse; }

/*list*/
.DDTopContainer.List {
    display:flex; flex-direction: column; justify-content: center; align-items: flex-start;    
    width: 100%; flex-shrink: 0; 
}

.DDTopContainer.Grid, .DDTopContainer.Label {
    display: grid; grid-column-gap: 1%; grid-template-columns: 100%; padding: 0; width: 100%;
}
/*min-height: 40px;*/

.DDoptionContainer {
    display:flex; flex-direction: column; justify-content: center; align-items: flex-start;    
    position: relative; cursor:pointer; margin-bottom: 10px; border-radius: 12px; width: 100%; 
}
.DDoptionContainer.Sel {box-shadow: 2px 2px 2px #BFBFBF;}
/*.DDoptionContainer.Grid, .DDoptionContainer.Label {max-width: 50%;}*/


/*min-height: 40px;*/
.DDextraBar { width:100%; height:10px; background-color:#AAD1E0; border-radius: 5px; align-self: center; }

.DDcontent {
    display:flex; flex-direction: row; justify-content: flex-start; align-items: center;
    height:100%; width:100%; /*background-color:#119922*/
}

/*.DDcontentTblSel*/
.DDcontentTbl, .DDcontentTbl > tbody, .DDcontentTbl > tbody > tr {
    display:flex; padding:0; margin:0; border: none; border-spacing: 0; width:100%;
    /*background-color: greenyellow;*/ border-radius: 12px;
}
.DDcontentTbl {height:100%;}
.DDcontentTbl > tbody > tr > td:last-child { width:100%; }

.DDtdGap {width:3px}
.DDtd0 {display: flex; flex-direction: column; justify-content: flex-start; align-items: center;
    min-width:20px; max-width: 20px; border: none; margin: 10px 5px 0 0;}
.DDtd1, .FIBAnsDetailHead {
    display:flex; flex-direction: column; justify-content: flex-start; align-items: center;
    min-width:40px; max-width:40px; color: white; border: none; 
    border-radius: 12px 0 0 12px; border:2px solid #c1c0e1;background-color: #c1c0e1;}
.DDtd1.Sel {color:white; background-color: #4EAE84; border-color:#4EAE84}
.FIBAnsDetailHead {background-color:transparent; border:none}

.DDtd1.teEdit {color:white; background-color: #c1c0e1;border-color:#c1c0e1}
.DDtd1.Sel.teEdit {color:white; background-color: #4EAE84;border-color:#4EAE84}
.DDtd1.atEdit {color:white; background-color: #c1c0e1;border-color:#c1c0e1}
.DDtd1.Sel.atEdit {color:white; background-color: #4EAE84; border-color:#4EAE84}
.DDtd1.stEdit, .DDtd1.preview {color:white; background-color: #c1c0e1; border-color: #c1c0e1;}
.DDtd1.Sel.stEdit, .DDtd1.Sel.preview {color:white; background-color: #5d52ce; border-color: #5d52ce;}

.DDtd1.Over, .DDtd2.Over { color:grey; background-color: yellow;border-color:yellow}

.DDCharHead { display: flex; flex-direction: column; justify-content: flex-start; align-items: center;
    font-weight: bold; width: 40px; min-height: 40px; font-size: 20px; padding-top: 10px;}

.DDtd2 { 
    margin: 0; padding: 0; border: none; align-items: center;
    background-color: white; border-radius: 0 12px 12px 0;
    border: 2px solid white; border-style: solid solid solid none;
}
.DDtd2.Sel {     
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 2px dashed #4EAE84; border-style: dashed dashed dashed none;}

.DDtd2.Sel.preview {     
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 2px solid #5d52ce; border-style: solid solid solid none;}


.DDIconContainer {
  display:flex; flex-direction: row; justify-content: center; align-items: center; align-self: center; 
  width:60px; height:12px; color:white; background-color:#84B2C2; border-radius: 12px 12px 0 0;
}

.DDQtnEditRow {
    display:flex; flex-direction: column; justify-content: space-between; align-items: flex-start;
    background-color: white; padding: 5px; height:100%; border-radius: 0 12px 12px 0; color:black;
}

.DDQtnEditRow>span { width:100%; }

.DDQtnEditRow_BK {
    display:flex; flex-direction: row; justify-content: flex-start; align-items: flex-start;
    background-color: white; padding: 5px; height:100%; border-radius: 0 12px 12px 0;
}

.DDQtnLabel, .DDQtnLabel.Sel  { display:flex; flex-direction: column; justify-content: center; 
    align-items: center; border-radius: 12px; width:100%; padding:20px;
    position: relative;
}
.DDQtnLabelTrash {position:absolute;top:5px;right:15px;width:30px;height:30px}

.DDQtnLabel {color:#c1c0e1; border:2px solid #c1c0e1; background-color: white;}
.DDQtnLabel.Sel {color:white; border:2px solid #4eae84; background-color: #4eae84;}
.DDQtnLabel.preview {color:#c1c0e1; border:2px solid #c1c0e1; background-color: white;}
.DDQtnLabel.Sel.preview {color:white; border:2px solid #5d52ce; background-color: #5d52ce;}

.DDQtnEditBtn {
    display:flex; flex-direction: row; justify-content: flex-end; align-items: center;
    background-color: white;/*border-radius: 0 12px 12px 0;*/
    width:100%;
}

.DDQtnEditBtnFIB {
    display:flex; flex-direction: row; justify-content: flex-end; align-items: center;
    background-color: white;/*border-radius: 0 12px 12px 0;*/
}

.DDQtnEditBtn_BK {
    display:flex; flex-direction: row; justify-content: center; align-items: flex-end;
    background-color: white; height:100%; /*border-radius: 0 12px 12px 0;*/
}

/******************************************/
/* question color 
    'atview':' atView', // at preview
    'atedit':' atEdit', // at edit
    'teview':' teView', // teacher preview
    'teedit':' teEdit', // teacher edit
    'stview':' stView', // student view result
    'sttest':' stTest'  // student take the test
*/

/* box-shadow: inset 0 0 0 20px red; question number DDtd1 DDtd1Sel*/

/* svg color */
/*
.oupSVGIcon:hover {
    filter: invert(62%) sepia(33%) saturate(358%) hue-rotate(160deg) brightness(90%) contrast(85%);
    background-color: yellow;
}
*/
/*.oupSVGIcon {border:1px solid black}*/

.ATSVGWhite {filter: invert(100%)}
.ATSVGBlack {filter: invert(0)}
.ATSVGEnable {filter: invert(53%) sepia(87%) saturate(3055%) hue-rotate(160deg) brightness(93%) contrast(101%);} 
.ATSVGDisable {filter: invert(82%) sepia(0%) saturate(0%) hue-rotate(138deg) brightness(96%) contrast(96%);}
.ATSVGDimBlue {filter: invert(62%) sepia(33%) saturate(358%) hue-rotate(160deg) brightness(90%) contrast(85%);}
.ATSVGLightBlue {filter: invert(88%) sepia(6%) saturate(744%) hue-rotate(170deg) brightness(104%) contrast(97%);}

/* eff1fd 31279d ec8008 dc7b00 ffd9b7 e7e8ec */
.ATSVGPrevBG {filter: invert(88%) sepia(19%) saturate(100%) hue-rotate(193deg) brightness(97%) contrast(112%);}
.ATSVGPrevPrimary {filter: invert(11%) sepia(49%) saturate(6747%) hue-rotate(246deg) brightness(96%) contrast(93%);}
.ATSVGPrevSecondary {filter: invert(53%) sepia(85%) saturate(2356%) hue-rotate(3deg) brightness(99%) contrast(94%);}
.ATSVGPrevSecondaryDark {filter: invert(50%) sepia(42%) saturate(6009%) hue-rotate(19deg) brightness(106%) contrast(101%);}
.ATSVGPrevSecondaryLight {filter: invert(82%) sepia(19%) saturate(535%) hue-rotate(336deg) brightness(104%) contrast(104%);}
.ATSVGPrevBGNav {filter: invert(92%) sepia(5%) saturate(601%) hue-rotate(199deg) brightness(106%) contrast(85%);}

/*c1c0e1 5d52ce 4eae84 e94929 3a6bb9 b4b4b4*/
.ATSVGMcqDefault {filter: invert(85%) sepia(5%) saturate(1678%) hue-rotate(202deg) brightness(96%) contrast(83%);}
.ATSVGMcqActive {filter: invert(29%) sepia(72%) saturate(1664%) hue-rotate(228deg) brightness(91%) contrast(86%);}
.ATSVGMcqCorrect {filter: invert(61%) sepia(35%) saturate(539%) hue-rotate(102deg) brightness(91%) contrast(91%);}
.ATSVGMcqIncorrect {filter: invert(39%) sepia(84%) saturate(3739%) hue-rotate(348deg) brightness(96%) contrast(90%);}
.ATSVGMcqAttempted {filter: invert(32%) sepia(86%) saturate(519%) hue-rotate(179deg) brightness(100%) contrast(86%);}
.ATSVGMcqDim {filter: invert(92%) sepia(0%) saturate(897%) hue-rotate(201deg) brightness(82%) contrast(80%);}

.ATSVGDrag {filter: invert(94%) sepia(6%) saturate(4319%) hue-rotate(167deg) brightness(81%) contrast(89%);}

.DDIconContainer.Over, .DDextraBar.Over {
    background-color: yellow;
}

.DDtd3, .DDtd4 { width:40px }

.DDBlockDisplay, .DDOverDisplay {
    position: absolute; top:0; left:0; width:100%; height: 100%; margin-bottom: -10px;
    background-color:rgba(99,99,99,0.4); /*border-radius:12px;*/
}
.DDOverDisplay {background-color:rgba(255, 255, 169, 0); /*border:1px solid orange;*/}

.DDAreaForDrop {
    position: absolute; top:0; left:0; width:100%; height: 100%;
    background-color:rgba(99,99,99,0);
}

.dragStyle {width: 100%; border-radius:12px }
/*.dragStyle {box-shadow: 5px 5px 5px;border-radius:12px }*/

.cssAlert {
    display: inline-block; vertical-align:middle; overflow:auto; border-radius: 8px;
    width:60vw; min-height:40vh; background-color:white; padding:16px
}
/*************************************/
.switch0Container {
    display: flex; flex-direction: row;
    justify-content: flex-start; align-items: center;
    position: relative; flex:1;
}
.switch0Body { display: flex; position: relative; cursor: pointer; }
.switch0Bar { display: flex; position: absolute; top:0; transition: left 0.2s; animation-fill-mode: forwards; }
.switch0Ball { display: flex; position: absolute; background-color: white; overflow: visible; }
/*************************************/

.colorButton {
    display: flex; flex-direction: column;
    justify-content: center; align-items: center; text-align: center;
    color: white; padding: 5px; border-radius:6px; margin:4px;
}

.insertEle { display:inline-flex; background-color: #08a; color: white; border: 1px solid #B7EFBA; }

/*******************************/
.tabQContainer, .tabQContainerReadOnly { position: relative;
    display:flex; flex-direction:row;justify-content: space-between;align-items: flex-start;
    width:100%; background-color: #efefef; min-height:0; height:100%; padding-top: 40px;
    flex:1 1 1px ;
}
.tabQContainer { background-color: #eff1fd; height:100%; flex:1 1 1px ; }

/*padding: 10px*/
.tabQContent { position: relative;
    display:flex; flex-direction: column; justify-self: flex-start; align-items: center;
    width:100%; padding: 5px 5px 0 5px; min-height:0; margin: 0; overflow: auto;
    flex-grow:1; height:100%;
}

.tabQContent.ReadOnly{background-color: #eff1fd;}
.tabQContent.ReadOnly.fullPage {height:100%;}
.tabQContent.Preview{ height:100%; background-color: #eff1fd;}
.tabQContent2 {display:flex; flex-direction: column; justify-self: flex-start;
    width:100%; max-width: 1024px; text-align: left;
}
.tabQContent3 {
    display:flex; flex-direction: column; justify-self: flex-start;
    width:100%; box-shadow: 0 0 3px #A1A1A1;
}
/*page background*/
.tabQContent.atView,.tabQContent.ReadOnly.atView {background-color: #eff1fd;}
.tabQContent.atEdit,.tabQContent.ReadOnly.atEdit {background-color: #eff1fd;}
.tabQContent.teView,.tabQContent.ReadOnly.teView {background-color: #eff1fd;}
.tabQContent.teEdit,.tabQContent.ReadOnly.teEdit {background-color: #f0e6e4;}


.tabDoQContent {
    display:flex; flex-direction: column; justify-self: flex-start; align-items: center;
    width:100%; margin: 0; height:auto; /*min-height:0; padding: 10px;*/
}

/*.tabDoQContent{ flex-grow:1; height:100%;}*/
.tabDoQContent.Editable {overflow: auto;}
/*.tabDoQContent.single{margin-bottom: 60px;} for not hide by bottom bar*/
.tabDoQContent.list{overflow:visible;} /*for FIB list mode, dont scroll when pop up answer*/
.tabDoQContent.ReadOnly{background-color: #eff1fd;}
.tabDoQContent.ReadOnly.fullPage {height:100%; padding:10px}
.tabDoQContent.Preview{ /*height:100%;*/ background-color: #eff1fd;}
.tabDoQContent.Overflow{ overflow:auto;}
.tabDoQContent2 {display:flex; flex-direction: column; justify-self: flex-start;
    width:100%; max-width: 1024px; text-align: left; padding-top: 5px;
}

/*do not add align-items for CK readonly*/
.tabDoQContent3 { 
    display:flex; flex-direction: column; justify-self: flex-start; padding:10px 0 5px 0;
    width:100%; max-width: 100%; box-shadow: 0 0 3px #A1A1A1; background-color: white;
}
/*page background*/
.tabDoQContent.atView,.tabDoQContent.ReadOnly.atView {background-color: #eff1fd;}
.tabDoQContent.atEdit,.tabDoQContent.ReadOnly.atEdit {background-color: #eff1fd;}
.tabDoQContent.teView,.tabDoQContent.ReadOnly.teView {background-color: #eff1fd;}
.tabDoQContent.teEdit,.tabDoQContent.ReadOnly.teEdit {background-color: #f0e6e4;}
/*display:flex; flex-direction: column; justify-content: center; align-items: flex-start;    
width: 100%; flex-shrink: 0; */
.ATQtnTableContainer { 
    display:flex; flex-direction: column; justify-content: center; align-items: flex-start;
    flex-shrink: 0; 
    position:relative; scroll-behavior: smooth; overflow-x: auto;
    width:100%; max-width: 960; background-color: white; padding:10px 0 10px 0;}
.ATQtnTableContainer2 { 
    scroll-behavior: smooth; overflow-x: auto;    
    width:100%; max-width: 960; background-color: white;}    
/*.ATQtnTableContainer {display:flex;flex-direction: column; justify-content: flex-start; align-items: center;
    width:100%; max-width: 960; background-color: green; padding:10px; overflow: auto;}    */
.ATQtnTableAns { width:100%; padding:0; margin:0; border: none; border-spacing: 0;
}
/*.ATQtnTableAns { border-collapse: collapse; width:100%;
    padding:0; margin:0; border: none; border-spacing: 0;
}*/

.ATQtnTableAns th {min-width: 150px; background-color: white}
.ATQtnTableAns td {min-width: 150px; background-color: white}
/*.ATQtnTableAns tr { display:flex; width:100%; background-color: red;}*/
/*ATQtnTableAnsth1 = table ans type - label*/
/*text-align: center;*/ 
.ATQtnTableAnsth1, .ATQtnTableAnsth2, .ATQtnTableAnstd {
    border-bottom: 1px solid lightgray; 
    padding: 10px 15px 10px 15px;
}
.ATQtnTableAnsth1 { min-width:130px !important;max-width:130px !important;width:130px;z-index:2}
.ATQtnTableAnsth2 { background-color:white;z-index:2 }
.ATQtnTableAnsth1.fixedCol { left:0; position:sticky;}
.ATQtnTableAnsth2.fixedCol { left:0; position:sticky;}
.ATQtnTableAnstd.fixedCol { left:0; position:sticky;}
.rightShadow {box-shadow: 8px 0 10px -8px rgba(10,10,10,0.2);}

.ATQtnTableAnsLabel { min-width:130px !important;max-width:130px !important;width:130px;z-index:2;
    text-align: center; /*vertical-align: center; border: 1px solid black;*/
}
.ATQtnTableAnsLabel.fixedCol { left:0; position:sticky;}

.ATQtnTableAnsCell {display:flex;flex-direction: column; justify-content: flex-start; align-items: center;}
.ATQtnTableAnsCell>span { width:100% }
.rightTArrow { position: absolute; top:60px; right:0;}
.leftTArrow { position: absolute; top:60px; left:0;}

/*
.ATQtnTableAns > tbody > tr > td:last-child {
    width:100%;
}
*/

.AnsLabelCharHead {
    border-radius: 6px; background-color: white; cursor: pointer;
    padding: 5px; margin: 5px; width:50px; height:50px; color:#c1c0e1;
    font-size: 20px; font-weight: bold; border: 2px solid #c1c0e1;
}
.AnsLabelCharHead.preview {color:#c1c0e1; border: 2px solid #c1c0e1;}
.AnsLabelCharHead.Sel {color:white; background-color: #4EAE84; border: 2px solid #4EAE84;}
.AnsLabelCharHead.Sel.preview {color:white; background-color: #5d52ce; border: 2px solid #5d52ce;}
.ansListCell {text-align: left;}

/*answer color setting: background color, font color, border style + color*/
/*mc label default */
.ansDefault {color:#5d52ce;background-color:white;border:2px solid #c1c0e1}

/*mc list default */
.ansDefault {color:white;background-color:#c1c0e1;border:2px solid #c1c0e1}

.ansChosen {color:white;background-color:#5d52ce;border:2px solid #5d52ce}
.ansCorrect {color:white;background-color:#4eae84;border:2px solid #4eae84}
.ansWrong {color:white;background-color:#e94929;border:2px solid #e94929}
/*for mc table checkbox ansCorrectNotChosen another background color setting */
.ansCorrectNotChosen {color:#4eae84;background-color:white;border:2px dashed #4eae84}
.ansCorrectNotChosen2 {color:white;background-color:#4eae84;border:2px solid #4eae84}

.ansBGEdit {background-color: #c1c0e1}
.ansBGNormal {background-color: #c1c0e1}

.ansScreenEdit {height:100%}
.ansScreenFit {height:auto}

.QtnOptionsTop { display:none;flex-direction: column; justify-content: flex-start; height:100%; user-select: none; }

.ExOptionsTop { display:none;flex-direction: column; justify-content: flex-start; height:100%; user-select: none; padding-top: 50px; }
.ExQtnEditTop { display:flex;flex-direction: row;justify-content: flex-end; width: 100%;
    border-bottom: 1px solid #e2e2e2; background-color: white; padding: 8px;
}

.VMenuContainer {
    display:flex;flex-direction: column; justify-content: flex-start; 
    width: 300px; min-height:100%; height:100%; user-select: none; background-color: white;
    overflow:auto; /*padding: 0 15px 10px 5px;*/
}

.VMenuHead, .VMenuHeadexpanded {
    display:flex; flex-direction: row; justify-content: space-between; align-items: center;
    width: 100%; border-bottom: 1px solid #e2e2e2; padding: 15px; color:#AFAFAF; cursor: pointer;
}
.VMenuHeadexpanded {color:black}

.VMenuTab {display:flex; flex-direction: column; justify-content: flex-start; align-items: center;
    width:100%; margin-bottom: 10px;}

.VMenuContent { display: block; height: auto; /*transition: height 0.2s ease-in;*/ }

/******************************************/
.ATPDMenu { position: relative; user-select: none;
    display:inline-flex;flex-direction:column;justify-content:flex-start;align-items:center;
}

.ATIcon { color:#2995cd; }
.ATIcon:hover { background-color:#2995cd; color:white; }

.ATPDHeader, .ATPDHeader2 { display:flex;flex-direction:row;justify-content:flex-start;align-items:center;
    color:#2995cd; border: 1px solid #2995cd; border-radius: 8px; padding: 4px 8px 4px 8px; white-space: nowrap;
}
.ATPDHeader:hover, .ATPDHeader.show {
    background-color: #2995cd; color: white;
}
.ATPDHeader2 {
    color: rgba(113, 131, 144, 1); border: 0px solid rgba(113, 131, 144, 1);
}
.ATPDHeader2:hover, .ATPDHeader2.show {
    background-color: rgba(113, 131, 144, 1); color: white;
}

/*position:absolute; top:30px; left:0*/
.ATPDContainer, .ATPDContainerLeft, .ATPDContainerRight {position:absolute; top:38px; padding:10px 0px 10px 0px;
  display:flex; flex-direction: column; justify-content: flex-start; background-color: white;
  align-items: center; border: 1px solid #2995cd; border-radius: 8px; z-index: 500;
}
.ATPDContainerLeft { left: 0}
.ATPDContainerRight { right: 0}

.ATPDrow {display:flex;flex-direction:row;justify-content:flex-start;align-items:center; 
    margin:0; padding: 4px 8px 4px 8px; width:100%}
.ATPDrow:hover {background-color: rgba(233,236,239,1);}

.ATPDrowLabel {display:flex;flex-direction:row;justify-content:flex-start;align-items:center; 
    margin:0; padding: 4px 8px 4px 8px; width:100%}

/*******************************************/
.ATDDAnsMenu { position: relative; user-select: none; display:inline-flex; min-height:42px; 
    border-radius: 6px;
    /*height:42px; flex-direction:column;justify-content:flex-start;align-items:center;*/
}

/*
.ATDDAnsHeaderCont { display:flex;flex-direction:column;justify-content:center;align-items:center;
    color:black; border: 1px solid #c1c0e1; border-radius: 6px; padding: 2px; white-space: nowrap;
}
*/

.ATDDAnsHeader { display:flex;flex-direction:row;justify-content:flex-start;align-items:center;
    color:black; border: 1px solid #c1c0e1; border-radius: 6px; padding: 2px;
    white-space: nowrap; overflow-x:auto;
} /*overflow: auto; white-space:pre-wrap; overflow-x: auto; overflow-y: hidden;*/
/*.ATDDAnsMenu > .ATDDAnsHeader .EditorReadOnly {margin-top: 5px; }*/
.LDAnsBlockEdit .EditorReadOnly {margin-top: 5px; }

.ATDDAnsHeader.correct { color: #4eae84; border-color: #4eae84; background-color: #E5F3ED; }
.ATDDAnsHeader.wrong { color: #e94929; border-color: #e94929; background-color: #FDECE9; }

.ATDDAnsHeaderResult { display:flex;flex-direction:row;justify-content:flex-start;align-items:center;
    color:#4eae84; border: 1px solid #4eae84; border-radius: 6px; padding: 2px; 
    white-space: pre-wrap; overflow-x: auto;
}

/*#5d52ce  top:45px;*/ 
.ATDDAnsContainer, .ATDDAnsContainerLeft, .ATDDAnsContainerRight,
.ATDDAnsContainerResult, .ATDDAnsContainerResultLeft, .ATDDAnsContainerResultRight {
  position:absolute; top:calc(100%);
  display:flex; flex-direction: column; justify-content: flex-start; align-items: flex-start; 
  border: 1px solid #c1c0e1; border-radius: 6px; z-index: 110;
  background-color: white; white-space: nowrap; box-shadow: 0 0 3px #A1A1A1;
}
/*padding:2px;*/
.ATDDAnsContainerResult, .ATDDAnsContainerResultLeft, .ATDDAnsContainerResultRight {
    color: #4eae84; border-color: #4eae84;
}

.ATDDAnsContainerLeft, .ATDDAnsContainerResultLeft { left: 0}
.ATDDAnsContainerRight, .ATDDAnsContainerResultRight { right: 0}

/*
padding: 12px 5px;
white-space: pre-wrap;
overflow-x: auto;

.ATDDAnsrow:not(:last-child) {
    border-bottom: 1px #e9edf0 solid;
}
*/
.ATDDAnsrow, .ATDDAnsrowResult {display:flex;flex-direction:row;justify-content:flex-start;align-items:center; 
    margin:2px 0 2px 0; padding:5px; white-space: pre-wrap; width:100%;
    overflow-x:auto;
} 
    /* overflow: auto*/
.LDAnsBlock .ATDDAnsrowResult {white-space: pre-wrap;}
.LDResultBottom .ATDDAnsrowResult {white-space: pre-wrap;}
.ATDDAnsrow:not(:last-child) {
    border-bottom: 1px #e9edf0 solid;
}    
.ATDDAnsrowResult:not(:last-child) {
    border-bottom: 1px #e9edf0 solid;
}  
.ATDDAnsrow:hover {background-color: #E6E6E6;}

.ATDDAnsrowLabel {display:flex;flex-direction:row;justify-content:flex-start;align-items:center; 
    margin:2px 0 2px 0; white-space: nowrap;}

.ATDDAnsrow.span {padding:0;}

.ATDDAnsOption:hover { background-color: transparent;}

.ansInputLabel {
    display: inline-flex; min-height:42px; position: relative; align-self: center;
}

.ansInputContainerBorder { vertical-align: middle; margin-bottom: 5px;
    display: inline-flex; min-height:42px;  position: relative; flex-wrap: wrap;
    word-break: break-word; line-height: normal; white-space: pre-wrap;
    flex-direction: row; justify-content: flex-start; align-items: center;
}
/*padding:0  padding: 0 5px 0 5px;*/
.ansInputContainerBorderLD {
    display: inline-flex; min-height:42px; position: relative; flex-wrap: wrap;
    word-break: break-word; line-height: normal; white-space: pre-wrap; width:100%;
    margin-top: 1px; flex-direction: row; justify-content: flex-start; align-items: center;
}

.ansInputContainerBorderLD .ck-content {
    display: inline-flex; border: none !important; padding:0 !important;
    line-height: normal; width:100%; box-shadow: none !important; margin: 1px 0 1px 0px;
}
.ansInputContainerBorderLD .ansInputContainer .ck-content {
    padding: 0 !important;
}

.ansInputContainerBorderLD .ansInputContainer .ck-content.EditorReadOnly > p {
    margin: 0;
}

.ansInputContainer .ck-content.EditorReadOnly > p {
    margin: 0;
}

.ansInputContainerDDBorder {
    display: inline-flex; min-height:42px; flex-wrap: wrap;
    word-break: break-word; line-height: normal; white-space: pre-wrap;
    margin-top: 1px; flex-direction: row; justify-content: flex-start; align-items: center;
}

.ansInputContainer { position:relative;
    display: inline-flex; flex-direction: row; justify-content: space-between; align-items: center;    
    border-radius:6px; border: 1px solid #c1c0e1; 
    padding: 0 15px 0 15px; min-height:42px; width:100%; min-width: 80px;
    word-break: break-word; line-height: normal; 
    white-space: nowrap; overflow-x: auto;
}

/*
.ansInputContainer {
    display: inline-flex; flex-direction: row; justify-content: space-between; align-items: center;
    border-radius:6px; border: 1px solid #c1c0e1; 
    padding: 0 15px 0 15px; min-height:42px; width:100%; min-width: 80px;
    word-break: break-word; line-height: normal; white-space: pre-wrap;
} 
*/
/*padding: 0 15px 0 15px;*/
.ansInputContainer.correct { color:#4eae84; border-color: #4eae84; background-color: #E5F3ED; }

.asnDDResultHead {
    display:flex; flex-direction: row;justify-content:space-between;align-items: center;
    color:#4eae84; border-color: #4eae84; background-color: white;
    width:100%;
}

.ansDisplayBlock {position: relative; user-select: none; min-height:42px; 
    display:flex;flex-direction:row;justify-content:space-between;align-items:center;
    color:#4eae84; border: 1px solid #4eae84; border-radius: 6px; padding: 2px;
    overflow-x:auto; white-space: pre-wrap; background-color: white;
    max-width: 100%;
} /*overflow: auto; white-space: pre-wrap;*/

.ansInputContainer.wrong { color:#e94929; border-color: #e94929; background-color: #FDECE9; }
/*
.ansInputContainer .ck-content {
    display: inline-flex; border: none !important; padding:0 5px 0 5px !important;
    line-height: normal; width:100%; box-shadow: none !important; margin: 1px 0 1px 0px;
}
*/
.ansInputContainer .ck-content {
    display: inline-flex; border: none !important; padding:0px 1px 0 1px !important;
    line-height: normal; width:100%; box-shadow: none !important;
}

/*for dd combobox ck readonly, but affect dd text input*/
/*.ansInputContainer .ck-content.EditorReadOnly {padding-top:5px !important}*/
/*.LDAnsBlock .ATDDAnsHeader .ck-content.EditorReadOnly {padding-top:5px !important;}*/
.LDAnsBlock .ATDDAnsHeader .ck-content.EditorReadOnly p {margin-bottom: 0;}
.LDAnsBlock .ck-content.EditorReadOnly p {margin-bottom: 0;}
.LDAnsBlockAns .ck-content.EditorReadOnly p {margin-bottom: 0;}
.LDAnsBlockEdit .ck-content.EditorReadOnly p {margin-bottom: 2px;}
.LDResultBottom .ck-content.EditorReadOnly p {margin-bottom: 0px;}

.ansInputContainer .ck-content span { min-height: 0 !important; }
.ansInputContainer .ck-content .ck-widget { overflow: auto; width: 100%; }
.ansInputContainer .ck-content .ck-math-widget {
    margin-top: 0rem !important; margin-bottom: 0rem !important;
}

.ansFIBInputBox { margin:0; text-align:left; border:none; width:100%; outline:none; box-shadow: none; padding:0 }

.ansEleInput {outline:none; border:none; margin:0; padding: 0; text-align: center; color:black}

/******************************************/
.iconButton { display: flex; flex-direction: row; justify-content: center; align-items: center;
  padding: 5px 15px 5px 15px; border-radius:5px;
}

.adjustValueButton1 {display:flex;flex-direction:row;justify-content:space-between;align-items:center;
    border: 1px solid #2995cd; border-radius: 8px; width:100px; height: 30px; overflow: hidden;
}
.adjustValueButton2 {
    border: 1px solid #E6E6E6; border-style: none solid none solid; padding-top: 4px; height: 28px;
}

.quesKeyWord {
    display:flex; flex-direction: row; justify-content: space-between; align-items: center;
    border: 1px solid #AFAFAF; border-radius: 6px; overflow: hide; width:90%;
}

.quesKeyWordList {
    display: flex; flex-direction:row; flex-wrap: wrap; justify-content: flex-start;
    width:90%; margin-top: 5px;
}

.quesKeywordItem { 
    display:flex; flex-direction: row; justify-content: flex-start; align-items: center;
    border-radius: 6px; color: #2995cd; background-color: #f1f7ff; padding:2px; margin: 2px;
}

/********************************************/
/*        <div style={{}}>*/

.ATQtnFilterContainer { 
    display:inline-block; width:90vw; height:90vh; vertical-align:middle; border-radius:8px; 
    background-color:#fff; overflow:auto
}

.ATQtnFilterContent {
    display: flex; flex-direction: column; justify-content: flex-start; align-items: center;
}

.ATQtnFullScreenContainer {
    position: fixed; top:0; left:0;
    display:flex; flex-direction: column; justify-content: flex-start; align-items: center;
    min-height:100%; width:100%; height:100%; z-index: 100;
}

/*z-index: 500*/
.ATQtnSingleContainer {
    position: fixed; top:0; left:0; align-self: flex-start;
    display:flex; flex-direction: column; justify-content: flex-start; align-items: center;
    width:100%; z-index: 1000; height:100%; min-height:100%; overflow: auto; 
    background-color: #eff1fd;
    padding-top: 60px; margin-bottom: 10px;
}

.ATQtnSingleContainer2 {
    display:flex; flex-direction: column; justify-content: flex-start; align-items: center;
    width:100%; background-color: #eff1fd;
}

.ATQtnSingleContainer3 {
    position: absolute; top:0; left:0; align-self: flex-start;
    display:flex; flex-direction: column; justify-content: flex-start; align-items: center;
    width:100%; z-index: 500; height:100%; min-height:100%; overflow: auto; 
    background-color: #eff1fd;
}

.ATQtnSingleInfoCont {
    display: flex; flex-direction: row; justify-content: space-between; align-items: center;    
    width:100%; max-width:1024px; min-height:60px; background-color: #eff1fd; margin-top: 5px;
    flex-shrink: 0; flex-wrap: wrap;
    /*padding:0 10px;*/
}

/*
.ATQtnSingleInfoCont {
    display: flex; flex-direction: row; justify-content: space-between; align-items: center;    
    width:100%; max-width:1024px; min-height:60px; background-color: #eff1fd; margin-top: 5px;
    flex-shrink: 0;
}
*/

.ATQtnSingleInfoMark {
    display:flex;flex-direction:row;justify-content:center;align-items:center;
    background-color: #1d4cb0; color: white; gap:5px; border-radius: 6px;
    padding: 5px 10px;
}
.ATQtnSingleInfo {
    display: flex; flex-direction: row; justify-content: flex-end; align-items: center;    
    background-color: #eff1fd;
}
/*
.ATQtnSingleInfo {
    display: flex; flex-direction: row; justify-content: flex-end; align-items: center;    
    height:60px; background-color: #eff1fd; flex-wrap: wrap;
}
*/
.ATQtnSingleMarks { display:flex; flex-direction: row; justify-content: center; align-items: center;
    color:rgba(49,39,157,1); border:1px solid rgba(49,39,157,1); padding: 5px 12px; background-color: white;
    border-radius: 6px; height:'38px'; white-space: nowrap;
}

.ATQtnSingleContent_bk {
    display:flex;flex-direction: column; margin: auto;/*justify-content: center; align-items: center; */
    flex:1; width: 100%; background-color: #eff1fd;
    padding:0 10px 60px 10px;
    
    position: relative; /* for fabric canvas test*/
    border: 1px solid violet;
    /*max-width: 1024px;*/
}


.ATQtnSingleContent {
    display:flex;flex-direction: column; margin: auto;
    justify-content: flex-start; align-items: center;
    flex:1; width: 100%; background-color: #eff1fd;
    padding:0 10px 60px 10px;
    
    position: relative; /* for fabric canvas test*/
    /*border: 1px solid violet;*/
    /*max-width: 1024px;*/
}

.ATQtnSingleContent2 {
    display:flex;flex-direction: column;/*justify-content: center; align-items: center; */
    width: 100%; background-color: transparent; overflow: visible;
    /*padding:4px 8px 10px 8px; */
    padding:4px 8px 10px 8px;
    position: relative; /* for fabric canvas test*/
    /*border: 1px solid orange;*/
    /*max-width: 1024px;*/
    max-width: 1032px;
}

.draw-editor-toolbar { top: 0 !important; margin-top: 0 !important; margin-bottom: 0 !important;} 
.drawOverlayExtra { min-height: 110px; }

.drawOverlay {
    display:flex; flex-direction: column; justify-content: flex-start; align-items: center;
    position: absolute; left:0; top:0; z-index: 101;
    width: 100%; height: calc(100% - 65px);
    /*background-color: rgba(0,255,0,0.02);*/
    margin-top: 65px; padding: 0;
    border: 2px dashed green;
}

.drawOverlayBarEx { position: fixed; z-index: 101;
    display:flex; flex-direction: column; justify-content: flex-start; align-items: center;
    padding:0 10px; min-height: 20px; align-self: center;
    overflow: visible; max-width: 380px;
}

.drawOverlaySticky {
    position: -webkit-sticky; /* Safari */
    position: sticky; top: 0; bottom: 1px;
    width: fit-content; height: 100px;
    align-self: center; padding-top:8px; max-width: 380px;
    /*background-color: rgba(255,255,0,0.2);*/
    z-index: 2;
    /*border: 2px dashed blue;*/
}

.fabricTest {
    position:absolute; top:0; left:200px; display:flex; z-index: 1200;
    width:800px; height:50%; background-color: rgba(0,255,0,0.2);
    border: 1px solid red; overflow: hidden;
}
/*padding-bottom:45px;*/
/*
.ATQtnHeader {
    position: fixed; top: 0; left:0; z-index: 1002; 
    display: flex; flex-direction: row; justify-content: space-between; align-items: center; 
    color:white; width:100%;
    height: 60px; min-height:60px; padding: 0 20px 0 20px; background-color: #31279d; 
}
*/
/*
.asmSoundBar {
    position:fixed; top:60px; left:0; z-index: 1000;
    display: flex; flex-direction: row; justify-content: center; align-items: center; 
    width: 100%; height: 100px; background-color: yellow; max-width: 1024px;
}
*/

.ATQtnHeader {
    position: -webkit-sticky; position: sticky;
    /*position: fixed;*/
    top: 0; left:0; z-index: 1000; align-self: flex-start;
    display: flex; flex-direction: column; justify-content: center; align-items: center; 
    color:white; width:100%;
    height: 60px; min-height:60px; padding: 0 10px 0 10px; background-color: #31279d; 
}

.ATQtnHeaderSlide {
    position: fixed; top: 60px; left:0; z-index: 1001; padding:15px;
    display: flex; flex-direction: row; justify-content: flex-start; align-items: center;
    flex-wrap: wrap; width:100%; background-color: white;
    border-bottom: 1px solid #EC8008;
}

.ATQtnDummyHeaderSlide {
    position: relative; top:-1000; left:0; z-index:1001;
    z-index: 1001; padding:15px; margin-top: 60px;
    display: flex; flex-direction: row; justify-content: flex-start; align-items: center;
    flex-wrap: wrap; width:100%; background-color: white;
    border-bottom: 1px solid #EC8008;
}

.ATQtnHeaderBtn { 
    display:flex; flex-direction: column; justify-content: center; align-items: center;
    border-radius:100%; width:38px; height:38px; background-color: #31279d; cursor: pointer;
    /*margin: 2px;*/
}
.ATQtnHeaderBtnSel {
    outline-offset: 2px; outline-style: solid; outline-color: #31279d; outline-width: 2px;
    width:36px; height:36px;
}
/*
.ATQtnSingleRow1 {
    background-color: #31279d; display: flex; flex-direction: row; justify-content: space-between; align-items: center; 
    color:white; width:100%; position: -webkit-sticky; position: sticky; top: 0; z-index: 1002;
    height: 60px; padding: 0 20px 0 20px;
}
*/

.ATQtnSingleBottomRow {
    position:-webkit-sticky; position: sticky;
    /*position: fixed;*/
    bottom:0; left:0; right:0;
    display: flex; flex-direction: row; justify-content: space-between; align-items: center; 
    background-color: #31279d; min-height:60px; height: 60px; width: 100%;
    padding: 10px; z-index: 111;
}

.QtnTimerContainer {
    display:flex;flex-direction: column; justify-content: center; align-items: center;
    background-color: white; color:black; border-radius: 6px; width:90px; height:40px;
    margin-right: 10px;
}

.bottomOverLay {
    position: fixed; top:0; left:0; z-index: 1010; width:100%; height:100%; background-color: rgba(99,99,99,0.5);
}

.bottomSlideBar {
    position: fixed; left: 0; width: 100%; background-color: white;
    display:flex; flex-direction: column; justify-content: flex-start; align-items: center;
    bottom: -100%; max-height:100%; overflow:auto; transition: bottom 0.3s ease-in;
    z-index:1011;
}
.bottomSlideBarHeader {
    display:flex; flex-direction: row; justify-content: space-between; align-items: center;
    /*display:flex; flex-direction: row; justify-content: flex-start; align-items: center;*/
    width:100%; padding:5px 20px 5px 20px; background-color: #ec8008; color: white;
}
.bottomSlideBarContent {
    display:flex; flex-direction: row; justify-content: flex-start; align-items: center;
    width:100%; padding:5px 10px 5px 10px; background-color: white; color: black;
}
/************************************************/
.filterRow1 {display: flex; flex-direction: row; justify-content: space-between; align-items: center; 
    width:100%; background-color: #2995cd; color:white; position: -webkit-sticky;
    position: sticky; top: 0; z-index: 110;}

.ATfilterOptions { display: flex; flex-direction: row; justify-content: center; align-items: flex-start;
    width:100%; padding: 0 5px 0 10px}
.ATfilterCol {display: flex; flex-direction: column; justify-content: flex-start; 
    align-items: center;width:50%; max-width: 50%; margin:10px;}
.ATfilterTable {width:100%; border-spacing: 5px;}
.ATfilterLine {border:1px solid #2995cd; border-width: 1px 0 0 0; width:95%; height:1px}

.ATfilterLabel {width:160px; text-align: left;}
.ATfilterLabel2 {width:120px; text-align: left;}
.ATfilterRow, .ATfilterRow2 {display: flex; flex-direction: row; justify-content: flex-start;
    align-items: center;}

.ATfilterRow2 {text-align:left; }

/******************************************************/
.ATQtnsListContainer {
    display: flex; flex-direction: column; justify-content: flex-start; align-items: center; 
    margin:5px; width: 100%; overflow: visible; padding: 10px;
    border: 1px solid #B1B1B1; border-radius: 8px; box-shadow: 0 0 3px #A1A1A1;
}

.ATQtnsListContent {
    display: flex; flex-direction: column; justify-content: flex-start; align-items: center; 
    width: 100%; border: 1px solid #efefef; border-radius: 8px; overflow: visible;
    background-color: #eff1fd;
}

.ATQtnsListHead {
    display: flex; flex-direction: row; justify-content: space-between; align-items: center;
    width: 100%; padding: 10px; flex-wrap: wrap;
}

.ATQtnsListQuestion {width:100%; max-width:1024px; padding:10px;
    /*border-radius: 6px; border: 1px solid #D1D1D1;*/ }

.ATQtnsListDPBtn {display:flex;flex-direction:row;justify-content: center;align-items: center;
    border-radius: 6px; width: 120px; padding:2px 5px 2px 5px;
}

.ATQtnsListTxt { text-align: center; padding: 5px; margin-right:5px; border-radius: 4px; background-color: white; }
/******************************************************/
/************************/
/*
.ATQtnPreviewContainer { position:absolute; top:0; left:0; width:100%; min-height: 100%;
    display:flex; flex-direction: column; justify-content: flex-start; align-items: center;
    z-index: 100; overflow: auto; background-color: #eff1fd;
}
*/

/* ####    Poupup Layers    #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### #### */ 
.LayerMPool { display:inline-block; vertical-align:middle; background-color:#F6F6F6; border-radius:8px; 
    width:100%; height:90vh; padding:10px; overflow: auto;
}
/*
.LayerMPool { display:inline-block; vertical-align:middle; background-color:#F6F6F6; border-radius:8px; 
    width:90vw; height:90vh; margin:4px; padding:10px; overflow: auto;
}
*/

.medFileHeader {display: flex; flex-direction: row; justify-content: space-between; align-items: center; 
    width:100%; background-color:#F6F6F6; position: -webkit-sticky; 
    position: sticky; top: 0; z-index: 110;}

.medFileSelContainer {
    display:flex; flex-direction: column; justify-content: space-between; align-items: flex-start;
    width:100%;
}

.medFileSelTop {
    display:flex; flex-direction: column; justify-content: flex-start; align-items: flex-start;
    width:100%; overflow: auto;
}

.medFileSelBox {
    display:flex;flex-direction: row; justify-content: flex-start; align-items: center;
    flex: 1; border: 1px solid gray; min-height:30px; flex-wrap: wrap; margin-right: 5px;
}

.medFileSubjects {
    display:flex;flex-direction: column; justify-content: flex-start; align-items: flex-start;
    width: 100%; flex: 1 1 1; overflow:auto; 
}

.medFileList {
    display:flex;flex-direction: row; justify-content: flex-start; align-items: center;
    flex-wrap: wrap; border: 1px solid #DFDFDF; width: 100%; flex: 1 1 1; overflow:auto;
    background-color: white; padding:4px;
}

.mediaItem {
    display:flex;flex-direction: column; justify-content: flex-start; align-items: center;
    min-width:210px; max-width:210px; min-height:210px; max-height:210px; overflow: visible; border-radius: 4px; margin: 4px;
    border: 1px solid #C4C4C4; box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25); padding:4px;
}

.mediaItemImgBox { display:flex; flex-direction:column; justify-content:center; align-items:center; flex:1; overflow:auto; }
.mediaItemImg {
    display: inline-block; overflow: hidden; text-align: center; vertical-align: middle;
    max-width:180px; max-height:120px; padding:4px;  
}

.medFileSelBottom {
    /*position: absolute; bottom:0; left:0;*/
    display:flex;flex-direction: row; justify-content: space-between; align-items: center;
    width: 100%;
}

/***********************************/
/*
.FIBAnsDetailContainerBK {
    display:flex; flex-direction: column; justify-content: flex-start; align-items: center;
    width: 100%; background-color: #F9F9F9; padding: 5px; flex:1; overflow: auto;
    min-height: 200px;
}
*/

.FIBAnsDetailContainer {
    position: sticky; bottom:0;left:0; border: 1px solid #e2e2e2; z-index:5;
    display:flex; flex-direction: column; justify-content: flex-start; align-items: center;
    width: 100%; background-color: #F9F9F9; padding: 5px; flex:1; overflow: auto;
    min-height: 50%; /*max-height:50%;*/ box-shadow: 0 0 3px #A1A1A1;
}


.FIBAnsDetailContent {
    display:flex; flex-direction: column; justify-content: flex-start; align-items: center;
    width:100%; flex:1; overflow: auto; min-height: 100px;
}


.aDscoreType1 { display: flex }
.aDscoreType2 { display: none }

.ansDetailHead {
    display: flex; flex-direction: column; justify-content: flex-start; align-items: center;
    width: 100%;
}

.ansDetail1 {
    display: flex; flex-direction: row; justify-content: space-between; align-items: center;
    width: 100%;
}
.ansDetailScore { display: flex; flex-direction: row; justify-content: flex-start; 
    align-items: center;width: auto }

.ansDetail2 {
    display: flex; flex-direction: row; justify-content: flex-start; align-items: center;
    width: 100%;
}

.aDscoreType2 { display: none; }

.FIBAnsDetailTd {
    display:flex; flex-direction: row; justify-content: center; align-items: center;
    margin: 0; padding: 0; border: none; height: 100%; border-radius: 12px; width:100%;
    /*box-shadow: 0 0 3px grey;*/
}

.FIBAnsDetailRow {
    display:flex; flex-direction: row; justify-content: flex-start; align-items: flex-start;
    flex:1; background-color: white; padding: 5px; height:100%; border-radius: 12px;
    border: 1px solid lightgray;
}


.FIBAnsDetailTbl, .FIBAnsDetailTbl > tbody, .FIBAnsDetailTbl > tbody > tr {
    display:flex; padding:0; margin:0; border: none; border-spacing: 0; width:100%; border-radius: 12px;
    background-color: white;
}

.wrs_modal_wrapper.wrs_modal_ios{ height: 100% !important; }

/*:root { --ck-spacing-unit: 0.3em;}*/
.ck.ck-editor__editable_inline > :first-child {
    margin-top: 7px !important; 
}
.ck.ck-editor__editable_inline > :last-child {
    margin-bottom: 7px !important;
}
.ck.ck-editor__editable_inline {
    min-height:0; margin: 0 !important;
}

.DDQtnEditRow .ck-content {padding-bottom:10px !important;}

/*.ck.ck-rounded-corners div:nth-child(n+2){
    z-index: 11 !important;
}*/
.ck.ck-rounded-corners>div.ck-toolbar-container:nth-child(n+2) {
    /*z-index: 11!important; change from 11 to 1090 for inline editor place on bootstrap modal */
    z-index: 1090 !important;
}

/*.ck-body-wrapper {z-index:10001 !important;}*/
.ck-content .table table { overflow: visible !important; }

.ck-content strong {font-weight: 800 !important;}
.ck-content input {display:inline-flex !important; font-weight: 300 !important; align-self: center;}
/*.ck-content.EditorReadOnly {overflow-x: auto;}*/
/*
.ck.ck-toolbar {
    z-index: 1 !important;
}
.ck.ck-balloon-panel {
    z-index: 1051 !important;
}

.ck.ck-balloon-panel:not(.ck-toolbar-container) {
	z-index: 1001;
}
*/
/* fix frontend ckeditor table properties block by bootstrap modal */
.modal-open .ck.ck-balloon-panel { z-index: 1056; }

/* comment for issue EX-665
div.ck-content.EditorReadOnly > p { margin:5px; padding:0;
} 
*/
/*
    display:flex; flex-direction: row; flex-wrap: wrap;
    justify-content:flex-start; align-items: center;
*/
/*div.LDAnsBlockEdit > span {margin:0; padding:0; outline:none; background-color:red}*/
/*div.ck-content.EditorReadOnly {background-color:white}*/
.ck-content span p { display: inline;}
.ck-content blockquote {padding:0} 
.ck-content .EditorReadOnly span p { display: inline; }

/* Prevent content out of the box*/
.EditorReadOnly {word-break: break-word; max-width: 100%;}

.EditorReadOnly table,
.EditorReadOnly td,
.EditorReadOnly th,
.EditorReadOnly img,
.EditorReadOnly video,
.EditorReadOnly audio,
.EditorReadOnly iframe,
.EditorReadOnly div,
.EditorReadOnly span
{ max-width: 100%;}
/*.EditorReadOnly * { max-width: 100%; clear:both;}*/

/*.EditorReadOnly table {table-layout: fixed;}*/
/*.EditorReadOnly td {overflow: auto;}*/
  
.oupanswer { display: inline-block; border: 2px solid #0C8CE9; cursor: pointer; 
    margin:0 2px; border-radius:4px; min-width: 70px; white-space: nowrap; overflow-x: auto;
    /*background-color: #0C8CE9;*/
}
.oupanswer > span {display:inline-block;text-align: center; padding-right:2px;
    vertical-align: center; margin-right:2px; min-width: 25px;
    /*background-color: white;*/
}
span[qtype='DRD'] {border: 2px solid green;}
.oupanswer p {display: inline-block; padding: 0; margin: 0;}
.inner-oupanswer {display: inline-block}

/*.oupQuesEditor p, .oupOneLineEditor p {margin: 5px}*/
/*font-size:16px !important;*/
.ck-content.oupQuesEditor { min-height: 20vh !important; width: 100%;
    background-color: white;}
/*.oupOneLineEditor { height: 52px; width: 100%; background-color: white; border: 1px solid #CFCFCF; }*/
.ck-content.oupEquaEditor {background-color:white; margin: 2px;}
.ck-content.oupEquaEditor p {margin: 2px;}

.ck-content.oupAnswerEditor {background-color:white; word-break: break-word; margin:2px; padding:2px;}
.ck-content.oupAnswerEditor p {margin: 2px;}

.ck-content.oupAnswerAdvEditor {background-color:white; word-break: break-word; margin:2px; padding:2px;}
.ck-content.oupAnswerAdvEditor p {margin: 2px;}



.ckEditorStatusBar {
  display:flex; flex-direction: row; justify-content: flex-end; width: 100%;
  background-color: white; border-top: 1px solid #CFCFCF; padding: 5px;
}

.EditorReadOnly {
    width: 100%; padding:5px 10px 5px 10px; background-color: white;  word-break: break-word;
    clear:both;
    /*border: 1px solid #D1D1D1;*/    
}
.noBackground { background-color: transparent;}

/*
.ansInputEle { border:none; outline:none; border: 1px solid grey; border-radius: 3px;}
.ansDropDownEle { appearance: none; border:none; outline:none; 
    border-radius: 3px; background-color: white; }
*/
/*****************************/
.keysThreeButtons {
    display:flex; flex-direction: row; justify-content: center; align-items: center;
    width:60%; background-color: #0C8CE9; border-radius: 20px;
}

.objContainer {
    display:flex; flex-direction: column; justify-content: center; align-items: center;
    width:100%; border: 1px solid rgba(222,226,230,1); border-radius: 4px; padding:5px; margin:10px 0 10px 0;
}

.kekFileDrop {
    display:flex; flex-direction: column; justify-content: center; align-items: center;
    border:1px dashed rgba(116,154,179,1); width:98%; border-radius: 4px; cursor: pointer;
    color: rgba(116,154,179,1);
}

.kekFileItem {
    display:flex;flex-direction: row;justify-content: space-between;align-items: center;
    width:100%; border: 1px solid rgba(222,226,230,1); margin-top:5px; padding:5px 5px 5px 5px;
    max-width: 100%; overflow: auto; border-radius: 4px;
}

.kekFileItem2 { 
    display:flex;flex-direction: row;justify-content: flex-start;align-items: center;
    border: 1px solid rgba(222,226,230,1); margin:5px; padding:5px; border-radius: 4px; 
    height: 40px; flex-wrap: nowrap;
}

/********************************/
.ATUILabel { display:flex; flex-direction: row; justify-content: flex-start; 
    align-items: center; align-self: flex-start; padding-top: 5px;}

/********************************/
.CtnBtnBarContainer {
    display:flex; flex-direction: row; justify-content: center; align-items: center;
    background-color: #0C8CE9; border-radius: 8px; padding: 5px;
}

.ViewExCtnContainer {
    display:flex; flex-direction: row; justify-content: center; align-items: center;
    padding: 5px;
}

.ViewExCtnBody {
    display:flex; flex-direction: column; justify-content: flex-start; align-items: center;
    flex: 1; border-radius: 6px; border: 1px solid lightgray; overflow: auto;
}

.ViewExCtnHeader {
    display:flex; flex-direction: row; justify-content: space-between; align-items: center;
    width: 100%; background-color: #F1F1F1; padding: 5px;
}

.ViewExCtnSide {
    display:flex; flex-direction: row; justify-content: center; align-items: center;
    align-self: flex-start;
}

/*.ViewExCtnItemLockBK {
    display:flex; flex-direction: column; justify-content: flex-start; align-items: flex-start;
    width: 100%; padding-top:64px;
}
.ViewExCtnItemLock {
    display:flex; flex-direction: row; justify-content: center; align-items: flex-start;
    width: 100%; padding:10px; max-width: 1024px; overflow: auto; flex-shrink: 1;
    margin-bottom: 64px;
}
*/

/*outside single container is flex col center*/
.ViewExCtnItemLock.Single { font-size: 18px;
    display:flex; flex-direction: column; /*justify-content: flex-start; align-items: center;*/
    width: 100%; max-width: 1024px; overflow: auto; flex-shrink: 1; /*padding:10px;*/
    margin: auto;
}
.ViewExCtnItemLockContent {width:100%; padding:4px; margin:auto;}

.ViewExCtnItemLock.List { font-size: 18px;
    display:flex; flex-direction: column; justify-content: flex-start; align-items: center;
    width: 100%; max-width: 1024px; overflow: auto; flex-shrink: 1;
    margin-bottom: 20px; /*padding:10px*/
}

.ViewExCtnItemQtn {
    display:flex; flex-direction: column; justify-content: center; align-items: center; padding-top:0;
    width:100%; max-width:1024px; margin: 0 auto 0 auto; /*ViewExCtnItemQtn*/
}

.ViewExCtnItem {
    display:flex; flex-direction: column; justify-content: flex-start; align-items: flex-start;
    width: 100%; height:100%; padding:0px 15px 5px 15px; flex:1;/*padding-left:50px; */
    overflow: auto; margin-top: 0px;
}
/*div className='flexRowStart' style={{height:'100%',alignItems:'flex-start'}}*/

.ViewExCtnItem2 {
    display:flex; flex-direction: column; justify-content: flex-start; align-items: center;
    width:100%; height:100%; padding: 0px 20px 0 50px;
}

.ViewExLabel {
    display:flex;flex-direction: row;justify-content: flex-start;align-items: center;
    font-size: 16px;
}

.viewExInput {
    flex:1; padding:4px 6px;border-radius:6px; border:1px solid lightgrey;
    background-color: white; margin: 4px 0 4px 0;
}

.ViewExCtnCKReadOnly {
    display:flex; flex-direction: column; justify-self: flex-start;
    width:100%; box-shadow: 0 0 3px #A1A1A1;
    background-color: white;
}

.exAlignLeft {justify-content: flex-start;}
.exAlignCenter {justify-content: center;}
.exAlignRight {justify-content: flex-end;}

/****************************/

.CpATImageCont {
    position: relative; display:flex;flex-direction: column; justify-content: center; align-items: center;   
    width: 100%; max-width: 100%; height: 100%; overflow: auto; min-height: 35px;
    background-color: white; padding: 5px;
}

.CpATImage { max-width:250px; object-fit: contain; }
/*.CpATImage { max-width:100%; object-fit: contain; }*/


.ViewExCtnBodyBorder {
    display:flex; flex-direction: column; justify-content: center; align-items: center;
    width: 100%;
}

.LayerECtnEditTop {
    display:flex; flex-direction: row; justify-content: center; align-items: center;
    height:100%;
}

.ATExEditQContainer {
    width:100%; border-radius: 6px; border:1px solid #efefef;
    box-shadow: 0 0 3px #A1A1A1;
}
.ATExEditQHead, .ATExEditQTail {
    display: flex; flex-direction: row; justify-content: space-between; align-items: center;
    width: 100%; padding: 10px; background-color: #efefef;
}

.ATExEditQHead {border-radius:6px 6px 0 0}
.ATExEditQTail {border-radius:0 0 6px 6px}

.CPExEditContainer { background-color:#eff1fd; height:100%; width:100%; overflow:auto; }

.CPExEditHeader {
    position: fixed; top:0; left:0; z-index:100;
    display: flex; flex-direction: row; justify-content: space-between; align-items: center;
    background-color: white; padding: 5px 15px 5px 15px; width:100%; height: 50px;
}

.CPATPopContent { 
    display: flex; flex-direction: column; justify-content: center; align-items: center;
    background-color:#F6F6F6; border-radius:8px;
    max-width: 100vh; max-height: 100vh; overflow: auto;
    /*display:inline-block; vertical-align:middle; background-color:#F6F6F6; border-radius:8px; 
    width:90vw; height:80vh; margin:4px; padding:10px; overflow: auto;
    */
}

.CpATImageToolsRT {
    position: absolute; top:5px; right:5px;
    display:flex;flex-direction:row; justify-content:flex-end; align-items: center;
}

.CpATImageToolsRD { position: absolute; bottom:5px; right:5px; }

/**************************/
.ATExerSelLayer {
    display:flex; flex-direction: column; justify-content: space-between; align-items: center;
    width:100%; min-height:40vh; overflow: auto;
    border-radius: 8px;
}

.ATExerPreVList { /*position: relative; min-height:100%;*/
    position: fixed; top:0; left:0; z-index: 1000;
    display: flex; flex-direction: column; justify-content: flex-start; align-items: center;
    height:100%; width:100%; overflow:auto; text-align:left; /*padding-top: 60px;*/
    background-color: #eff1fd;
    padding-top:60px;
}

.ATExerTitle {
    display:flex;flex-direction:row; justify-content:flex-start; align-items: center;
    padding:5px 10px; background-color:white; border-radius: 6px; border: 1px solid #31279d;
    color: #31279d; margin: 10px 0;
}

.ATExerBlock {
    display:flex;flex-direction:row; justify-content:flex-start; align-items: center;
    width:100%; padding:10px 30px; margin: 10px 0; 
    background-color:white; box-shadow: 0 0 3px #DBDBDB;
}

/**********************************************************/
.htmlPlayerContainer { width:100%; }
.brightCoveContainer { width: 100%; }

 .youtubeContainer {
     display:flex;
     position: relative; background-color: lightblue;
     width:100%;
 }
 
 .youtubeContainer div {
     position: relative;
     /*padding-top: 56.25%;*/
     width: 100%; height:0; background-color: lightgreen;
 }
 
 .youtubeContainer div iframe {
     position:absolute;
     top:0;
     left:0;
     width:100%;
     height:100%;
 }
 
 
 /*.video-container object, .video-container embed*/
 
.cpMediaUIContainer { display:flex; flex-direction: column; justify-content: center; align-items: center; width:100%; }
 
.cpMediaUIRow, .cpMediaUIRow2, .cpMediaUIRowInline,
.cpMediaUIRow.mini, .cpMediaUIRow2.mini, .cpMediaUIRowInline.mini {
     display:flex; flex-direction: row; justify-content: center; align-items: center;
     width: 100%; background-color: #fdb832; color: white; 
     margin-bottom:1px; padding: 10px; border-radius: 6px 6px 6px 6px;
} /*height:64px;*/

.cpMediaUIRow.vis, .cpMediaUIRow.vis.mini {border-radius: 6px 6px 0px 0px;}
.cpMediaUIRow2, .cpMediaUIRow2.mini {flex-direction: column; justify-content: space-around; 
    border-radius: 0px 0px 6px 6px;}
.cpMediaUIRow3, .cpMediaUIRow3.mini {display: flex; flex-direction: row; justify-content: center; align-items: center;
    width:90%; position:relative;
}
.cpMediaUIContainer.cpMediaUI1.mini { display:flex; flex-direction: column; justify-content: center; align-items: center; width:100%; }
.cpMediaUIContainer.cpMediaUI2.mini { display:none; flex-direction: column; justify-content: center; align-items: center; width:100%; }

.cpMediaUI1 {display:flex}
.cpMediaUI2 {display:none}

 /*.cpMediaUIRow input[type=range], .cpMediaUIRow2 input[type=range] {
    width: 100%; margin: auto; outline: none; padding: 0; height: 5px; margin-top: 6px;*/
 .cpMediaUIRow input[type=range], .cpMediaUIRow2 input[type=range] {
     width: 100%; outline: none; padding: 0; height: 5px;
     background-color: rgba(255, 255, 255, 0.4);
     background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, #087af5), color-stop(100%, #087af5));
     background-image: -webkit-linear-gradient(#fff, #fff);
     background-image: -moz-linear-gradient(#fff, #fff);
     background-image: -o-linear-gradient(#fff, #fff);
     background-image: linear-gradient(#fff, #fff);
     background-repeat: no-repeat;
     border-radius: 10px; cursor: pointer; -webkit-appearance: none;
     /* background-size: 50% 100%; control this size*/
     /* track */
     /* thumb */
}
 
 .cpMediaUIRow input[type=range]::-webkit-slider-runnable-track, .cpMediaUIRow2 input[type=range]::-webkit-slider-runnable-track
 { box-shadow: none; border: none; background: transparent; -webkit-appearance: none; }
 
 .cpMediaUIRow input[type=range]::-moz-range-track, .cpMediaUIRow2 input[type=range]::-moz-range-track
 { box-shadow: none; border: none; background: transparent; }
 
 .cpMediaUIRow input[type=range]::-moz-focus-outer, .cpMediaUIRow2 input[type=range]::-moz-focus-outer 
 { border: 0 }
 
 .cpMediaUIRow input[type=range]::-webkit-slider-thumb, .cpMediaUIRow2 input[type=range]::-webkit-slider-thumb {
     width: 16px; height: 16px; border: 0; background: white; border-radius: 100%;
     -webkit-appearance: none;
     appearance: none; outline: none;
   }
 
 .cpMediaUIRow input[type=range]::-moz-range-thumb, .cpMediaUIRow2 input[type=range]::-moz-range-thumb {
     width: 16px; height: 16px; border: 1px solid grey; background: white; border-radius: 100%;
 }
 
 /*****************************************/
 .mediaBubble {
   background: white;
   color: #fdb832;
   margin-top:2px;
   padding: 0 2px 0 2px;
   position: absolute;
   border-radius: 4px;
   border: 1px solid white;
   min-width:40px;
   top:-1px;
   left: 50%;
   transform: translateX(-50%);
 }
 /*
 .mediaBubble::after {
   content: "";
   position: absolute;
   width: 2px;
   height: 2px;
   background: red;
   top: -1px;
   left: 50%;
 }
 */
 /**********************************************************/

 .dropImg { min-width:100px; min-height:100px; padding:8px; margin:8px; border:2px solid #ccc;}
 .dropImgIn { min-width:100px; min-height:100px; padding:8px; margin:8px; border:1px solid #000;} 
 /*.dropImgImg { }*/

 /*****************************/
 .LDCanvasContainer {
    position: relative;
    display:flex;flex-direction:column;justify-content:center;align-items:center;
    width: 100%; /*border: 1px solid #CFCFCF;*/
  }
   
 .LDCanvasBaseCont {
    position: absolute; top:0; left:0; width:100%; height:100%; 
    /*background-color:rgba(10,10,10,0.5)*/
  }
  
  /*.LDARImg { visibility: hidden; }*/
 
  .LDCanvasContainer2 { position: relative;
    display:flex;flex-direction:column;justify-content:center;align-items:center;
    width: 30%; border: 1px solid grey;
  }

  .LDCanvasAnsTop {
    display:flex; flex-direction: column; justify-content: flex-start; align-items: center; width:100%;
  }

  /*
  .LDCanvasLayer {
    display: flex; flex-direction: row; justify-content: center; align-items: center;
    width: 100%; flex-shrink: 1; min-width: 100%; max-width: 100%; height: auto;
  } 
  */ 

  .LDCanvasLayer {
    display: flex; flex-direction: row; /*justify-content: center; align-items: center;*/
    width: 100%; flex-shrink: 1; min-width: 100%; max-width: 100%; height: auto; overflow:auto;
  }

  .LDAnsLayer {
    display: flex; flex-direction: column; justify-content: flex-start; align-items: flex-start;
    width:70%; height:auto; overflow: visible; align-self: flex-start; padding: 5px;
    align-self: center;
  }
  
  .LDResultBottom {
    display:none; flex-direction: row; justify-content: flex-start; align-items: center;
    background-color: white; flex-wrap: wrap;
  }

  .LDCavBtnCont {
    display: flex; flex-direction: row; justify-content: center; align-items: center; width:100%;
    margin: 15px 0;
  }

  .LDCavBtnContent {
    display: flex; flex-direction: row; justify-content: center; align-items: center;
    border-radius: 10px; background-color: white; box-shadow: 0 0 3px #DBDBDB;
    padding:5px;
  }

  .LDAnsBlock { line-height: normal; min-height:42px; 
    display: flex; flex-direction: row; justify-content: flex-start; align-items: center;
    min-width: 90%; max-width: 90%; flex-wrap: wrap; background-color: white;
  }
  /*.LDAnsBlock .EditorReadOnly {margin-top: 5px;}*/

  .LDAnsBlockAns { display:flex; flex-direction: row; justify-content: flex-start; align-items: center;
    width:100% }
  
  .LDAnsBlockEdit {
    display: flex; flex-direction: row; justify-content: flex-start; align-items: center;
    min-width: 90%; max-width: 90%; border: 1px solid lightgrey; border-radius: 4px;
    white-space:pre-wrap; overflow-x:auto; background-color: white;
  }

  .LDToolBtn { display:flex; flex-direction: row; justify-content: center; align-items: center;
    background-color: white; padding: 10px 10px 8px 10px; gap: 0.325rem; border-radius: 10px;
  }
  .LDToolBtn:hover { background-color: #eee; }
  .LDToolBtn.add { color:black }
  .LDToolBtn.other.sel { color:#2995cd }
  .LDToolBtn.disable, .LDToolBtn.other.disable { color: lightgrey }
  .LDToolBtn.disable:hover { background-color: white; }
  .LDToolBtn.remove { color:red; }
  .LDAlwayTxt, .LDMiniTxt { font-size: 0.725rem; text-align: center; }
  .LDMiniTxt { display: none; }
  .LDMiniTxt.remove { color: black; }
  /**********************/
  
  .checkedStyle {
    display:flex;flex-direction: column;justify-content: center;align-items: center;
    background-color: blue; border:2px solid blue; box-shadow: inset 0 0 0 2px white;
    padding: 2px; width:20px; height:20px;
  }
    
  .uncheckedStyle {
    display:flex;flex-direction: column;justify-content: center;align-items: center;
    background-color: white; border:2px solid #b4b4b4;
    padding: 2px; width:20px; height:20px;
  }

  .checkedStyle.radio, .uncheckedStyle.radio {
    border-radius: 50%;
  }


  .checkedStyle2A {
    display:flex;flex-direction: column;justify-content: center;align-items: center;
    background-color: white; border:2px solid blue;
    padding:2px; width:20px; height:20px;
  }
  .uncheckedStyle2A {
    display:flex;flex-direction: column;justify-content: center;align-items: center;
    background-color: white; border:2px solid #b4b4b4;
    padding: 2px; width:20px; height:20px;
  }

  .checkedStyle2B {
    display:flex;flex-direction: column;justify-content: center;align-items: center;
    background-color: blue; border:2px solid white; 
    padding:2px; width:12px; height:12px;
  }    
  .uncheckedStyle2B {
    display:flex;flex-direction: column;justify-content: center;align-items: center;
    /*background-color: #b4b4b4; border:2px solid #b4b4b4;*/
    background-color: transparent; border:2px solid transparent;
    padding: 2px; width:12px; height:12px;
  }  

  .checkedStyle2A.radio, .uncheckedStyle2A.radio, .checkedStyle2B.radio, .uncheckedStyle2B.radio {
    border-radius: 50%;
  }  


/*******************************************/
/*
.checkWidthCont {position:absolute;top:0;left:0;max-height:0px;height:0px;
    display:inline-flex;flex-direction:column;justify-content: flex-start;align-items:flex-start;
    overflow: hidden; width:auto;padding:0;margin:0;white-space: nowrap;}
*/
.checkWidthCont {position:absolute;top:0;left:0;max-height:0px;height:0px; background-color: green;
    display:inline-flex;flex-direction:column;justify-content: flex-start;align-items:flex-start;
    overflow: hidden; width:auto;padding:0;margin:0;white-space: nowrap;}

div.ck-content.CheckEditorReadOnly > p { margin:5px; padding:0; }    
.ck-content .CheckEditorReadOnly span p { display: inline-flex; }
.CheckEditorReadOnly {
    max-height:0px;height:0px; width: auto; padding:0px; background-color: white;
    white-space: nowrap; overflow: hidden;
    /*border: 1px solid #D1D1D1;*/
}

/**********************************************/
.qtnOPDoContainer { display: flex;flex-direction: column;
    justify-self: flex-start;align-items: center; width:100%;
}

.qtnOPDoContainer2 { display: flex;flex-direction: column;
    justify-self: flex-start;align-items: center; width:100%;
}

.qtnOPDoUploadBorder {
    display:flex;flex-direction: column; justify-content: center; align-items: center;
    border-radius: 8px; border: 2px dashed #31279d; color: #31279d; width: 100%;
    padding: 40px 0 30px 0; background-color: white;
}
/*
.qtnOPDoRListContainer {
    display:flex;flex-direction:row;justify-content: flex-start;align-items: center;
    flex-wrap: wrap;
}
*/
.qtnOPDoRListContainer {
    display: grid; grid-column-gap: 1%; grid-template-columns: 100%; padding: 0; width: 100%;
}

.qtnOPDoRListItem { position: relative;
    display:flex;flex-direction:row;justify-content:flex-start;align-items: center;
    width:100%; border-radius: 8px; background-color: white; word-wrap: break-word;
    margin-top: 10px; padding:5px; box-shadow: 0px 0px 3px #b4b4b4; min-height:80px;
}

.qtnOPDoRListItemTrash {position:absolute;bottom:10px;right:15px;width:30px;height:30px}

.qtnOPDoURL { 
    display:flex;flex-direction: row;justify-content: flex-start;align-items: center;
    border: 1px solid lightgray; padding:10px; border-radius: 4px; background-color: white;
    width:100%; flex-wrap: nowrap; box-shadow: 0px 0px 3px #b4b4b4;
}

.qtnOPDoURL:hover {color:#31279d; background-color: #e7e8ec;}

.DrawCanvasBaseCont {
    width:100%; height:100%; background-color: white;
}

.DrawCanvasBaseContBG {
    position: absolute; top:0; left:0; width:100%; height:100%;
    background-color: transparent;
}

.qtnOPDoDrawCont {
    display: flex; flex-direction: column; justify-content: center; align-items: center; width:100%; 
    gap: 0px;
}

.qtnOPDoDrawArea_old {
    display:flex;flex-direction: row;justify-content: center; align-items: center;
    width:100%; overflow: auto;
    max-width: 1024px;
    background-color:white; box-shadow: 0px 0px 3px #b4b4b4; border-radius: 6px;
}

.qtnOPDoDrawArea {
    display:flex;flex-direction: row; /*justify-content: center; align-items: center;*/
    width:100%; overflow: auto; flex-shrink: 1;
    background-color:white; box-shadow: 0px 0px 3px #b4b4b4; border-radius: 6px;
    overflow-y: hidden;
}


.DrawCanvasContainer {
    position: relative;
    display:flex;flex-direction:column;justify-content:center;align-items:center;
    height:100%; width: 100%;
}

.DrawCanvasBtnBarHori {
    display:flex;flex-direction:row;justify-content:center;align-items:center;
    gap:5px; background-color:white; border-radius:6px; padding:5px; margin-top:5px;
    box-shadow: 0px 0px 3px #b4b4b4;
}
.DrawCanvasBtnBarVert {
    display:none;flex-direction:column;justify-content:center;align-items:center;
    gap:10px; background-color:white; border-radius:6px; margin-left:5px;
    box-shadow: 0px 0px 3px #b4b4b4; padding:20px 10px;
}
.DrawCanvasBtnDL {
    display:flex;flex-direction:column;justify-content:center;align-items:center;
    gap:10px; background-color:white; border-radius:6px; padding:5px;
    box-shadow: 0px 0px 3px #b4b4b4; align-self: flex-start; margin-top: 5px;
}
.DrawCanvasPopBtn { position:relative;
    display:flex;min-width:20px;min-height:20px;border-radius: 4px;
}
.DrawCanvasPopContent { position:absolute;
    display:flex;flex-direction:column;justify-content:center;align-items:center;
    border-radius: 6px; box-shadow: 0px 0px 3px #b4b4b4; background-color: white;
}
/*************************************/

/*
svg { transition: all 0.3s;}
div { transition: background-color 0.3s, color 0.3s}
*/

/*************************************/

.batchImportSavingTop {position: relative;
    display:flex;flex-direction:column;justify-content: center;align-items: center;
    width:100%;height:100%; background-color: transparent;
}

.batchImportContent {display:flex;flex-direction:column;justify-content: center;align-items: center;
    width:600px;height:400px; max-width: 90%; max-height: 60%;
    background-color: white; border-radius: 6px;
}

.ATExEditToolBarBtn:hover {background-color: #3a6bb9 !important;}
.ATExEditToolBar {position: fixed !important; bottom: 5px !important; align-self: center !important;
    transform: none !important; left:auto !important;
}

/* action button disable*/
.disableBtn {
    cursor: none !important; pointer-events: none !important; 
}

.disableBG1 {
    color: grey !important;
}

.disableBG2 {
    background-color: lightgray !important; border-color: grey !important;
}

.openended-response-checkbox.unchecked label { background-color: transparent !important;}

/*Page admin*/
div.adminLogin { display: flex; flex-direction: row; justify-content: space-between;
    align-items: center; width: 100%; flex-wrap: wrap;
}

div.adminColClass { display: flex; flex-direction: column; justify-content: flex-start;
    align-items: flex-start; width:auto;
}
  
div.adminRowClass { display: flex; flex-direction: row; justify-content: flex-start; 
    align-items: center; width: 100%;
}

div.adminContainer { display: flex; flex-direction: column; justify-content: flex-start;
    align-items: center; width: 100%; padding: 10px;
}

div.adminBody { display: flex; flex-direction: column; justify-content: flex-start;
    align-items: center; max-width: 1200px; width: 100%; padding: 0px 15px 0px 15px;
}

div.adminBlock { display: flex; flex-direction: column; justify-content: flex-start;
    align-items: flex-start; border: 1px solid #BBBBBB; padding: 15px 30px 30px 30px;
    width: 100%;
}

div.adminAlert { display: flex; flex-direction: row; justify-content: flex-start;
    align-items: center; width: 100%; border: 1px solid #BBBBBB; background-color: rgb(247,228,225);
    padding: 15px; margin-top: 2px;
}

/****** for offcanvas show hints/answer key, need higher z-index **********/
.modalZ1700 { z-index: 1061 !important; }

/****** remove down arrow if dropdown disable ****/
.simple-dropdown.unclickable .dropdown-text::after {
    content: none;
}

/**** inline mathjax equation inside ckeditor ****/
.ck-content mjx-container { display: inline-flex !important; }

/**** adjust scroll-to-top position, for page library, my exer list ****/
.pageLibrary .scroll-to-top { margin: 0.8rem 1.6rem 3.6rem 1.6rem !important; }

/**** adjust scroll-to-top position, for page exer open,edit ****/
.pageExerEdit .scroll-to-top { margin: 0.8rem 1.6rem 4.5rem 1.6rem !important; }

/****/
.ATErrorMsgBlock {
    width: 100%; border-radius: 4px; margin: 0.5rem 0 0.5rem 0; padding: 1rem 0.5rem;
    border: 1px solid #B72123; background-color: #F5D6D6;
    color: #B72123; font-size: 14px;
}

/*.btn768Show { display: none; overflow: hidden;}*/
.btn768Show { display: none; }
.btn768Hide { display: show; flex-shrink: 1;}

/*****/
.toolTipsFollowup { border: none !important; left: -100px !important }

/*** override bootstrap navbar offcanvas ***/
.navbar-expand-lg .moveOverHeader .offcanvas {
    position: fixed !important; border-bottom: 1px solid #EC8008 !important; 
    background-color: white !important; height: fit-content !important;
    transition: transform 0.3s ease-in-out !important;
}
.navbar-expand-lg .moveOverHeader .offcanvas .offcanvas-body {
    display: block !important; padding: 1rem !important;
}

/****/
.workFollowName, .workFollowName2 { border-radius: 24px; background-color: #FFF0EC;
    padding: 2px 12px 2px 12px; font-size: 24px !important; font-weight:400 !important; color: black;
}

.workFollowName2 { font-size: 12px !important; }
.workFollowName>span>div>p { margin-bottom: 0 !important; }
.workFollowName2>span>div>p { margin-bottom: 0 !important; }

.CpEPTagMetaList .badge-btn { color: black; }

.progress-chart-popup {
    background: white; border: 1px solid #8c939e; border-radius: 4px;
    box-shadow: 0 2px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);
    padding: 1rem; font-size: 13.2px; width: 230px;
}
.progress-chart-popup .title-container { display: flex; }
.progress-chart-popup .title-container .title { 
    font-size: 16px; margin-bottom: 1rem; overflow: hidden;
    text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 3;
    line-clamp: 3; -webkit-box-orient: vertical;
}
.progress-chart-popup h3, .progress-chart-popup h4 { font-size: 13.2px; margin-bottom: 0.5rem; }
.progress-chart-popup p { margin-bottom: 0.5rem; }
.progress-chart-popup .class-average-label { margin-bottom: 0; }
.progress-chart-popup ul { list-style-type: none; padding-left: 0; margin: 0.5rem 0; }
.progress-chart-popup li { display: flex; align-items: center; margin-bottom: 0.25rem; }
.progress-chart-popup .color-box { width: 7px; height: 13px; margin-right: 5px; }
.progress-chart-popup .score { margin-left: 10px; }
.progress-chart-popup .class-average { margin-left: 0.5rem; }

/******************************************************/
.report .minibar-container .indicator-value.shiftBubble {
    position: absolute;
    font-size: 1rem;
    z-index: 2;
    text-align: center;
    color: white;
    bottom: -100%;
    margin-left: -42px;
}

.report .minibar-container .indicator-value.shiftBubble::before {
    border-width: 7px 10px 0 10px;
    top: -5px;
    left: 42px;
    transform: translateX(-50%) rotate(180deg);
}

.report .minibar-container .indicator-value.shiftBubble::after {
    border-width: 6px 9px 0 9px;
    top: -2px;
    left: 41px;
    transform: translateX(-50%) rotate(180deg);
}
/***/
.report .minibar-container .indicator-value.shiftBubble2 {
    position: absolute;
    font-size: 1rem;
    z-index: 2;
    text-align: center;
    color: white;
    bottom: -100%;
    margin-left: -32px;
}

.report .minibar-container .indicator-value.shiftBubble2::before {
    border-width: 7px 10px 0 10px;
    top: -5px;
    left: 32px;
    transform: translateX(-50%) rotate(180deg);
}

.report .minibar-container .indicator-value.shiftBubble2::after {
    border-width: 6px 9px 0 9px;
    top: -2px;
    left: 31px;
    transform: translateX(-50%) rotate(180deg);
}
/********************************************************/