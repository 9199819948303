.sidenav {
  width: 64px;
  @include noselect;
  @include media-breakpoint-down(md) {
    width: 0;
  }

  .sidenav-container {
    overflow: hidden;
    background-color: #{$sidenav-primary};
    position: fixed;
    z-index: $z-index-sidenav-container;

    &.left-sidenav {
      transition: width .2s cubic-bezier(0.175, 0.685, 0.32, 1);
      -moz-transition: width .2s cubic-bezier(0.175, 0.685, 0.32, 1); /* Firefox 4 */
      -webkit-transition: width .2s cubic-bezier(0.175, 0.685, 0.32, 1); /* Safari and Chrome */
      -o-transition: width .2s cubic-bezier(0.175, 0.685, 0.32, 1); /* Opera */
    }

    width: 4rem;
    height: 100%;

    @include media-breakpoint-down(md) {
      width: 100% !important;
      height: 0% !important;
      &.top-sidenav {
        transition: height .2s cubic-bezier(0.175, 0.685, 0.32, 1);
        -moz-transition: height .2s cubic-bezier(0.175, 0.685, 0.32, 1); /* Firefox 4 */
        -webkit-transition: height .2s cubic-bezier(0.175, 0.685, 0.32, 1); /* Safari and Chrome */
        -o-transition: height .2s cubic-bezier(0.175, 0.685, 0.32, 1); /* Opera */
      }
    }

    .sidenav-icon {
      height: 2rem;
      display: inline-flex;
      transition: all .2s cubic-bezier(0.175, 0.685, 0.32, 1);
      -moz-transition: all .2s cubic-bezier(0.175, 0.685, 0.32, 1); /* Firefox 4 */
      -webkit-transition: all .2s cubic-bezier(0.175, 0.685, 0.32, 1); /* Safari and Chrome */
      -o-transition: all .2s cubic-bezier(0.175, 0.685, 0.32, 1); /* Opera */

      &:not(.no-hover) {
        &:hover, &.active {
          background-color: white;

          svg, .title, .side-nav-language { //, .side-nav-avatar
            color: #{$sidenav-primary};
          }


        }
      }
    }

    &:hover {
      z-index: $z-index-sidenav-container-hover;
      @include media-breakpoint-up(md) {
        width: 11.5rem;
        max-width: 100%;
        .title, .logo-title {
          display: block !important;
        }

        .version {
          display: flex !important;
        }
      }
    }

    &.active {
      z-index: $z-index-sidenav-container-active;
      @include media-breakpoint-down(md) {
        height: 100vh !important;
        @include max_safari_100vh;
        .title, .logo-title {
          display: block !important;
        }

        .version {
          display: flex !important;
        }
      }
    }

    .logo {
      width: 2rem;
      height: 2rem;
      margin-bottom: 0.2rem;
    }

    .logo-title {
      display: none;
      width: auto;
      height: 2rem;
      margin-bottom: 0.2rem;

      img {
        height: 2rem;
      }
    }

    .version {
      display: none;
      //justify-content: end;
      //flex-direction: column;
      //line-height: normal;
      //height: 2.1rem;
    }

    .split-line {
      border-top: 1px #{$dim} solid;
    }

    .sidenav-list-small-screen-container {
      //@media screen and (max-height: 600px) {
      overflow-y: auto;
      overflow-x: hidden;

      @include scroll-theme(#{$sidemenu-scrollbar-bg}, #{$sidemenu-scrollbar}, #{$sidemenu-scrollbar-hover}, 0.325rem);
      //}
    }

    .sidenav-list-container {
      overflow: hidden;
      @media screen and (max-height: 600px) {
        overflow: unset;
      }
    }

    .sidenav-list {
      @include scroll-theme(#{$sidemenu-scrollbar-bg}, #{$sidemenu-scrollbar}, #{$sidemenu-scrollbar-hover}, 0.325rem);

      overflow-y: auto;
      overflow-x: hidden;
      margin-right: 2px;
      //padding-right: 2rem;
      //margin-right: -2rem;
      //padding-bottom: 1.5rem;
    }

    .sidenav-bottom-list {
      background-color: #{$sidenav-secondary};
      @include media-breakpoint-down(md) {
        grid-template-columns: 1fr 1fr;
      }
    }

    .gradient {
      width: 100%;
      height: 1.5rem;
      position: absolute;
      left: 0;
      background: rgb(255, 255, 255);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#{$sidenav-primary}", GradientType=1);

      &.top-0 {
        background: -moz-linear-gradient(0deg, rgba(255, 255, 255, 0) 20%, #{$sidenav-primary} 100%);
        background: -webkit-linear-gradient(0deg, rgba(255, 255, 255, 0) 20%, #{$sidenav-primary} 100%);
        background: linear-gradient(0deg, rgba(255, 255, 255, 0) 20%, #{$sidenav-primary} 100%);
      }

      &.bottom-0 {
        background: -moz-linear-gradient(180deg, rgba(255, 255, 255, 0) 20%, #{$sidenav-primary} 100%);
        background: -webkit-linear-gradient(180deg, rgba(255, 255, 255, 0) 20%, #{$sidenav-primary} 100%);
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 20%, #{$sidenav-primary} 100%);
      }
    }

    .sidenav-icon {
      min-width: 2rem;
      min-height: 2rem;
      padding: 0.5rem;
      border-radius: 0.325rem;
      @include media-breakpoint-down(md) {
        padding: 1.5rem 0.5rem;
      }

      .title {
        display: none;
        line-height: normal;
        color: white;
      }

      svg {
        width: 1rem;
        height: 1rem;
        color: white;
      }
    }

    //.sidenav-bottom-icon {
    //  min-width: 2rem;
    //  height: 2rem;
    //  .title{
    //    display: none;
    //  }
    //}

    .side-nav-language {
      width: 1rem;
      line-height: normal;
      color: white;

      &.chi {
        padding: 0 1px;
      }
    }

    //.side-nav-avatar {
    //  text-align: center;
    //  border-radius: 5rem;
    //  width: 1.5rem;
    //  height: 1.5rem;
    //  background-color: white;
    //}
  }
}