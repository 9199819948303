@mixin cus-input-checkbox($size) {
  position: relative;
  width: #{$size};
  height: #{$size};
  &:not(.checkicon) {
    label {
      &::after {
        visibility: hidden;
      }
    }
  }

  &.checkbox-round, &.checkbox-round-border, &.checkbox-round-border-dashed {
    &::after {
      border-radius: #{$size};
    }

    label {
      border-radius: #{$size};
    }
  }

  &.checkbox-round-border, &.checkbox-square-border, &.checkbox-round-border-dashed, &.checkbox-square-border-dashed {
    &::after {
      content: "";
      position: absolute;
      left: -4px;
      top: -4px;
      width: calc(#{$size} + 8px);
      height: calc(#{$size} + 8px);

      border-width: 2px;
      border-style: solid;
      border-color: #{$checkbox-dim};
    }

    &.checked {
      &:after {
        border-color: #{$checkbox-default};
      }
    }
  }

  &.checkbox-round-border-dashed, &.checkbox-square-border-dashed {
    &::after {
      border-style: dashed;
    }
  }

  label {
    background-color: #{$checkbox-dim};
    cursor: pointer;
    width: #{$size};
    height: #{$size};
    left: 0;
    position: absolute;
    top: 0;

    &:after {
      border: 2px solid #{$checkbox-white};
      border-top: none;
      border-right: none;
      content: "";
      height: #{$size /4};
      left: #{$size /4};
      position: absolute;
      top: #{$size /3};
      transform: rotate(-45deg);
      width: #{$size/2};
    }
  }

  input {
    &[type="checkbox"], &[type="radio"] {
      visibility: hidden;
    }

    &[type="checkbox"]:checked + label, &[type="radio"]:checked + label {
      background-color: #{$checkbox-default};
    }

    &[type="checkbox"]:checked + label:after, &[type="radio"]:checked + label:after {
      opacity: 1;
      border-color: #{$checkbox-white};
    }
  }
}

@mixin cus-input-checkbox-colortheme($color, $bgColor, $borderColor , $checkedColor, $checkedBgColor, $checkedBorderColor) {
  label {
    background-color: #{$bgColor};

    &:after {
      border-color: #{$color};
    }
  }

  &.checkbox-round-border, &.checkbox-square-border, &.checkbox-round-border-dashed, &.checkbox-square-border-dashed {
    &:after {
      border-color: #{$borderColor};
    }

    &.checked {
      &:after {
        border-color: #{$checkedBorderColor};
      }
    }
  }

  input {
    &[type="checkbox"]:checked + label, &[type="radio"]:checked + label {
      background-color: #{$checkedBgColor};

      &:after {
        border-color: #{$checkedColor};
      }
    }
  }
}

.#{$checkbox-prefix} {
  &xxxs {
    @include cus-input-checkbox($h8-font-size);
  }

  &xxs {
    @include cus-input-checkbox($h6-font-size);
  }

  &xs {
    @include cus-input-checkbox($h5-font-size);
  }

  &sm {
    @include cus-input-checkbox($h4-font-size);
  }

  &md {
    @include cus-input-checkbox($h3-font-size);
  }

  &lg {
    @include cus-input-checkbox($h2-font-size);
  }

}

.checkbox-theme-collection {
  //$color, $bgColor, $borderColor , $checkedColor, $checkedBgColor, $checkedBorderColor
  @include cus-input-checkbox-colortheme(transparent, transparent, $body-color, $sidenav-primary, $checkbox-white, $body-color)
}


.metadata-filter-container {
  .metadata-filter-toggle {
    height: 20px;
    width: 20px;

    svg {
      height: 20px;
      width: 20px;
    }
  }
}

//simple-checkbox
.simple-checkbox {
  height: 20px;
  width: 20px;


  .control {
    height: 20px;
    width: 20px;
    display: block;
    position: relative;
    cursor: pointer;

    input {
      position: absolute;
      z-index: -1;
      opacity: 0;

      &:checked {
        & ~ .control__indicator {
          background: #{$exercise-third-btn}; //#{$sidenav-primary};

          &:after {
            display: block;
          }
        }
      }

    }
  }

  .control__indicator {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    //background: #e6e6e6;
    border: 2px solid #{$dim-350}; //#{$body-color};

    &:after {
      content: '';
      position: absolute;
      display: none;
    }
  }

  .control:hover input ~ .control__indicator,
  .control input:focus ~ .control__indicator {
    //background: #ccc;
  }

  .control:hover input:not([disabled]):checked ~ .control__indicator,
  .control input:checked:focus ~ .control__indicator {
    background: #{$exercise-third-btn}; //#{$sidenav-primary};
  }

  .control input:checked ~ .control__indicator {
    border: 2px solid #{$exercise-third-btn} !important;
  }

  .control--checkbox {
    .control__indicator {
      &:after {
        left: 5px;
        top: 0px;
        width: 6px;
        height: 12px;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
      }

      &.partial {
        &:after {
          border-width: 0 2px 0px 0;
          transform: rotate(90deg);
        }
      }
    }

    input {
      &:disabled {
        & ~ .control__indicator {
          &:after {
            border-color: #7b7b7b;
          }
        }
      }
    }
  }
}

.student-checkbox-btn {
  color: #{$dim-350};

  &.all {
    border: 1px #{$dim-350} solid !important;
    color: #{$dim-350};

    .simple-checkbox {
      .control input:checked ~ .control__indicator {
        border: 2px solid #{$dim-350} !important;
        background-color: #{$dim-350} !important;
      }
    }
  }

  &.active {
    border: 1px #{$exercise-third-btn} solid !important;
    color: #{$exercise-third-btn};
  }
}

.checkbox-taggings-primary {
  .simple-checkbox .control input:checked ~ .control__indicator {
    border: 2px solid #{$card-action-primary} !important;
  }

  .simple-checkbox .control input:checked ~ .control__indicator {
    background: #{$card-action-primary};
  }

  .simple-checkbox .control:hover input:not([disabled]):checked ~ .control__indicator, .simple-checkbox .control input:checked:focus ~ .control__indicator {
    background: #{$card-action-primary};
  }
}