$custom-video-prefix: '[class*="simple-video-"]';
$custom-audio-prefix: '[class*="simple-audio-"]';

.themed-player, .ck-content {
  .ratio-container {
    position: relative;
    display: block;
    max-width: 100%;
  }
  .ratio-frame {
    padding-top: 56.25%;

    &.themed {
      padding-top: initial;

      .ratio-object {
        position: relative;
      }

      iframe {
        width: 100%;
      }
    }

    &:not(.themed) {
      iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }

    video, audio {
      width: 100%;
    }
  }

  .ratio-object {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  &#{$custom-video-prefix} {
    -webkit-border-bottom-right-radius: 10px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-radius-bottomright: 10px;
    -moz-border-radius-bottomleft: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 15px;
    background: #d6e2e2;
    position: relative;
    margin-top: 30px;

    &::before {
      -webkit-border-top-left-radius: 10px;
      -webkit-border-top-right-radius: 10px;
      -moz-border-radius-topleft: 10px;
      -moz-border-radius-topright: 10px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      content: "Test";
      width: 100%;
      position: absolute;
      top: -30px;
      padding: 5px 15px;
      background: #c1d2d2;
      color: #596b6b;
    }

    iframe, video, audio {
      border-radius: 10px;
    }
  }

  &#{$custom-audio-prefix} {
    .ratio-container { height: 0; overflow: visible; }

    .ratio-object { position: relative; }

    .ratio-frame { padding: 0; }
  }
}

.vjs-progress-control { visibility: visible; }

.youtube-pattern-list {
  @media (max-width: 575.98px) {
    max-height: 100px;
    overflow: scroll;
  }
}
