.mcq-answer-container {
  .select-indicator {
    padding-top: 0.825rem;
  }

  &.label-mode {
    .answer-border {
      &:after {
        border-color: #{$at-indicator-color};
      }

      &.correct {
        &:after {
          border-color: #{$mcq-correct};
        }

        .indicator {
          color: #{$white};
          background-color: #{$mcq-correct};
        }
      }
    }

    .indicator {
      border-radius: 0.8rem 0% 0% 0.8rem;
      background-color: #{$white};
      color: #{$at-indicator-color};
      height: 100%;

      &.correct {
        background-color: #{$mcq-correct};
      }
    }
  }

  .drag-handler {
    background-color: #{$at-drag-color};
    color: white;
    border-radius: 0.8rem 0.8rem 0% 0%;
    cursor: grab;
  }

  .indicator {
    border-radius: 0.8rem 0% 0% 0.8rem;
    background-color: #{$at-indicator-color};
    color: white;
    height: 100%;

    &.correct {
      background-color: #{$mcq-correct};
    }
  }

  //.editor-type-selection {
  //  button {
  //    width: 3.5rem;
  //    height: 3rem;
  //  }
  //}

  //.trash {
  //  button {
  //    width: 3rem;
  //    height: 3rem;
  //  }
  //}
}