@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

//https://material.io/design/color/text-legibility.html#text-backgrounds

//Text
$opacity-high-emphasis: 0.87;
//Hints
$opacity-medium-emphasis: 0.60;
//Disable
$opacity-low-emphasis: 0.38;


// ------- Override color  ------- //
$body-color: #000000;

// ------- Custom color  ------- //

//$correct-600: #1f6744;
//$correct-400: #78bb94;
//$stateless-600: #0044B9;
//$stateless-300: #C9E7FF;
//$incorrect-600: #7E1A0B;
//$incorrect-400: #ED8C7C;
$dim-800: #545454;
$dim-500: #dadada;
$hover-bg-400: #E9EDF0;
$dim-400: #8c939e;
$dim-350: #c8cace;
$dim-300: #cdd3e2;
$dim-250: #e0e2e5;
$dim-200: #E9EDF0;
$dim-150: #ebebeb;
$dim-100: #f4f5f6;
//General
$dim: #e2e2e2;
$shadow: rgba(0, 0, 0, 0.25);
$white: #FFFFFF;
//Checkbox
$checkbox-default: #2995cd;
$checkbox-bgColor: white;
$checkbox-borderColor: white;
$checkbox-dim: #d0d0d0;
$checkbox-correct: #4EAE84;
$checkbox-white: white;
//Badge
$badge-bg: #D7EDFB;
$badge: #0c9bef;
$badge-primary-bg: #dbe3f6;
$badge-primary: #1D4CB0;
$badge-hover-primary-bg: #c2d5ff;
$badge-hover-primary: #1D4CB0;
//AT
$at-bg-color: #FFFFFF;
$at-secondary-bg-color: #D7EDFB;
$at-color: #152536;
$at-primary: #2995cd; //#0c4154;
$at-primary-dim: #749AB3;
$at-secondary: #63bafa;
$at-secondary-dark: #0C8CE9;
$at-secondary-light: #baddff;
$at-secondary-dim-dark: #718390;
$at-secondary-dim: #e2e2e2;
$at-third: #F1F7FF;
$at-bg-nav: #c9e7ff;
$at-side-nav: #131A3C;
$at-drag-color: #86B4C5;
$at-indicator-color: #A9D1E0;
$at-border: #dee2e6;
$at-border-light: #cccccc;
//Preview
$preview-bg-color: #eff1fd;
$preview-primary: #31279D;
$preview-secondary: #EC8008;
$preview-secondary-dark: #dc7b00;
$preview-secondary-light: #ffd9b7;
$preview-bg-nav: #E7E8EC;
$preview-border: #eaeaea;
//Question type - MCQ
$mcq-default: #c1c0e1;
$mcq-active: #5d52ce;
$mcq-correct: #4EAE84;
$mcq-correct-missing: #C2DBC6;
$mcq-incorrect: #E94929;
$mcq-attempted: #3a6bb9;
$mcq-dim: #B4B4B4;
//Question type - FIB
$fib-active-border: #cbc7ef;
$fib-active: #5d52ce;
$fib-default-bg: rgba(232, 230, 250, 1);
$fib-correct: #008b53;
$fib-correct-bg: rgba(78, 174, 132, 0.15);
$fib-incorrect: #c72a0d;
$fib-incorrect-bg: rgba(233, 73, 41, 0.1);
$fib-attempted: #1655bb;
$fib-attempted-bg: rgba(58, 107, 185, 0.1);
$fib-checked-color: #FFFFFF;
$fib-underline: #000000;

//Question type - Openended
$openended-correct: #4EAE84;
$openended-incorrect: #E94929;
$openended-dim: #B4B4B4;
$openended-toolbar-bg: #ffffff;
//$openended-toolbar-active-color: #ffffff;
//$openended-toolbar-active-bg: #5d52ce;
$openended-toolbar-trash: #E94929;
$openended-toolbar-active: #5d52ce;
$openended-toolbar-selected: #29247a;

//Label the diagram
$label-the-diagram-active: #5d52ce;
$label-the-diagram-default-bg: rgba(232, 230, 250, 1);
$label-the-diagram-drag-bg: #a0a0a0;
$label-the-diagram-drag-color: #ffffff;

//Accordion
$accordion-header: #ffffff;

//CK comment
$ck-comment-active: #B0D5EFFF;
$ck-comment-inactive-border: #FFC130;
$ck-comment-active-border: #0082d0;

//Login
$login-page-primary-bg: #fff;
$login-page-secondary-bg: #1D4CB0;
$login-btn-color: #F27F0C;
$login-learn-more-btn-color: #1D4CB0;

//Exercise
$exercise-secondary-btn: #C0D6F7;
$exercise-third-btn: #FD5821;
$exercise-third-btn-hover: #F27F0C;
$exercise-third-btn-hover-light: #FFF1EC;
$exercise-third-btn-hover-lighter: rgba(255, 241, 236, 0.69);
$exercise-edit-toolbar: #FD5821;
$exercise-edit-toolbar-item-hover: #bf390c;
$exercise-third-btn-shadow: rgba(253, 88, 33, 0.25);

$card-action-primary: #575d88;
$taggings-primary: #575d88;

// ------- Non-button color  ------- //
//Scroll table
$mcq-table-bg-default: #F0F1FB;
$mcq-table-header-bg-default: #DEE0F6;
$scrolltable-bg-indicator: rgba(0, 0, 0, 0.25);
$scrolltable-color-indicator: #FFFFFF;
$scrollbar-preview-bg: #FFFFFF;
$scrollbar-preview: #c1c0e1;
$scrollbar-preview-hover: #5d52ce;
$scrollbar-at-bg: #FFFFFF;
$scrollbar-at: #63bafa;
$scrollbar-at-hover: #2995cd;

$form-switch-color-dim: #000000;

$agreement-bg: #F9F9F9;

$cookies-agreement-bg: #787878;
$cookies-agreement-color: #FFFFFF;

$sidenav-primary: #1D4CB0;
$sidenav-primary-bg: #e8efff;
$sidenav-secondary: #252966;
$sidemenu-scrollbar-bg: #252966;
$sidemenu-scrollbar: #6a9bff;
$sidemenu-scrollbar-hover: #2c5ec7;

//Card
$card-container-primary: #575d88;
$card-container-secondary: #d9dcf1;
$card-container-third: #a8afd7;
$card-container-forth: #a5aacb;

//Tag
$tag-none: #828282;
$tag-danger: #DE4E4E;
$tag-success: #03AC00;
$tag-warning: #FF9900;
$tag-info: #82006D;
$tag-text: #000000;
$tag-primary: #2BA4D6;

//MessageBox
$message-box-warning: #ffefda;
$message-box-warning-dark: #c76114;
$message-box-warning-font-only: #c76114;
$message-box-error: #f5d6d6;
$message-box-error-dark: #b72222;
$message-box-error-font-only: #ff0000;
$message-box-success: #C1ECC2;
$message-box-success-dark: #03AC00;
$message-box-success-font-only: #03AC00;

//Report
$report-correct: #1D7C27;
$report-incorrect: #C30000;
$report-attempted: #0055A3;
$report-correctness: #4EAE84;
$report-alert: #FD5821;
$report-average: #25A3FF;
$report-correct-light: #C1ECC2;
$report-incorrect-light: #F8B5B5;
$report-incorrect-bg: #E94929;
$report-attempted-light: #C1E1EC;
$report-alert-light: #FFFFFF;

$report-average-line: #006fbb;
$report-passing-line: #ffa7a7;

$report-correct-bar: #4EAE84;
$report-incorrect-bar: #ee6161;
$report-attempted-bar: #4b97e5;
$report-not-attempted-bar: #909090;
$report-average-bar: #4b97e5;
$report-correctness-bar: #4EAE84;
$report-uncategories-bar: #c2bdf4;
$report-uncategories-line: #7c7c7c;

$report-correct-text: #03AC00;
$report-incorrect-text: #EA572C;
$report-attempted-text: #2BA4D6;

$report-question-total: #767676;

//Drawing Layer
$drawinglayer-border: #4EAE84;

//Chart color
//$chart-color-1: #E9D4A3;
//$chart-color-2: #C2E1B2;
//$chart-color-3: #BED1D4;
//$chart-color-4: #446B71;
//$chart-color-5: #D57E36;
//$chart-color-6: #E2A0CA;
//$chart-color-7: #9C8DAB;
//$chart-color-8: #8AB6B8;
//$chart-color-9: #EBCADC;
//$chart-color-10: #DB6E75;
//$chart-color-11: #997861;
//$chart-color-12: #6E95B8;
//$chart-color-13: #94D5C2;
//$chart-color-14: #E3AAA8;
//$chart-color-15: #5DAE6E;
//$chart-color-16: #C6F0D7;
//$chart-color-17: #CFCADA;
//$chart-color-18: #F5C29D;
//$chart-color-19: #E4B640;
//$chart-color-20: #D5C1B2;

//$tag-not-attempted: #828282;
//$tag-expired: #DE4E4E;
//$tag-expire-today: #DE4E4E;
//$tag-expire-in-days: #DE4E4E;
//$tag-due-today: #DE4E4E;
//$tag-due-this-week: #DE4E4E;
//$tag-in-progress: #FF9900;
//$tag-pending-manual-mark: #FF9900;
//$tag-auto-marked: #03AC00;
//$tag-manual-marked: #2BA4D6;
//$tag-paused: #FF9900;
//$tag-not-yet-started: #828282;
//$tag-result-published: #03AC00;
//$tag-pending-result: #FF9900;
//$tag-not-submitted: #828282;
//$tag-submitted: #000000;

$custom-colors: (
  //Schema Demo
        "demo":#0C8CE9,
        "demo-dark":#0b4b79,
        "demo-card":#adb9e2,
  //"blue-800":#2997CF,
  //"blue-750":#0C8CE9,
  //"blue-650":#45ACFF,
  //"blue-500":#25C0EF,
  //"blue-400":#52DFFB,
  //"blue-200": #E0ECF6,
  //"tiffany-700": #009FC1,
  //"tiffany-600": #03C4D4,
  //"purple-200":#D5D3E0,
  //"gray-700":#A0BCC2,
  //"gray-400": #D2DAE1,
  //"gray-300": #E9EAEE,
  //"gray-200": #E9EDF0,
  //"correct-600":$correct-600,
  //"correct-400":$correct-400,
  //      "stateless-600":$stateless-600,
  //      "stateless-300":$stateless-300,
  //"incorrect-600":$incorrect-600,
  //"incorrect-400":$incorrect-400,
        "hover-bg-400": $hover-bg-400,
  //General
        "dim":$dim,
        "shadow":$shadow,
        "white": $white,
  //Body
        "body-color": $body-color,
  //Checkbox
        "checkbox-default":$checkbox-default,
        "checkbox-bgColor":$checkbox-bgColor,
        "checkbox-borderColor":$checkbox-borderColor,
        "checkbox-dim":$checkbox-dim,
        "checkbox-correct":$checkbox-correct,
        "checkbox-white":$checkbox-white,
  //Badge
        "badge-bg":$badge-bg,
        "badge":$badge,
        "badge-primary-bg":$badge-primary-bg,
        "badge-primary":$badge-primary,
        "badge-hover-primary-bg":$badge-hover-primary-bg,
        "badge-hover-primary":$badge-hover-primary,
  //AT
        "at-bg-color": $at-bg-color,
        "at-secondary-bg-color":$at-secondary-bg-color,
        "at-color": $at-color,
        "at-primary":$at-primary,
        "at-primary-dim":$at-primary-dim,
        "at-secondary":$at-secondary,
        "at-secondary-dark":$at-secondary-dark,
        "at-secondary-light":$at-secondary-light,
        "at-secondary-dim-dark": $at-secondary-dim-dark,
        "at-secondary-dim": $at-secondary-dim,
        "at-third":$at-third,
        "at-bg-nav":$at-bg-nav,
        "at-drag-color":$at-drag-color,
        "at-indicator-color":$at-indicator-color,
        "at-border":$at-border,
        "at-border-light":$at-border-light,
  //Preview
        "preview-bg-color": $preview-bg-color,
        "preview-primary":$preview-primary,
        "preview-secondary":$preview-secondary,
        "preview-secondary-dark":$preview-secondary-dark,
        "preview-secondary-light":$preview-secondary-light,
        "preview-bg-nav":$preview-bg-nav,
  //Question type - MCQ
        "mcq-default":$mcq-default,
        "mcq-active":$mcq-active,
        "mcq-correct":$mcq-correct,
        "mcq-correct-missing":$mcq-correct-missing,
        "mcq-incorrect":$mcq-incorrect,
        "mcq-attempted":$mcq-attempted,
        "mcq-dim":$mcq-dim,
  //Login
        "login-page-primary-bg": $login-page-primary-bg,
        "login-page-secondary-bg": $login-page-secondary-bg,
        "login-btn-color": $login-btn-color,
        "login-learn-more-btn-color": $login-learn-more-btn-color,
  //Side nav
        "sidenav-primary":$sidenav-primary,
        "sidenav-primary-bg":$sidenav-primary-bg,
        "sidenav-secondary":$sidenav-secondary,
        "sidemenu-scrollbar-bg": $sidemenu-scrollbar-bg,
        "sidemenu-scrollbar": $sidemenu-scrollbar,
        "sidemenu-scrollbar-hover": $sidemenu-scrollbar-hover,
  // Exercise
        "exercise-secondary-btn": $exercise-secondary-btn,
        "exercise-third-btn": $exercise-third-btn,
        "exercise-third-btn-hover":$exercise-third-btn-hover,
        "exercise-third-btn-hover-light":$exercise-third-btn-hover-light,
        "exercise-third-btn-hover-lighter":$exercise-third-btn-hover-lighter,
        "exercise-edit-toolbar":$exercise-edit-toolbar,
        "exercise-edit-toolbar-item-hover":$exercise-edit-toolbar-item-hover,
        "exercise-third-btn-shadow":$exercise-third-btn-shadow,
        "card-action-primary":$card-action-primary,
        "taggings-primary":$taggings-primary,
  // Tag
        "tag-none":$tag-none,
        "tag-danger":$tag-danger,
        "tag-success":$tag-success,
        "tag-warning":$tag-warning,
        "tag-info":$tag-info,
        "tag-text":$tag-text,
        "tag-primary":$tag-primary,
  //
        "gray-at-action-button":#718592,
        "dim-800":$dim-800,
        "dim-500":$dim-500,
        "dim-400": $dim-400,
        "dim-350": $dim-350,
        "dim-300": $dim-300,
        "dim-250": $dim-250,
        "dim-200": $dim-200,
        "dim-150": $dim-150,
        "dim-100": $dim-100,
);

$non-button-colors: (
  //Question type - MCQ - Table
        "mcq-table-bg-default":$mcq-table-bg-default,
        "mcq-table-header-bg-default":$mcq-table-header-bg-default,
        "scrolltable-bg-indicator":$scrolltable-bg-indicator,
        "scrolltable-color-indicator":$scrolltable-color-indicator,
        "scrollbar-preview-bg":$scrollbar-preview-bg,
        "scrollbar-preview":$scrollbar-preview,
        "scrollbar-preview-hover":$scrollbar-preview-hover,
        "scrollbar-at-bg":$scrollbar-at-bg,
        "scrollbar-at":$scrollbar-at,
        "scrollbar-at-hover":$scrollbar-at-hover,
  //Question type - FIB
        "fib-active-border":$fib-active-border,
        "fib-active":$fib-active,
        "fib-default-bg":$fib-default-bg,
        "fib-correct":$fib-correct,
        "fib-correct-bg":$fib-correct-bg,
        "fib-incorrect":$fib-incorrect,
        "fib-incorrect-bg":$fib-incorrect-bg,
        "fib-attempted":$fib-attempted,
        "fib-attempted-bg":$fib-attempted-bg,
        "fib-checked-color":$fib-checked-color,
        "fib-underline":$fib-underline,
  //Question type - Openended
        "openended-correct": $openended-correct,
        "openended-incorrect": $openended-incorrect,
        "openended-dim": $openended-dim,
        "openended-toolbar-bg":$openended-toolbar-bg,
  //"openended-toolbar-active-color": $openended-toolbar-active-color,
  //"openended-toolbar-active-bg": $openended-toolbar-active-bg
        "openended-toolbar-active":$openended-toolbar-active,
        "openended-toolbar-selected":$openended-toolbar-selected,
        "openended-toolbar-trash":$openended-toolbar-trash,
  //Question type - Label the diagram
        "label-the-diagram-active":$label-the-diagram-active,
        "label-the-diagram-default-bg":$label-the-diagram-default-bg,
        "label-the-diagram-drag-bg":$label-the-diagram-drag-bg,
        "label-the-diagram-drag-color": $label-the-diagram-drag-color,
  //Accordion
        "accordion-header":$accordion-header,
  //CK comment
        "ck-comment-inactive-border":$ck-comment-inactive-border,
        "ck-comment-active-border":$ck-comment-active-border,
        "ck-comment-active":$ck-comment-active,
  //Form
        "form-switch-color-dim":$form-switch-color-dim,
  //Agreement
        "agreement-bg":$agreement-bg,
  //Cookies Agreement
        "cookies-agreement-bg":$cookies-agreement-bg,
        "cookies-agreement-color":$cookies-agreement-color,
  //Card Container
        "card-container-primary": $card-container-primary,
        "card-container-secondary": $card-container-secondary,
        "card-container-third": $card-container-third,
        "card-container-forth": $card-container-forth,
  //Message Box
        "message-box-warning":$message-box-warning,
        "message-box-warning-dark":$message-box-warning-dark,
        "message-box-warning-font-only":$message-box-warning-font-only,
        "message-box-error":$message-box-error,
        "message-box-error-dark":$message-box-error-dark,
        "message-box-error-font-only":$message-box-error-font-only,
        "message-box-success":$message-box-success,
        "message-box-success-dark":$message-box-success-dark,
        "message-box-success-font-only":$message-box-success-font-only,
  //Report
        "report-correct":$report-correct,
        "report-incorrect":$report-incorrect,
        "report-attempted":$report-attempted,
        "report-correctness":$report-correctness,
        "report-alert":$report-alert,
        "report-average":$report-average,
        "report-correct-light":$report-correct-light,
        "report-incorrect-light":$report-incorrect-light,
        "report-incorrect-bg":$report-incorrect-bg,
        "report-attempted-light":$report-attempted-light,
        "report-alert-light":$report-alert-light,
        "report-average-line":$report-average-line,
        "report-passing-line":$report-passing-line,
        "report-correct-bar":$report-correct-bar,
        "report-incorrect-bar":$report-incorrect-bar,
        "report-attempted-bar":$report-attempted-bar, "report-not-attempted-bar":$report-not-attempted-bar,
        "report-average-bar":$report-average-bar,
        "report-correctness-bar":$report-correctness-bar,
        "report-uncategories-bar":$report-uncategories-bar,
        "report-uncategories-line":$report-uncategories-line,
        "report-correct-text":$report-correct-text,
        "report-incorrect-text":$report-incorrect-text,
        "report-attempted-text":$report-attempted-text,
        "report-question-total":$report-question-total,

  //Drawing Layer
        "drawinglayer-border":$drawinglayer-border,

  //Chart color
  //      "chart-color-1": $chart-color-1,
  //      "chart-color-2": $chart-color-2,
  //      "chart-color-3": $chart-color-3,
  //      "chart-color-4": $chart-color-4,
  //      "chart-color-5": $chart-color-5,
  //      "chart-color-6": $chart-color-6,
  //      "chart-color-7": $chart-color-7,
  //      "chart-color-8": $chart-color-8,
  //      "chart-color-9": $chart-color-9,
  //      "chart-color-10": $chart-color-10,
  //      "chart-color-11": $chart-color-11,
  //      "chart-color-12": $chart-color-12,
  //      "chart-color-13": $chart-color-13,
  //      "chart-color-14": $chart-color-14,
  //      "chart-color-15": $chart-color-15,
  //      "chart-color-16": $chart-color-16,
  //      "chart-color-17": $chart-color-17,
  //      "chart-color-18": $chart-color-18,
  //      "chart-color-19": $chart-color-19,
  //      "chart-color-20": $chart-color-20
);

$theme-colors: map-merge($theme-colors, $custom-colors);
