@media screen and (min-width: 720px) {
    .DDTopContainer.Grid, .DDTopContainer.Label {
        display: grid; grid-column-gap: 1%; grid-template-columns: 49.5% 49.5%; padding: 0px; width: 100%;
    }
    .qtnOPDoRListContainer {
        display: grid; grid-column-gap: 1%; grid-template-columns: 49.5% 49.5%; padding: 0px; width: 100%;
    }

    .cpMediaUIRow.vis {border-radius: 6px 6px 0px 0px;}
    .cpMediaUIRow2 {flex-direction: column; justify-content: space-around; 
        border-radius: 0px 0px 6px 6px;}
    .cpMediaUIRow3 {display: flex; flex-direction: row; justify-content: center; align-items: center;
        width:80%; position:relative; margin: 10px;
    }
    
    /* moved to at550.css, for 768 width
    .qtnOPDoDrawCont {flex-direction:row; justify-content: space-between;}
    .DrawCanvasBtnBarHori {display: none;}
    .DrawCanvasBtnBarVert {display: flex;}
    .DrawCanvasBtnDL {padding:18px 8px; margin-left: 5px; margin-top:0px}
    */

     .cpMediaUI1 {display:flex}
     .cpMediaUI2 {display:none}
    /*.qtnOPDoRListItem { width:45% }*/
    /*.ATDDAnsHeader {white-space: no-wrap}*/
    .ATDDAnsHeader {white-space: nowrap}
    .ansDisplayBlock  {white-space: pre-wrap}

    .ansDetailHead { flex-direction: column; justify-content: flex-start; }
    
    .ansDetail1 { width:100%; justify-content: flex-start; }
    .ansDetailScore { width: auto }
    .aDscoreType1 { display: none }

    .ansDetail2 { width:100%; justify-content: space-between; margin-top:5px;}
    .aDscoreType2 { display: flex }

    .LDToolBtn { flex-direction: column }
    .LDMiniTxt { display:flex }    
}
