@mixin tabdrop-theme(
  $itemColor,
  $itemBackground,
  $itemBorder,
  $itemBorderWidth:0,
  $itemActiveColor:$itemsColor,
  $itemActiveBackground:$itemBackground,
  $itemActiveBorder:$itemBorder) {

  $tabdrop-my-exercise-theme: 1.5rem;
  $tabdrop-my-exercise-theme-md: 1rem;
  position: relative;

  .tabdrop-item-container {
    display: flex;
    font-size: #{$tabdrop-my-exercise-theme};
    flex-wrap: wrap;
    height: #{$tabdrop-my-exercise-theme + 1rem};
    overflow: hidden;
    padding-right: 2.5rem; //#{$tabdrop-my-exercise-theme};
    gap: 2rem;

    @include media-breakpoint-down(md) {
      gap: 1rem;
      font-size: #{$tabdrop-my-exercise-theme-md};
      height: #{$tabdrop-my-exercise-theme + 0.5rem};
    }

    .tabdrop-item {
      cursor: pointer;
      align-items: center;
      flex-wrap: nowrap;
      gap: 0.5rem;


      break-inside: avoid;
      -webkit-column-break-inside: avoid;
    }

    .svg-icon, svg {
      width: #{$tabdrop-my-exercise-theme};
      height: #{$tabdrop-my-exercise-theme};
    }
  }

  .tabdrop-item {
    display: flex;
    color: #{$itemColor};
    background-color: #{$itemBackground};
    border-bottom: #{$itemBorderWidth} #{$itemBorder} solid;
    @include media-breakpoint-down(md) {
      border-bottom: #{$itemBorderWidth/1.5} #{$itemBorder} solid;
    }

    @include uiAnimation;

    &:hover {
      color: #{$itemActiveColor};
      background-color: #{$itemActiveBackground};
      border-bottom: #{$itemBorderWidth} #{$itemActiveBorder} solid;

      @include media-breakpoint-down(md) {
        border-bottom: #{$itemBorderWidth/1.5} #{$itemActiveBorder} solid;
      }
    }
  }

  display: flex;
  align-items: center;


  .tabdrop-more {
    height: #{$tabdrop-my-exercise-theme + 0.5rem};
    @include media-breakpoint-down(md) {
      height: #{$tabdrop-my-exercise-theme };
    }

    .svg-icon {
    }
  }
}


@mixin mainTabdrop($itemBorderWidth,$itemActiveColor,$itemActiveBorder) {
  .tabdrop-item {
    &.active {
      color: #{$itemActiveColor};
      border-bottom: #{$itemBorderWidth} #{$itemActiveBorder} solid;
      position: relative;
      @include media-breakpoint-down(md) {
        border-bottom: #{$itemBorderWidth/1.5} #{$itemActiveBorder} solid;
      }
    }
  }
}

.tabdrop-my-exercise-theme {
  @include tabdrop-theme(#{$dim-400}, "transparent", "transparent", 0.225rem, #{$body-color}, "transparent", #{$sidenav-secondary});
  @include mainTabdrop(0.225rem, #{$sidenav-secondary}, #{$sidenav-secondary});

}

.tabdrop-library-theme {
  @include tabdrop-theme(#{$dim-400}, "transparent", "transparent", 0.225rem, #{$body-color}, "transparent", #{$sidenav-secondary});
  @include mainTabdrop(0.225rem, #{$sidenav-secondary}, #{$sidenav-secondary});

  .tabdrop-item {

    &:nth-child(1) {
      .svg-icon, svg {
        width: auto;
        height: 1.825rem;
        @include media-breakpoint-down(md) {
          height: 1.25rem;
        }
      }
    }
  }
}

.tabdrop-exercise-editor-theme {
  @include tabdrop-theme(#{$dim-400}, "transparent", "transparent", 0.225rem, #{$body-color}, "transparent", #{$sidenav-secondary});
  @include mainTabdrop(0.225rem, #{$sidenav-secondary}, #{$sidenav-secondary});


}


.tab-oupc {
  svg {
    width: auto;
    height: 1.5rem;
  }
}

.exercise-library-tab-theme {
  .tab-item {
    color: #{$dim-350};
    border-bottom: 3px #{$dim-350} solid;

    &.active {
      color: #{$sidenav-secondary};

      border-bottom: 3px #{$sidenav-secondary} solid;

    }
  }

  .bg-active {
    background: #{$sidenav-primary};
  }

  @include media-breakpoint-down(md) {
    font-size: #{$fs-6-size} !important;
    svg {
      width: #{$fs-6-size} !important;
      height: #{$fs-6-size} !important;
    }
    .tab-oupc {
      svg {
        width: auto !important;
      }
    }
  }

  + .simple-dropdown {
    .dropdown-text {
      border: 1px #{$sidenav-secondary} solid;
    }
  }
}

.exercise-report-theme {
  .tab-item {
    color: #{$dim-350};
    border-bottom: 3px #{$dim-350} solid;

    &.active {
      color: #{$exercise-third-btn};

      border-bottom: 3px #{$exercise-third-btn} solid;

    }
  }

  .bg-active {
    background: #{$exercise-third-btn};
  }

  @include media-breakpoint-down(md) {
    font-size: #{$fs-6-size} !important;
    svg {
      width: #{$fs-6-size} !important;
      height: #{$fs-6-size} !important;
    }
    .tab-oupc {
      svg {
        width: auto !important;
      }
    }
  }

  + .simple-dropdown {
    .dropdown-text {
      border: 1px #{$exercise-third-btn} solid;
    }
  }
}


.exercise-content-tab-theme {
  .tab-item {
    color: #{$dim-350};
    border-bottom: 3px #{$dim-350} solid;

    &.active {

      border-bottom: 3px #{$exercise-third-btn} solid;

      color: #{$exercise-third-btn};
    }
  }

  @include media-breakpoint-down(md) {
    font-size: #{$fs-6-size} !important;
    svg {
      width: #{$fs-6-size} !important;
      height: #{$fs-6-size} !important;
    }
    .tab-oupc {
      svg {
        width: auto !important;
      }
    }
  }

  + .simple-dropdown {
    .dropdown-text {
      border: 1px #{$exercise-third-btn} solid;
    }
  }
}