//.copyright {
//  font-size: 0.75rem;
//
//}
//
////Login
//.login-section{
//  background-image: url("../../img/frontend/bg.png");
//  background-repeat: no-repeat;
//  background-size: cover;
//  background-position: center;
//  @include safari_100vh;
//  @media screen and (max-height: 660px) {
//    min-height: unset;
//    background-image: none;
//    background-color: #{$login-page-secondary-bg};
//  }
//}
//.login-page {
//  //@include safari_100vh;
//
//  //min-height: 667px;
//  margin-top: 72px;
//  height: calc(100% - 72px);
//
//  &.agreement {
//    background-image: none;
//    background-color: #{$agreement-bg};
//
//    .login-container {
//      //margin-top: 72px;
//      //height: calc(100% - 72px);
//      @include safari_100vh;
//    }
//  }
//
//  @include media-breakpoint-down(md) {
//    //background-image: url("../../img/frontend/bg-mobile.png");
//  }
//
//  @media screen and (max-height: 660px) {
//    .login-col {
//      gap: 0.25rem !important;
//    }
//    .login-top {
//      padding: 0;
//      margin: 0;
//    }
//
//    .login-bottom {
//      padding: 0 !important;
//      margin: 0 !important;
//    }
//
//    .cta-container{
//      padding: 0 !important;
//      margin: 0 !important;
//    }
//
//    .logo {
//      svg {
//        color: white !important;
//      }
//    }
//  }
//
//  .login-container {
//
//    grid-template-rows: 50%;
//    @include safari_100vh;
//
//    .login-top, .login-bottom {
//      height: 50%;
//    }
//
//    .logo {
//      width: 100%;
//      max-width: 300px;
//      @include media-breakpoint-down(md) {
//        max-width: 270px;
//      }
//
//      .svg-icon {
//        width: 100%;
//      }
//
//      svg {
//        width: 100%;
//        height: 100%;
//        margin-right: 1.5rem;
//        color: black;
//      }
//    }
//
//    .btn-login {
//      background-color: #{$login-btn-color};
//      box-shadow: 1px 0rem 1rem 2px rgb(0 0 0 / 45%) !important;
//      letter-spacing: 0.05em;
//      @include noselect;
//    }
//
//    .btn-learn-more {
//      background-color: #{$login-learn-more-btn-color};
//      @include noselect;
//
//      span {
//        line-height: 0;
//      }
//
//      svg {
//        width: 0.825rem;
//        height: 0.825rem;
//      }
//    }
//
//    .btn-accept {
//      background-color: #{$login-btn-color};
//      @include media-breakpoint-down(md) {
//        width: 100%;
//      }
//
//      &.disabled {
//        background-color: #{$dim};
//        @include noselect;
//      }
//    }
//
//    .btn-decline {
//      @include media-breakpoint-down(md) {
//        width: 100%;
//      }
//    }
//
//    .oup-brand-logo {
//      img {
//        height: 1.1rem;
//        @include media-breakpoint-down(md) {
//          height: 0.825rem;
//        }
//      }
//    }
//
//    .keys-brand-logo {
//      img {
//        height: 1.6rem;
//        @include media-breakpoint-down(md) {
//          height: 1.325rem;
//        }
//      }
//    }
//  }
//}
//
//Switch Language
.switch-language-top {
  //background-color: #{$login-learn-more-btn-color};
  //color: white;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 0;
  @include noselect;
}

//Agreement-nav
.agreement-nav {
  background-color: #{$agreement-bg};
}

.agreement-checkbox {
  //$color, $bgColor, $borderColor , $checkedColor, $checkedBgColor, $checkedBorderColor
  @include cus-input-checkbox-colortheme($checkbox-white, $checkbox-dim, transparent, $checkbox-white, $login-btn-color, transparent)
}

//Filter
.manual-mark-filter-checkbox {
  //$color, $bgColor, $borderColor , $checkedColor, $checkedBgColor, $checkedBorderColor
  @include cus-input-checkbox-colortheme($checkbox-white, transparent, $checkbox-dim, $checkbox-white, $login-btn-color, $login-btn-color)
}


//Page section
.page-section {
  width: calc(100% - 64px);
  background-color: white;

  transition: transform .2s cubic-bezier(0.175, 0.685, 0.32, 1);
  -moz-transition: transform .2s cubic-bezier(0.175, 0.685, 0.32, 1); /* Firefox 4 */
  -webkit-transition: transform .2s cubic-bezier(0.175, 0.685, 0.32, 1); /* Safari and Chrome */
  -o-transition: transform .2s cubic-bezier(0.175, 0.685, 0.32, 1); /* Opera */

  @include safari_100vh;

  @include media-breakpoint-down(md) {
    width: 100%;
  }

  &.login-bg {
    background-image: url("../../img/frontend/bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @include media-breakpoint-down(sm) {
      background-image: url("../../img/frontend/bg-mobile.svg");
    }
    @media screen and (max-height: 600px) {
      min-height: unset;
      background-image: none;
      background-color: #{$login-page-secondary-bg};
    }
  }

  &.agreement {
    background-image: none;
    background-color: #{$agreement-bg};
  }
}

//Login
.login-section {
  &.agreement {
    .copyright {
      color: #{$body-color} !important;

      * {
        color: #{$body-color} !important;
      }
    }
  }

  .logo {
    height: 40%;
    @include media-breakpoint-down(lg) {
      height: 35%;
    }
    @include media-breakpoint-down(md) {
      height: 20%;
    }
    @include media-breakpoint-down(sm) {
      height: 10%;
    }
    @media screen and (max-height: 600px) {
      height: 25%;
      margin-top: 0 !important;
    }

    svg {
      width: 350px;
      height: 100%;
      margin-right: 1.5rem;
      color: black;
      //@include media-breakpoint-down(xlg) {
      //  width: 290px;
      //}
      @include media-breakpoint-down(lg) {
        width: 250px;
      }
      //@include media-breakpoint-down(md) {
      //  width: 250px;
      //}
      @include media-breakpoint-down(sm) {
        display: none !important;
      }
      @media screen and (max-height: 600px) {
        display: block !important;
        //width: 300px;
        color: white;
      }
    }
  }

  .btn-login {
    //background-color: #{$login-btn-color};
    box-shadow: 1px 0rem 1rem 2px rgb(0 0 0 / 45%) !important;
    letter-spacing: 0.05em;
    @include noselect;
  }

  .btn-learn-more {
    //background-color: #{$login-learn-more-btn-color};
    line-height: 0;
    @include noselect;

    span {
      line-height: 0;
    }

    svg {
      width: 0.825rem;
      height: 0.825rem;
    }
  }

  .btn-accept {
    //background-color: #{$login-btn-color};
    @include media-breakpoint-down(md) {
      width: 100%;
    }

    &.disabled {
      //background-color: #{$dim};
      @include noselect;
    }
  }

  .btn-decline {
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  .cta-container {
    @media screen and (min-height: 600px) {
      margin-top: 3.5rem !important;
    }
  }

  .brand-container, .cta-container {
    @media screen and (max-height: 600px) {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important
    }
  }

  .oup-brand-logo {
    img {
      height: 1.1rem;
      @include media-breakpoint-down(md) {
        //height: 0.825rem;
      }
    }
  }

  .keys-brand-logo {
    img {
      height: 1.6rem;
      @include media-breakpoint-down(md) {
        //height: 1.325rem;
      }
    }
  }

  .copyright {
    @include media-breakpoint-down(md) {
      font-size: #{$h8-font-size};
    }

    .d-flex {
      justify-content: center;
    }

    @media screen and (max-height: 600px) {
      align-items: center !important;
      justify-content: center;
    }
  }

  .no-entitlement {
    width: 100%;
    max-width: 450px;
    background-color: rgba(249, 249, 249, 1);
  }
}

//Cookies agreement
.cookies-agreement {
  background-color: #{$cookies-agreement-bg};
  color: #{$cookies-agreement-color};

  a {
    color: inherit;
  }
}


//Library

.library-list-thumbnail-container {
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  //overflow-x: hidden;
  //overflow-y: auto;

  @include media-breakpoint-down(xl) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }

  @include media-breakpoint-down(xl) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }

  @include media-breakpoint-down(lg) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }

  @include media-breakpoint-down(md) {
    overflow-y: unset;
  }
  @include media-breakpoint-down(sm) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  .library-list-thumbnail-new {
    margin-left: .5rem;
    margin-top: .325rem;

    svg {
      -webkit-filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, .7));
      filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, .7));
    }
  }

  .library-list-thumbnail {
    background-color: #{$dim};
    aspect-ratio: 16/5;
    border-radius: 4px;
    @include btnAnimation();
    @include sequenceListAnimation();
    @include cardHoverAnimation();

    .two-line {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      @include media-breakpoint-down(lg) {
        -webkit-line-clamp: 3;
      }
      @include media-breakpoint-down(md) {
        -webkit-line-clamp: 2;
      }
    }
  }

  .library-list-thumbnail-image {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 4px;
  }
}

// Exercise
.exercise-list-new-thumbnail {
  background-color: transparent !important;
  border: 3px #{$dim-400} dashed;
  transition: none !important;
  box-shadow: none !important;
}

.exercise-edit-toolbar-container {
  background-color: #{$exercise-edit-toolbar};
  width: fit-content;
  min-width: 300px;
  //padding: 0.5rem 2rem;
  //height: 3.5rem;
  font-size: #{$fs-4-response};

  //Float
  position: absolute;
  bottom: 1rem;
  //left: calc(50% + 64px);
  transform: translate(-50%, 0);

  @include media-breakpoint-down(md) {
    left: calc(50%);
  }
  @include answerShadow;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }


  @include media-breakpoint-down(md) {
    //font-size: #{$fs-6-size};
    //height: 3rem;

    svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  .split-line {
    background-color: white;
  }

  .exercise-edit-toolbar-group-item {
    //height: 2.5rem;
    //@include media-breakpoint-down(md) {
    //  height: 2rem;
    //}
  }

  .exercise-edit-toolbar-item, .exercise-edit-toolbar-item-more {
    color: white;
    padding: 0.5rem;
    border-radius: 50%;
    @include btnAnimation;

    &:hover {
      background-color: #{$exercise-edit-toolbar-item-hover};
    }
  }
}

.exercise-edit-counting {
  position: absolute;
  bottom: -1.5rem;
  @include media-breakpoint-down(lg) {
    position: relative;
    bottom: 0rem;
  }
}

.exercise-library-filter-container {
  .title {
    min-height: 2.25rem;

    @include media-breakpoint-down(md) {
      min-height: 0;
    }
  }
}

.exercise-quick-action-btn-container {
  display: grid;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;

  .exercise-quick-action-btn {
    color: #{$exercise-third-btn};

    @include btnAnimation;

    &:hover {
      background-color: #{$exercise-third-btn-hover-light};
    }

    .title {
      font-size: #{$h8-font-size};
      @include media-breakpoint-down(md) {
        font-size: #{$h9-font-size};
      }
    }
  }
}

.exercise-edit-scroll-container {
  @media only screen and (max-width: 550px) {
    &.edit-mode {
      .scroll-to-top {
        margin: 0.8rem 1.6rem 4.8rem 0.8rem !important;
        @include uiAnimation;
      }
    }
    .exercise-edit-toolbar-container {
      width: 90%;
      justify-content: space-evenly !important;
      @include uiAnimation;
    }

  }
}

.create-question-tab-menu {
  width: 220px;

  @include media-breakpoint-down(md) {
    width: 70px;
  }
}


.create-question-tab-container {
  width: calc(100% - 220px);
  @include media-breakpoint-down(md) {
    width: calc(100% - 70px);
  }

  > div {
    width: 25%;

    @include media-breakpoint-down(xxl) {
      width: 33%;
    }
    @include media-breakpoint-down(xl) {
      width: 50%;
    }
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }
}

.exercise-metadata-set{
  width: 380px;

  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

// SequenceListAnimation
.sequenceListAnimation {
  @include sequenceListAnimation()
}

//Radio
.exercise-radiobox {
  //$color, $bgColor, $borderColor , $checkedColor, $checkedBgColor, $checkedBorderColor
  @include cus-input-checkbox-colortheme($checkbox-white, transparent, $checkbox-dim, $checkbox-white, $exercise-third-btn, $exercise-third-btn)
}

//Overflow
.overflow-y {
  overflow-y: auto;
  overflow-x: hidden;
}

.overflow-unset {
  overflow: unset !important;
}


// Width
.w-md-33 {
  width: 33%;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.w-md-50 {
  width: 50%;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

.min-width-none {
  min-width: unset !important;
}

.search-question-sort {
  width: 360px;

  @include media-breakpoint-down(lg) {
    width: 250px;
  }
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}

//Rotate
.arrow-rotate {
  svg {
    transform: rotate(180deg);
    @include uiAnimation;
  }
}

//line height
.line-height-normal {
  line-height: normal;
}

// Form
.disable-option {
  filter: grayscale(1);
  opacity: 0.725;
}

.scroll-padding {
  padding-bottom: 4rem;
}

// Exercise Tag
$exercise-svg: 20px;

.exercise-tag{
  border-radius: 1.25rem;
  padding: 0.25rem 0.5rem;
  svg{
    width: #{$exercise-svg};
    height: #{$exercise-svg};
  }
}
.exercise-available-tag{
  background-color: #{$exercise-third-btn-hover-light};
}