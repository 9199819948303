.badge-component {
  * {
    font-size: #{$h8-font-size};
    line-height: #{$h8-font-size};
  }

  max-width: 100%;

  .btn {
    max-width: 100%;
  }

  span {
    max-width: 100% !important;
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: top;
  }

  svg {
    font-size: #{$h8-font-size};
    line-height: #{$h8-font-size};
    width: #{$h8-font-size};
    height: #{$h8-font-size};
  }
}


@mixin cus-badge-input($size,$line-height) {
  font-size: #{$size};
}

@mixin cus-badge-input-breakpoint($breakpoint) {
  @include media-breakpoint-down(#{$breakpoint}) {
    @include cus-badge-input($h6-font-size, $line-height-base);
  }
}

.badge-input-component {
  input {
    -moz-appearance: textfield;
    line-height: 0;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  &.#{$input-prefix} {
    &xxs {
      @include cus-badge-input($h9-font-size, $line-height-sm);
    }

    &xs {
      @include cus-badge-input($h8-font-size, $line-height-sm);
    }

    &sm {
      @include cus-badge-input($h7-font-size, $line-height-sm);
    }

    &md {
      @include cus-badge-input($h6-font-size, $line-height-base);
    }

    &lg {
      @include cus-badge-input($h5-font-size, $line-height-base);
    }

    &breakpoint-xxs {
      @include cus-badge-input-breakpoint(xs);
    }

    &breakpoint-xs {
      @include cus-badge-input-breakpoint(xs);
    }

    &breakpoint-sm {
      @include cus-badge-input-breakpoint(sm);
    }

    &breakpoint-md {
      @include cus-badge-input-breakpoint(md);
    }

    &breakpoint-lg {
      @include cus-badge-input-breakpoint(lg);
    }
  }
}