// Override
$apple: system-ui, -apple-system;
$windows: "Segoe UI";
$android: 'Roboto';
$webfontsEN: 'Noto Sans';
$webfontsZH: 'Noto Serif TC';
$rollback: "Helvetica Neue", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-sans-serif: #{$apple}, #{$windows}, #{$android},  #{$rollback}, #{$webfontsEN}, #{$webfontsZH};
//system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;


$font-weight-base: #{$font-weight-light};
$h7-font-size: $font-size-base * 0.925;
$h8-font-size: $font-size-base * 0.825;
$h9-font-size: $font-size-base * 0.725;
$h10-font-size: $font-size-base * 0.625;

$fs-1-size: #{$h1-font-size};
$fs-1-response: calc(1.375rem + 1.5vw);
$fs-2-size: #{$h2-font-size};
$fs-2-response: calc(1.325rem + 0.9vw);
$fs-3-size: #{$h3-font-size};
$fs-3-response: calc(1.3rem + 0.6vw);
$fs-4-size: #{$h4-font-size};
$fs-4-response: calc(1.275rem + 0.3vw);
$fs-5-size: #{$h5-font-size};
$fs-6-size: #{$h6-font-size};

$font-sizes: (
        1: #{$h1-font-size},
        2: #{$h2-font-size},
        3: #{$h3-font-size},
        4: #{$h4-font-size},
        5: #{$h5-font-size},
        6: #{$h6-font-size},
        7: #{$h7-font-size},
        8: #{$h8-font-size},
        9: #{$h9-font-size},
        10: #{$h10-font-size}
);