:root {
  --bs-body-color: #{$body-color};
  --bs-body-font-weight: #{$font-weight-base};
}

* {
  text-underline-offset: 0.325rem;
}

@each $size, $value in $font-sizes {
  .fs-#{$size} {
    svg {
      width: $value;
      height: $value;
    }
  }
}


.fs-1 {
  @include media-breakpoint-down(xl) {
    font-size: #{$fs-1-response} !important;
  }
}

.fs-2 {
  @include media-breakpoint-down(xl) {
    font-size: #{$fs-2-response} !important;
  }
}

.fs-3 {
  @include media-breakpoint-down(xl) {
    font-size: #{$fs-3-response} !important;
  }
}

.fs-4 {
  @include media-breakpoint-down(xl) {
    font-size: #{$fs-4-response} !important;
  }
}