@mixin MessageBox($darkColor,$lightColor) {
  border: 1px $darkColor solid;
  background-color: $lightColor;
  color: $darkColor;
}

@mixin MessageBoxFont($color) {
  color: $color !important;
}

.message-box {
  padding: 1rem 0.5rem;

  li {
    list-style-type: square;
  }

  ul {
    margin-bottom: 0;
    padding-right: 1rem;
    padding-left: 2rem;
  }

  &.warning {
    @include MessageBox(#{$message-box-warning-dark}, #{$message-box-warning})
  }

  &.error {
    @include MessageBox(#{$message-box-error-dark}, #{$message-box-error})
  }

  &.text-warning {
    padding: 0.5rem 0rem;
    @include MessageBoxFont(#{$message-box-warning-font-only})
  }

  &.text-error {
    padding: 0.5rem 0rem;
    @include MessageBoxFont(#{$message-box-error-font-only})
  }

  &.manual-mark-msg {
    padding: 0.375rem 0.5rem;
    font-size: 0.925rem;
    font-weight: 500;
  }
}
.border-notification-success{
  border-color: $message-box-success-dark;
}
.text-notification-success{
  color: $message-box-success-dark;
}
.border-notification-error{
  border-color: $message-box-error-dark;
}
.text-notification-error{
  color: $message-box-error-dark;
}
.border-notification-warning{
  border-color: $message-box-warning-dark;
}
.text-notification-warning{
  color: $message-box-warning-dark;
}