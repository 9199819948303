body {
  &#at {
    $at-setting-mode-max-width: ($at-setting-breakpointSpace + $at-setting-width + 960px);

    background-color: #{$at-bg-color};
    color: #{$at-color};

    .at-main-container {
      width: calc(100% - #{$at-setting-width});
      @media (max-width: #{$at-setting-mode-max-width}) {
        width: 100%;
      }
    }

    .at-setting {
      &:not(.offcanvas) {
        width: #{$at-setting-width};
      }

      border-left: 1px #{$dim} solid;

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    .at-setting-mode-static {
      display: block;
      @media (max-width: #{$at-setting-mode-max-width}) {
        display: none !important;
      }
    }

    .at-setting-mode-overlay {
      display: none;
      @media (max-width: #{$at-setting-mode-max-width}) {
        display: flex;
      }
    }


    .metadata-board {
      min-height: 50px;
      max-height: 500px;
      overflow: auto;
    }


    //Answer
    .checkbox-theme-at-answer-correct {
      //$color, $bgColor, $borderColor , $checkedColor, $checkedBgColor, $checkedBorderColor
      @include cus-input-checkbox-colortheme($checkbox-white, $checkbox-dim, transparent, $checkbox-white, $checkbox-correct, transparent)
    }

    .checkbox-theme-at-answer-correct-grid {
      //$color, $bgColor, $borderColor , $checkedColor, $checkedBgColor, $checkedBorderColor
      @include cus-input-checkbox-colortheme($checkbox-white, $checkbox-dim, transparent, $checkbox-correct, $checkbox-white, transparent)
    }

    .checkbox-theme-at-answer-correct-border {
      //$color, $bgColor, $borderColor , $checkedColor, $checkedBgColor, $checkedBorderColor
      @include cus-input-checkbox-colortheme($checkbox-white, $checkbox-dim, $checkbox-dim, $checkbox-white, $checkbox-correct, $checkbox-correct)
    }

    //Table
    .mcq-table {
      @include scroll-theme(#{$scrollbar-at-bg}, #{$scrollbar-at}, #{$scrollbar-at-hover});

      th, td {
        min-width: 150px;
      }

      table {
        thead {
          th {
            &:last-child {
              border: none;
              width: 60px;
              min-width: 0 !important;
            }
          }
        }
      }

      .table-handler {
        &:not(.with-indicator) {
          position: absolute;
          z-index: 5;
          bottom: -1rem;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      .indicator {
        color: #{$at-indicator-color};

        &::after {
          border: 2px #{$at-indicator-color} solid;
        }

        &.correct {
          color: #{$white};
          background-color: #{$mcq-correct};

          &::after {
            border: 2px #{$mcq-correct} solid;
          }
        }
      }

      &.table-mc {
        th {
          &:first-child {
            min-width: 0;
            width: 80px;
            padding-left: 1rem;
            padding-right: 1rem;
          }
        }
      }
    }


    //FIB
    .fib-fake-at {
      .editor-bar {
        display: flex;
        flex-wrap: wrap;

        width: 100%;
        //border-bottom: 1px black solid;


        .fake-ckeditor {
          //width: calc(100% - 200px);
          height: 100%;
          overflow: hidden;

          background-image: url("../../img/demo/fake-ckeditor.png");
          background-size: auto 100%;
        }

        .add-blank {
          width: 200px;
          height: 100%;
          overflow: hidden;
        }
      }

      @include fib-setting;

      .fib-content {
        &:empty {
          min-width: 100px;
        }
      }
    }

    .fib-modal {
      width: calc(100% - 350px);
      height: 50vh;
      overflow: hidden;

      .fib-answer-option-border {
        border-top: 1px #{$at-border} solid;
      }

      @media screen and (max-width: 1360px) {
        width: 100%;
      }

      .btn-close {
        color: white;
      }

      .alternative-answer {
        .editor-container {
          border: 1px #{$at-border-light} solid;
        }
      }
    }


    //Open-ended
    .openended-response-checkbox {
      @include cus-input-checkbox-colortheme(white, transparent, $checkbox-dim, white, $at-primary, $at-primary);
      @include noselect;
    }


    //Label the diagram
    .label-the-diagram-fake-at {
      @extend .fib-fake-at;

      .label-the-diagram-container {
        cursor: grab;
      }

      .label-diagram-image-item {


        .fib-align-tool {
          //position: absolute;
          //top: -2.2rem;
          //left: 0;
          display: inline-flex;
          justify-content: center;
          //background: white;
          //padding: 0.125rem;
          //display: none;
        }

        .fib-handler {
          position: relative;
          align-self: start;
          padding: 0.725rem 0;
          background-color: #{$label-the-diagram-drag-bg};
          color: #{$label-the-diagram-drag-color};
        }


        &.reverse {
          [data-type="fib-field"] {
            .fib-indicator {
              border-bottom-right-radius: 0 !important;
            }

            .fib-border {
              //border-top-right-radius: 0 !important;
              //border-bottom-right-radius: 0 !important;
              //border-top-left-radius: var(--bs-border-radius) !important;
              //border-bottom-left-radius: var(--bs-border-radius) !important;
            }
          }
        }

        [data-type="fib-field"] {
          cursor: pointer;
          overflow: unset;
          border: none;


          .fib-indicator {
            border-bottom-left-radius: 0 !important;
          }

          .fib-border {
            //border-top-left-radius: 0 !important;
            //border-bottom-left-radius: 0 !important;
          }
        }
      }


      [contenteditable=true] {
        line-height: normal;
      }

      .fib-indicator {
        display: inline-flex;
        align-items: center;
        flex-direction: row-reverse;
      }
    }
  }
}

.at-setting-native-radio {
  input[type='radio'] {
    accent-color: #{$at-primary};
  }
}