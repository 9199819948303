.date-picker {
  .disable, &:hover {
    .date-picker-container {
      background-color: #{$dim-100};
      @include uiAnimation;
    }
  }


  .icon {
    color: #{$dim-400};
  }

  .date-display-title {
    color: #{$exercise-third-btn};
  }

  .date-display {
    line-height: normal;
  }

  .date-display-placeholder {
    color: #{$dim-400};
    line-height: normal;
  }
}

.date-picker-overlay {
  &:not(.sticky) {
    @include media-breakpoint-down(md) {
      background-color: rgba(0, 0, 0, 0.5);
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow-y: auto;
      z-index: $z-index-datepicker;
    }

    .date-picker-panel {
      @include media-breakpoint-down(md) {
        position: relative;
        margin: auto;
        width: 100%;
        max-width: 400px;
        height: auto;
        top: 50%;
        left: unset !important;
        right: unset !important;
        transform: translateY(-50%);
        @media (max-height: 600px) {
          top: unset;
          transform: unset;
          position: static;
        }
      }
    }
  }

  &.sticky {
    .date-picker-panel {

      position: relative;
      width: 100%;
    }
  }

  .date-picker-panel {
    background-color: white;
    position: absolute;
    width: 400px;
    height: 400px;
    top: 100%;
    z-index: $z-index-datepicker;

    @include media-breakpoint-down(md) {
      position: relative;
      margin: auto;
      width: 100%;
    }

    &.with-time {
      width: 500px;
      @include media-breakpoint-down(md) {
        width: 100%;
        height: calc(100% - 1rem);
        min-height: 600px;
        max-height: 800px;

      }

      .calendar-date-container {
        width: 350px;
        @include media-breakpoint-down(md) {
          height: 60% !important;
        }
      }

      .calendar-time-container {
        @include media-breakpoint-down(md) {
          height: 40% !important;
        }
      }
    }


    @include containerFadeAnimation;
    @include answerShadow;


    .calendar-date-container {
      width: 100%;
      height: 100%;
      @include media-breakpoint-down(md) {
        width: 100% !important;
      }
    }

    .calendar-time-container {
      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    .calendar-top {
      grid-template-columns: repeat(5, 1fr);
    }

    .calendar-bottom {
    }

    .calendar-years {
      grid-template-columns: repeat(3, 1fr);
    }

    .calendar-months {
      grid-template-columns: repeat(3, 1fr);
    }

    .calendar-weeks {
      grid-template-columns: repeat(7, 1fr);
    }

    .calendar-dates {
      grid-template-columns: repeat(7, 1fr);
    }

    .calendar-day {
      //  &:not(:empty) {
      //    border-right: 1px #{$dim-200} solid;
      //  }
      //
      //  &:nth-child(-n+7) {
      //    &:not(:empty) {
      //      //border-top: 1px #{$dim-200} solid;
      //    }
      //  }
      //
      //  &:nth-child(-n+7) {
      //    &:not(:empty) {
      //      border-left: 1px #{$dim-200} solid;
      //      border-right: 0px #{$dim-200} solid;
      //    }
      //  }
      //
      //  &:nth-child(7) {
      //    &:not(:empty) {
      //      border-right: 1px #{$dim-200} solid;
      //    }
      //  }
      //
      //  &:nth-child(7n+1) {
      //    &:not(:empty) {
      //      border-left: 1px #{$dim-200} solid;
      //    }
      //  }
      //
      //  border-bottom: 1px #{$dim-200} solid;
      //
      //  border-radius: 4px;
      &:nth-child(7n), &:nth-child(7n+1), &:first-child {
        &:not(:empty) {
          background-color: #{$dim-100};
        }
      }
    }

    .calendar-month, .calendar-year {
      border-radius: 4px;
    }

    .calendar-previous, .calendar-next {
      .svg-icon {
        @include btnAnimation;
        width: 2rem;
        height: 2rem;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          background-color: #{$exercise-third-btn-hover-light} !important;
          color: #{$exercise-third-btn-hover} !important;
        }
      }
    }

    .calendar-day, .calendar-month, .calendar-year, .calendar-time {
      @include btnAnimation;

      &:hover {
        &:not(:empty) {
          &:not(.active) {
            background-color: #{$exercise-third-btn-hover-light} !important;
            color: #{$exercise-third-btn-hover} !important;
          }
        }
      }

      &.active {
        background-color: #{$exercise-third-btn} !important;
        color: white;
      }
    }
  }


}