.table-data-container {
  overflow: hidden;

  @include media-breakpoint-down(md) {
    overflow: unset;
  }

  .table-list-container {
    width: 40%;
    overflow-y: auto;
    overflow-x: hidden;

    &.search-list-container {
      @include media-breakpoint-down(md) {
        //width: 70px;
        //overflow-y: hidden;
        //overflow-x: auto;
        overflow: unset;
      }
    }

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  .table-preview-container {
    width: 60%;
    overflow-x: hidden;
    @include media-breakpoint-down(md) {
      width: 100%;
      //height: 99%;
      //left: 0;
      //bottom: -100vh;
      //position: fixed;
      //background-color: white;
      //z-index: 5;
      //
      //transition: all .2s cubic-bezier(0.175, 0.685, 0.32, 1);
      //-moz-transition: all .2s cubic-bezier(0.175, 0.685, 0.32, 1); /* Firefox 4 */
      //-webkit-transition: all .2s cubic-bezier(0.175, 0.685, 0.32, 1); /* Safari and Chrome */
      //-o-transition: all .2s cubic-bezier(0.175, 0.685, 0.32, 1); /* Opera */
      //
      //&.active {
      //  bottom: 0;
      //}
    }
  }
}

.table-fit-column {
  tr {
    td {
      padding-bottom: 0.825rem;
    }

    td:first-child {
      white-space: nowrap;
      width: 1%;
      padding-top: 0.3375rem;
      padding-right: 0.5rem;
    }
  }
}

.assignment-table-status {
  th, td {
    padding: 0.5rem 1rem;
    white-space: nowrap;
    text-align: center;
  }

  //th {
  //  font-size: 0.825rem;
  //}
  //
  //td {
  //  font-size: 0.725rem;
  //}

  tr:first-child {
    background-color: #{$dim-250};
  }

  tr:not(:first-child):nth-child(odd) {
    background-color: #{$dim-200};
  }
}