@mixin cus-btn($size,$line-height) {
  font-size: #{$size};
  svg {
    width: #{$line-height};
    height: #{$line-height};
  }

  &.animate {
    @include bounceAnimation();
  }
}

@mixin cus-btn-breakpoint($breakpoint) {
  @include media-breakpoint-down(#{$breakpoint}) {
    @include cus-btn($h6-font-size, $line-height-base);
  }
}

.#{$btn-prefix} {
  &xxs {
    @include cus-btn($h9-font-size, calc($h9-font-size + 0.1rem));
  }

  &xs {
    @include cus-btn($h8-font-size, calc($h8-font-size + 0.1rem));
  }

  &sm {
    @include cus-btn($h7-font-size, calc($h7-font-size + 0.1rem));
  }

  &md {
    @include cus-btn($h6-font-size, calc($h6-font-size + 0.1rem));
  }

  &lg {
    @include cus-btn($h5-font-size, calc($h5-font-size + 0.1rem));
  }

  &breakpoint-xxs {
    @include cus-btn-breakpoint(xs);
  }

  &breakpoint-xs {
    @include cus-btn-breakpoint(xs);
  }

  &breakpoint-sm {
    @include cus-btn-breakpoint(sm);
  }

  &breakpoint-md {
    @include cus-btn-breakpoint(md);
  }

  &breakpoint-lg {
    @include cus-btn-breakpoint(lg);
  }
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    color: $white;

    &:hover, &:focus {
      color: $white !important;
    }
  }

  .btn-outline-#{$color} {
    color: $value;

    &.with-bg-white {
      background: #{$white} !important;

      &:hover, &:active {
        color: $white !important;
        background: #{$value} !important;
      }

      &:focus {
        color: $white !important;
        background: #{$value} !important;
      }
    }

    &:hover, &:active {
      color: $white !important;
    }

    &:focus {
      color: $white !important;
      background: #{$value} !important;
    }
  }


  .btn-borderless-#{$color} {
    color: $value;
    border: none;

    &:hover, &:active {
      color: $white !important;
      background: #{$value} !important;
    }

    &:focus {
      color: $white !important;
      background: #{$value} !important;
    }
  }

  .btn-color-#{$color} {
    color: #{$value};
    background-color: lighten($value, 50);

    &:hover, &:focus {
      color: #{$value} !important;
      background-color: lighten($value, 55) !important;
    }
  }

  .btn-color-light-#{$color} {
    color: #{$value};
    background-color: lighten($value, 40);

    &:hover, &:focus {
      color: #{$value} !important;
      background-color: lighten($value, 35) !important;
    }
  }

  .btn-color-dark-#{$color} {
    color: #{$value};
    background-color: lighten($value, 50);

    &:hover, &:focus {
      color: $white !important;
      background-color: lighten($value, 40) !important;
    }
  }

  .btn-transparent-#{$color} {
    color: #{$value};

    &:hover, &:focus {
      color: #{$value} !important;
      background-color: lighten($value, 45) !important;
    }
  }

  .btn-hover-light-#{$color} {
    color: $white;

    &:hover, &:focus {
      color: #{$value} !important;
      background-color: #{$white} !important;
    }
  }

  .btn-hover-dark-#{$color} {
    color: $white;

    &:hover, &:focus {
      color: $white !important;
      background-color: darken($value, 10) !important;
    }
  }

  .btn-gray-#{$color} {
    color: #{$value};

    &:hover, &:focus {
      color: #{$value} !important;
      background-color: #{$hover-bg-400} !important;
    }
  }

  .btn-plain-#{$color} {
    color: #{$value};

    &:hover, &:focus {
      color: #{$value} !important;
      background-color: transparent !important;
    }
  }
}

.btn {
  &:active {
    border-color: transparent !important;
  }
}

@mixin gray-hover() {
  //background-color: transparent !important;
  //&:hover {
  //  background-color: #{$dim-200} !important;
  //}
}

@mixin svgFill() {
  //* {
  //  pointer-events: none;
  //  fill: currentColor;
  //}
  //svg {
  //  width: $font-size-base;
  //  height: $font-size-base;
  //}
  //&.btn-sm {
  //  svg {
  //    width: $font-size-base;
  //    height: $font-size-base;
  //  }
  //
  //  &.keep-icon-base-height {
  //    svg {
  //      margin: 0.325rem;
  //    }
  //  }
  //}
  //&.btn-lg {
  //  svg {
  //    width: $font-size-lg;
  //    height: $font-size-lg;
  //  }
  //}
}

.btn {
  //@include bounceAnimation();
  //@include svgFill();
}

.btn {
  //&.gray-hover {
  //  @include gray-hover();
  //}
}

//
//@each $color, $value in $new-color {
//  .btn-solid-#{$color} {
//    $backgroundColor: $value;
//    $border: transparent;
//    $fontColor: white;
//
//    $hoverBackground: lighten($value, 10%);
//    $hoverBorder: lighten($value, 10%);
//    $hoverColor: white;
//
//    $activeBackground: lighten($value, 10%);
//    $activeBorder: lighten($value, 10%);
//    $activeColor: white;
//    //$disabled-background
//    //$disabled-border
//    //$disabled-color
//
//    @include button-variant($backgroundColor, $border, $fontColor, $hoverBackground, $hoverBorder, $hoverColor, $activeBackground, $activeBorder, $activeColor);
//    @include svgFill(white, white);
//    @include bounceAnimation();
//  }
//
//  .btn-light-solid-#{$color} {
//    $backgroundColor: lighten($value, 45%);
//    $border: transparent;
//    $fontColor: $value;
//
//    $hoverBackground: $value;
//    $hoverBorder: transparent;
//    $hoverColor: white;
//
//    $activeBackground: $value;
//    $activeBorder: transparent;
//    $activeColor: white;
//    //$disabled-background
//    //$disabled-border
//    //$disabled-color
//
//    @include button-variant($backgroundColor, $border, $fontColor, $hoverBackground, $hoverBorder, $hoverColor, $activeBackground, $activeBorder, $activeColor);
//    @include svgFill($value, white);
//    @include bounceAnimation();
//  }
//
//  .btn-light-solid-border-#{$color} {
//    $backgroundColor: lighten($value, 45%);
//    $border: $value;
//    $fontColor: $value;
//
//    $hoverBackground: $value;
//    $hoverBorder: $value;
//    $hoverColor: white;
//
//    $activeBackground: $value;
//    $activeBorder: $value;
//    $activeColor: white;
//    //$disabled-background
//    //$disabled-border
//    //$disabled-color
//
//    @include button-variant($backgroundColor, $border, $fontColor, $hoverBackground, $hoverBorder, $hoverColor, $activeBackground, $activeBorder, $activeColor);
//    @include svgFill($value, white);
//    @include bounceAnimation();
//  }
//
//  .btn-solid-gray-border-#{$color} {
//    $backgroundColor: white;
//    $border: $dim-200;
//    $fontColor: darken($value, 5%);
//
//    $hoverBackground: white;
//    $hoverBorder: lighten($value, 20%);
//    $hoverColor: $value;
//
//    $activeBackground: white;
//    $activeBorder: lighten($value, 20%);
//    $activeColor: $value;
//    //$disabled-background
//    //$disabled-border
//    //$disabled-color
//
//    @include button-variant($backgroundColor, $border, $fontColor, $hoverBackground, $hoverBorder, $hoverColor, $activeBackground, $activeBorder, $activeColor);
//    @include svgFill($value, white);
//    @include bounceAnimation();
//  }
//
//  .btn-border-#{$color} {
//    $fontColor: $value;
//    $colorHover: white;
//    $activeBackground: $value;
//    $activeBorder: $value;
//    $activeColor: white;
//
//    @include button-outline-variant($fontColor, $colorHover, $activeBackground, $activeBorder, $activeColor);
//    @include svgFill($value, white);
//    @include bounceAnimation();
//  }
//
//  .btn-borderless-#{$color} {
//    @extend .btn-border-#{$color};
//    border-color: transparent;
//    color: $value;
//    fill: $value;
//
//    &:hover {
//      border-color: transparent !important;
//      background-color: #{$hover-bg-400} !important;
//      color: $value !important;
//    }
//
//    @include svgFill($value, $value);
//    @include bounceAnimation();
//
//    + .dropdown-menu {
//      a {
//        &:hover, &:active {
//          border-color: transparent !important;
//          background-color: #{$hover-bg-400} !important;
//          color: $value !important;
//        }
//      }
//    }
//  }
//
//  .btn-long-solid-#{$color} {
//    $backgroundColor: $value;
//    $border: transparent;
//    $fontColor: white;
//
//    $hoverBackground: lighten($value, 10%);
//    $hoverBorder: transparent;
//    $hoverColor: white;
//
//    $activeBackground: lighten($value, 10%);
//    $activeBorder: transparent;
//    $activeColor: white;
//    //$disabled-background
//    //$disabled-border
//    //$disabled-color
//    @include button-variant($backgroundColor, $border, $fontColor, $hoverBackground, $hoverBorder, $hoverColor, $activeBackground, $activeBorder, $activeColor);
//
//    @include svgFill(white, white);
//    padding-top: 5px;
//    padding-bottom: 5px;
//    max-width: 275px;
//    width: 100%;
//    @include bounceAnimation();
//  }
//
//  .btn {
//    &.btn-active-#{$color} {
//      &:hover {
//        color: $value !important;
//      }
//    }
//  }
//}
//
//.btn, a, .dropdown-item {
//  &:active {
//    background-color: inherit;
//    border-color: inherit;
//  }
//}
//
//.btn-active-bg-transparent {
//  &:hover {
//    background-color: transparent !important;
//  }
//}

.exercise-action-btn {
  //padding: 0.3rem 0.875rem 0.3rem 0.775rem;
  background-color: #{$exercise-third-btn-hover-light};
  //border: 2px #{$dim} solid;
  -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  color: #{$exercise-third-btn};
  white-space: nowrap;

  //@include media-breakpoint-down(lg) {
  //  .title {
  //    display: none;
  //  }
  //}

  &:hover {
    background-color: #{$exercise-third-btn};
    color: white;
  }
}

.exercise-small-action-btn {
  border-radius: 4px;
  padding: 0.5rem;

  svg {
    width: 1rem;
    height: 1rem;
  }

  &.edit {
    color: #{$exercise-third-btn};
  }

  &.confirm {
    color: #{$mcq-correct};
  }

  &.cancel {
    color: #{$mcq-incorrect};
  }

  @include btnAnimation;

  &:hover {
    &.edit {
      background-color: #{$exercise-third-btn};
    }

    &.confirm {
      background-color: #{$mcq-correct};
    }

    &.cancel {
      background-color: #{$mcq-incorrect};
    }

    color: white;
  }
}

.action-btn {
  @include btnAnimation;
}

.card-favourite-btn {
  color: #{$dim-400};

  svg {
    width: 1.2rem !important;
    height: 1.2rem !important;
  }

  &.active {
    color: #{$exercise-third-btn} !important;

    &:hover {
      background-color: transparent;
    }
  }
}


.exercise-card {
  &.active {
    .card-favourite-btn, .card-collection-btn, .card-trash-btn, .card-more-btn, .card-common-btn {
      @media (hover: hover) {
        &:hover {
          background-color: #{$exercise-third-btn};
          color: white !important;
        }
      }
    }
  }
}

.card-favourite-btn, .card-collection-btn, .card-trash-btn, .card-more-btn, .card-common-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.375rem;
  @media (hover: hover) {
    &:hover {
      background-color: #{$exercise-third-btn};
      color: white;
    }
  }
}

.card-collections-tool-btn {
  &:hover {
    background-color: rgba(226, 226, 226, 0.85);
  }
}

.badge-btn {
  padding: 0.3rem 0.675rem;
  background-color: #{$exercise-third-btn-hover-light};
  color: #{$exercise-third-btn};

  &:hover {
    background-color: #{$exercise-third-btn};
    color: white;
  }
}

.badge-exercise-third-btn {
  padding: 0.3rem 0.675rem;
  background-color: #{$exercise-third-btn-hover-light};
  color: #{$exercise-third-btn};
}

.badge-card-action-primary {
  padding: 0.3rem 0.675rem;
  background-color: #{$card-container-secondary};
  color: #{$card-container-primary};
}

.badge-taggings-primary {
  padding: 0.3rem 0.675rem;
  background-color: #{$card-container-secondary};
  color: #{$card-container-primary};
}


.collection-color-picker {
  border: 3px transparent solid;
  border-radius: 1.5rem;
  width: 2rem;
  height: 2rem;

  .color {
    width: calc(1.5rem - 1.5px);
    height: calc(1.5rem - 1.5px);
    border-radius: calc(1.5rem - 3px);
    border: 3px transparent solid;
    display: flex;
    align-items: center;
    justify-content: center;

    .color {
      width: calc(0.8rem);
      height: calc(0.8rem);
      border: none;
    }
  }

  &.add-color {
    width: 1rem;
    height: 1rem;
    border: 3px #{$dim-300} solid;

    span {
      width: 1rem;
      height: 1rem;
    }
  }

  &.active {
    border: 2px #{$dim-400} solid;
  }
}

.exercise-create-btn {
  background-color: #{$exercise-third-btn};
  border-radius: 4px;
  font-size: 1.1rem;
  //min-width: 112px;
  height: 2rem;
  color: white;
  @include btnAnimation;

  &:hover {
    background-color: #{$exercise-third-btn-hover};
  }

  .svg-icon {
    width: 1.2rem;
    height: 1.2rem;
  }

  svg {
    width: 1.2rem;
    height: 1.2rem;
  }

  //@include media-breakpoint-down(md) {
  //  padding: 0;
  //  width: 1.55rem;
  //  height: 1.55rem;
  //  .svg-icon {
  //    width: 1.55rem;
  //    height: 1.55rem;
  //  }
  //  svg {
  //    width: 100%;
  //    height: 100%;
  //  }
  //}
}

.filter-count {
  top: -0.325rem;
  right: -0.325rem;
}

.menuTitle-item {
  position: relative;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    width: calc(100% + 1rem);
    height: calc(100% + 1rem);
    top: -0.5rem;
    left: -0.5rem;
    padding: 1rem;
    border-radius: 4px;
  }

  &:hover {
    &::before {
      content: "";
      position: absolute;
      width: calc(100% + 1rem);
      height: calc(100% + 1rem);
      top: -0.5rem;
      left: -0.5rem;
      padding: 1rem;
      background-color: #{$dim-350};
      border-radius: 4px;
    }

    > span, > div {
      z-index: 1;
    }
  }
}

.animation-fade-up {
  @include textEnterAnimation;
}

.hidden-action-btn-text-md {
  @include media-breakpoint-down(md) {
    .text {
      display: none;
    }
  }
}

// Fix
.btn-exercise-third-btn-hover-light {
  &:hover, &:focus {
    background-color: #{$exercise-third-btn} !important;
  }
}

.hover-gray {
  &:hover {
    background-color: #{$dim-100};
  }
}

//Download excel
.excel-btn {
  border: 1.5px #309866 solid;

  svg {
    //path:nth-child(1) {
    //  fill: #ffffff !important;
    //}
    //
    //path:nth-child(2) {
    //  fill: #ffffff !important;;
    //}
    //
    //path:nth-child(3) {
    //  fill: #ffffff !important;;
    //}
  }

  &:hover, &:focus {
    background: $dim-200;
    border: 1.5px #309866 solid;
  }
}

//Shortcuts
.shortcuts-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 1rem;
  padding: 0 1rem 1rem 1rem;

  .shortcut-item {
    position: relative;
    overflow: hidden;
    color: white;
    border-radius: 1rem;
    padding: 1.5rem 1rem;
    -webkit-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.05);
    transition: box-shadow 0.215s ease-in-out;
    display: flex;

    @include media-breakpoint-down(md) {
      align-items: center;
    }

    &:hover {
      -webkit-box-shadow: 4px 3px 3px 2px rgba(0, 0, 0, 0.15);
      box-shadow: 4px 3px 3px 2px rgba(0, 0, 0, 0.15);

      .icon-item {
        svg {
          scale: 1.3;
        }
      }
    }

    &:nth-child(1) {
      background: rgb(38, 217, 167);
      background: -moz-linear-gradient(141deg, rgba(38, 217, 167, 1) 46%, rgba(5, 198, 121, 1) 100%);
      background: -webkit-linear-gradient(141deg, rgba(38, 217, 167, 1) 46%, rgba(5, 198, 121, 1) 100%);
      background: linear-gradient(141deg, rgba(38, 217, 167, 1) 46%, rgba(5, 198, 121, 1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#26d9a7", endColorstr="#05c679", GradientType=1);
    }

    &:nth-child(2) {
      background: rgb(38, 191, 217);
      background: -moz-linear-gradient(141deg, rgba(38, 191, 217, 1) 46%, rgba(5, 144, 198, 1) 100%);
      background: -webkit-linear-gradient(141deg, rgba(38, 191, 217, 1) 46%, rgba(5, 144, 198, 1) 100%);
      background: linear-gradient(141deg, rgba(38, 191, 217, 1) 46%, rgba(5, 144, 198, 1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#26bfd9", endColorstr="#0590c6", GradientType=1);
    }

    &:nth-child(3) {
      background: rgb(255, 72, 128);
      background: -moz-linear-gradient(141deg, rgba(255, 72, 128, 1) 46%, rgba(255, 56, 90, 1) 100%);
      background: -webkit-linear-gradient(141deg, rgba(255, 72, 128, 1) 46%, rgba(255, 56, 90, 1) 100%);
      background: linear-gradient(141deg, rgba(255, 72, 128, 1) 46%, rgba(255, 56, 90, 1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff4880", endColorstr="#ff385a", GradientType=1);
    }

    &:nth-child(4) {
      background: rgb(255, 185, 72);
      background: -moz-linear-gradient(141deg, rgba(255, 185, 72, 1) 46%, rgba(255, 169, 56, 1) 100%);
      background: -webkit-linear-gradient(141deg, rgba(255, 185, 72, 1) 46%, rgba(255, 169, 56, 1) 100%);
      background: linear-gradient(141deg, rgba(255, 185, 72, 1) 46%, rgba(255, 169, 56, 1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffb948", endColorstr="#ffa938", GradientType=1);
    }
  }

  .text-item {
    opacity: 0.95;
    z-index: 2;
  }

  .icon-item {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    opacity: 0.15;
    width: 100%;
    height: 100%;

    .svg-icon {
      justify-content: end;
      align-items: center;
      width: 50%;
      height: 100%;
      float: right;

      svg {
        scale: 1.1;
        transition: scale 0.215s ease-in-out;
        width: 100%;
      }
    }
  }
}