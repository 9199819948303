@mixin cus-switch($size) {
  input {
    font-size: #{$size};
    height: #{$size};
  }
  label {
    font-size: #{$size};
  }
}

@mixin cus-switch-breakpoint($breakpoint) {
  @include media-breakpoint-down(#{$breakpoint}) {
    @include cus-switch($h4-font-size);
  }
}

.switch-component {
  input {
    margin-top: 0;
  }
}

.#{$switch-prefix} {
  &xxs {
    @include cus-switch($h7-font-size);
  }

  &xs {
    @include cus-switch($h6-font-size);
  }

  &sm {
    @include cus-switch($h5-font-size);
  }

  &md {
    @include cus-switch($h4-font-size);
  }

  &lg {
    @include cus-switch($h3-font-size);
  }

  &breakpoint-xxs {
    @include cus-switch-breakpoint(xs);
  }

  &breakpoint-xs {
    @include cus-switch-breakpoint(xs);
  }

  &breakpoint-sm {
    @include cus-switch-breakpoint(sm);
  }

  &breakpoint-md {
    @include cus-switch-breakpoint(md);
  }

  &breakpoint-lg {
    @include cus-switch-breakpoint(lg);
  }
}

@each $color, $value in $theme-colors {
  .#{$switch-prefix}#{$color} {
    input {
      &:checked {
        border: none;
        background-color: #{$value};
      }
    }
  }
}

.switch-component {
  input:disabled {
    //background-color: #{$form-switch-color-dim};
  }
}


//simple-switch
.simple-switch-container {
  color: #{$dim};
  font-size: 1.1rem !important;
  line-height: normal;

  .active {
    color: #{$sidenav-primary};
  }

  .simple-switch {
    $simple-bar-height: 5px;
    $simple-bar-width: 40px;
    $simple-label-size: 20px;
    width: #{$simple-bar-width};
    height: #{$simple-bar-height};
    background: #{$dim};
    position: relative;
    border-radius: 50px;

    label {
      display: block;
      width: #{$simple-label-size};
      height: #{$simple-label-size};
      position: absolute;
      top: -#{$simple-label-size / 2 - $simple-bar-height /2};
      left: -3px;
      cursor: pointer;
      background: #fcfff4;
      background: linear-gradient(to bottom, #{$sidenav-primary} 0%, #{$sidenav-primary} 40%, #{$sidenav-primary} 100%);
      border-radius: 50px;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
      transition: all 0.3s ease;
    }

    input[type=checkbox] {
      visibility: hidden;

      &:checked + label {
        left: #{$simple-bar-width - $simple-label-size};
      }
    }
  }

}


.simple-switch-quick-action-theme {
  .active {
    color: #{$exercise-third-btn};
  }

  .simple-switch {
    label {
      background: linear-gradient(to bottom, #{$exercise-third-btn} 0%, #{$exercise-third-btn} 40%, #{$exercise-third-btn} 100%);
    }
  }
}