// Include any default variable overrides here
@import "src/poc/static/scss/custom-variable";

@import "bootstrap/scss/bootstrap";

// Add additional custom code here
@import "src/poc/static/scss/polyfill";

html, body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  font-family: #{$apple},#{$windows}; 
/*   
  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
*/
}

.min-vh-100 {
  @include min_safari_100vh;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
