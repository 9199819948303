.form-number-component {
  width: fit-content;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input {
    -moz-appearance: textfield;
    max-width: 2.5rem;
    padding: 0;
    line-height: 0;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

@mixin cus-input($size,$line-height) {
  font-size: #{$size};
  input {
    font-size: #{$size};
  }
}

@mixin cus-input-breakpoint($breakpoint) {
  @include media-breakpoint-down(#{$breakpoint}) {
    @include cus-input($h6-font-size, $line-height-base);
  }
}

.#{$input-prefix} {
  &xxs {
    @include cus-input($h9-font-size, $line-height-sm);
  }

  &xs {
    @include cus-input($h8-font-size, $line-height-sm);
  }

  &sm {
    @include cus-input($h7-font-size, $line-height-sm);
  }

  &md {
    @include cus-input($h6-font-size, $line-height-base);
  }

  &lg {
    @include cus-input($h5-font-size, $line-height-base);
  }

  &breakpoint-xxs {
    @include cus-input-breakpoint(xs);
  }

  &breakpoint-xs {
    @include cus-input-breakpoint(xs);
  }

  &breakpoint-sm {
    @include cus-input-breakpoint(sm);
  }

  &breakpoint-md {
    @include cus-input-breakpoint(md);
  }

  &breakpoint-lg {
    @include cus-input-breakpoint(lg);
  }
}

//.form-drop-down-component {
//  .dropdown-toggle {
//    display: flex;
//    justify-content: center;
//    align-items: center;
//
//    svg {
//      width: 1rem;
//      height: 1rem;
//    }
//  }
//
//  .dropdown-item {
//    svg {
//      width: 1rem;
//      height: 1rem;
//    }
//  }
//}


//textarea:hover,
//input:hover,
//textarea:active,
//input:active,
//textarea:focus,
//input:focus,
//button:focus,
//button:active,
//button:hover,
//label:focus,
//.btn:active,
//.btn.active {
//  outline: 0px !important;
//  -webkit-appearance: none;
//  box-shadow: none !important;
//}
