@mixin bounceAnimation($value:0.98) {
  &:active {
    transform: scale($value);
    box-shadow: 0 1px 4px -1px var(--shadow);
  }
}

@mixin changeColorAnimation() {
  transition: all 0.15s ease-out;
}

.bounce-animation {
  @include bounceAnimation();
}

.bounce-animation-light {
  @include bounceAnimation(0.99);
}

@mixin btnAnimation() {
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@mixin uiAnimation() {
  transition: all .2s cubic-bezier(0.175, 0.685, 0.32, 1);
  -moz-transition: all .2s cubic-bezier(0.175, 0.685, 0.32, 1); /* Firefox 4 */
  -webkit-transition: all .2s cubic-bezier(0.175, 0.685, 0.32, 1); /* Safari and Chrome */
  -o-transition: all .2s cubic-bezier(0.175, 0.685, 0.32, 1); /* Opera */
}

.uiAnimation {
  @include uiAnimation;
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: scale(0.85);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fade-in-container {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@mixin sequenceListAnimation() {
  animation: .3s ease-in-out both fade-in;

  @for $i from 1 through 50 {
    &:nth-child(#{$i}) {
      // Delay the animation. Delay increases as items loop.
      animation-delay: $i * (.03s);
    }
  }
}

@mixin cardHoverAnimation() {
  -webkit-box-shadow: 0 2px 2px rgb(10 16 20 / 24%), 0 0 2px rgb(10 16 20 / 12%);
  -moz-box-shadow: 0 2px 2px rgb(10 16 20 / 24%), 0 0 2px rgb(10 16 20 / 12%);
  box-shadow: 0 2px 2px rgb(10 16 20 / 24%), 0 0 2px rgb(10 16 20 / 12%);

  &:hover {
    -webkit-box-shadow: 0 8px 8px rgb(10 16 20 / 50%), 0 0 8px rgb(10 16 20 / 12%);
    -moz-box-shadow: 0 8px 8px rgb(10 16 20 / 50%), 0 0 8px rgb(10 16 20 / 12%);
    box-shadow: 0 8px 8px rgb(10 16 20 / 50%), 0 0 8px rgb(10 16 20 / 12%);
    text-decoration: none;
  }
}


@mixin slide-up($translateY) {
  $animation-name: unique-id() !global;

  @keyframes #{$animation-name} {
    0% {
      transform: translateY(#{$translateY})
    }
    100% {
      transform: translateY(0)
    }
  }
  animation: #{$animation-name} 0.4s .2s cubic-bezier(0.175, 0.685, 0.32, 1) both;
}

@mixin textEnterAnimation() {
  @include slide-up("150%")
}

@mixin containerFadeAnimation() {
  animation: .3s ease-in-out both fade-in-container;
}

.fade-in-container {
  @include containerFadeAnimation;
}


@keyframes shake {
  0% {
    transform: rotateZ(0deg) translateX(0) translateY(0) scale(1);
  }
  20% {
    transform: rotateZ(-8deg) translateX(0) translateY(0);
  }
  40% {
    transform: rotateZ(0deg) translateX(0) translateY(0);
  }
  60% {
    transform: rotateZ(8deg) translateX(0) translateY(0);
  }
  100% {
    transform: rotateZ(0deg) translateX(0) translateY(0);
  }
}


.shakeAnimation {
  animation: shake 0.4s linear infinite;
}