.accordion-header {
  background-color: #{$accordion-header};
}

.exercise-filter {
  > .accordion-button {
    &:not(.collapsed) {
      &::after {
        background-image: #{$accordion-button-arrow-icon} !important;
        transform: rotate(180deg);
      }
    }

    //&.searchExercises {
    //  &:not(.collapsed) {
    //    &::after {
    //      background-image: #{$accordion-button-exercise-arrow-icon} !important;
    //    }
    //  }
    //}

    &::after {
      width: 1.25rem;
      height: 1.25rem;
      background-image: #{$accordion-button-arrow-icon} !important;
      transition: all .3s cubic-bezier(0.175, 0.685, 0.32, 1);
      -moz-transition: all .3s cubic-bezier(0.175, 0.685, 0.32, 1); /* Firefox 4 */
      -webkit-transition: all .3s cubic-bezier(0.175, 0.685, 0.32, 1); /* Safari and Chrome */
      -o-transition: all .3s cubic-bezier(0.175, 0.685, 0.32, 1); /* Opera */
    }

    &.searchQuestion {
      &::after {
        background-image: #{$accordion-button-exercise-arrow-icon} !important;
      }
    }
  }
}

.exercise-subfilter {
  .split-line {
    border-bottom: 1px #{$dim-300} dashed;
  }

  > .accordion-item {
    border-radius: 0 !important;

    > .accordion-header {
      * {
        color: #{$body-color};
      }
    }
  }
}

.exercise-filter, .exercise-subfilter {
  .accordion-button {
    padding: 0.5rem;
  }
}

.exercise-search-filter-container {
  .filter-backdrop {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.25);
      z-index: 1;
    }
  }
}

.exercise-search-filter {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}