.tooltips[data-tool-tip] {
  position: relative;
}

@media (hover: hover) {
  .tooltips[data-tool-tip]:hover::before {
    visibility: visible;
    opacity: 1;
  }
}

.tooltips[data-tool-tip]::before {
  text-align: center;
  content: attr(data-tool-tip);
  visibility: hidden;
  opacity: 0;
  min-width: 100%;
  width: max-content;
  height: max-content;
  position: absolute;
  box-sizing: border-box;
  display: block;
  margin: 0 auto;
  padding: 4px 8px;
  background-color: #5a5a5a;
  color: white;
  border-radius: 4px;
  font-size: 0.8em;
  font-weight: 500;
  white-space: nowrap;
  transition: opacity ease-out 150ms, bottom ease-out 150ms;
}

.tooltips[data-position='top']::before {
  bottom: 100%;
}

.tooltips[data-position='right']::before {
  left: 100%;
  top: 0;
  bottom: 0;
  margin: auto 0;
}

.tooltips[data-position='bottom']::before {
  top: 100%;
}

.tooltips[data-position='left']::before {
  right: 100%;
  top: 0;
  bottom: 0;
  margin: auto 0;
}


.tips {
  &-container {
    position: relative;
  }

  &-icon {
    cursor: pointer;
  }

  &-content {
    position: absolute;
    top: 1.2rem;
    background: #FFFFFF;
    border: 1px $dim-400 solid;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    display: none;
    z-index: 30;
    @include containerFadeAnimation;

    &.active {
      display: block;
    }

    //&::before,&::after {
    //  // layout
    //  content: '';
    //  position: absolute;
    //  width: 0;
    //  height: 0;
    //  bottom: 100%;
    //  left: 1.5em; // offset should move with padding of parent
    //  border-top: none;
    //
    //  // looks
    //  filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, .1));
    //}
    //&::before{
    //  border-bottom-color: $dim-400;
    //  border: .65rem solid transparent;
    //}
    //&::after{
    //  border-bottom-color: white;
    //  border: .75rem solid transparent;
    //}
  }

  &-body {
    padding: 0.825rem;
  }
}


[data-tooltip] {
  cursor: pointer;
  position: relative;

  &:before,
  &:after {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: all .15s cubic-bezier(.5, 1, .25, 1);
    z-index: 1;
  }

  &:before {
    padding: 5px;
    //width: 110px;
    border-radius: 3px;
    background: #000;
    color: #fff;
    content: attr(data-tooltip);
    text-align: center;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.2;
  }

  &:after {
    border: 8px solid transparent;
    width: 0;
    content: "";
    font-size: 0;
    line-height: 0;
  }

  /********** Default Behavior **********/
  @media (hover: hover) {
    &:hover:before,
    &:hover:after {
      visibility: visible;
      opacity: 1;
    }
  }


  /********** Tooltip Sizes **********/
  &.t-xl:before {
    width: 200px;
  }

  &.t-lg:before {
    width: 170px;
  }

  &.t-md:before {
    width: 140px;
  }

  &.t-sm:before {
    width: 110px;
  }

  &.t-xs:before {
    width: 80px;
  }

  &.t-no-wrap:before {
    white-space: nowrap;
  }


  /********** Tooltip Padding **********/
  &.p-x-3:before {
    padding-right: 1rem;
    padding-left: 1rem;
  }

  /********** Top Tooltip **********/
  &.t-top {
    &:before {
      bottom: 100%;
      left: 50%;
      margin-bottom: 5px;
      transform: translateX(-50%);
    }

    &:after {
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-top: 8px solid #000;
      border-bottom: none;
    }

    @media (hover: hover) {
      &:hover:before,
      &:hover:after {
        transform: translateX(-50%) translateY(-5px);
      }
    }
  }

  /********** Right Tooltip **********/
  &.t-right {
    &:before {
      top: 50%;
      left: 100%;
      margin-left: 5px;
      transform: translateY(-50%);
    }

    &:after {
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      border-right: 8px solid #000;
      border-left: none;
    }

    @media (hover: hover) {
      &:hover:before,
      &:hover:after {
        transform: translateX(5px) translateY(-50%);
      }
    }
  }

  /********** Bottom Tooltip **********/
  &.t-bottom {
    &:before {
      top: 100%;
      left: 50%;
      margin-top: 5px;
      transform: translateX(-50%);
    }

    &:after {
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      border-bottom: 8px solid #000;
      border-top: none;
    }

    @media (hover: hover) {
      &:hover:before,
      &:hover:after {
        transform: translateX(-50%) translateY(5px);
      }
    }
  }

  /********** Left Tooltip **********/
  &.t-left {
    &:before {
      top: 50%;
      right: 100%;
      margin-right: 5px;
      transform: translateY(-50%);
    }

    &:after {
      top: 50%;
      right: 100%;
      transform: translateY(-50%);
      border-left: 8px solid #000;
      border-right: none;
    }

    @media (hover: hover) {
      &:hover:before,
      &:hover:after {
        transform: translateX(-5px) translateY(-50%);
      }
    }
  }
}