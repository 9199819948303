@import "mixins";

$dropdown-padding-xxs: 0.3rem 0.5rem;
;
$dropdown-padding-xs: 0.3rem 0.5rem;
$dropdown-padding-sm: 0rem;
$dropdown-padding-md: 0rem;
$dropdown-padding-lg: 0rem;
$dropdown-padding-xl: 0rem;

@each $color, $value in $theme-colors {
  .dropdown-menu-#{$color},
  .dropdown-menu-outline-#{$color},
  .dropdown-menu-borderless-#{$color},
  .dropdown-menu-color-#{$color},
  .dropdown-menu-color-light-#{$color},
  .dropdown-menu-color-dark-#{$color},
  .dropdown-menu-transparent-#{$color},
  .dropdown-menu-hover-light-#{$color},
  .dropdown-menu-hover-dark-#{$color},
  .dropdown-menu-gray-#{$color},
  .dropdown-menu-plain-#{$color} {
    .dropdown-item {
      &:active {
        background-color: #{$value};
      }
    }
  }
}

@mixin cus-dropdown($size,$line-height,$padding) {
  font-size: #{$size};
  svg {
    width: #{$line-height}em;
    height: #{$line-height}em;
  }

  .dropdown-toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: #{$size};
    padding: #{$padding};
  }
  .dropdown-item {
    font-size: #{$size};
    padding: calc(#{$size} / 2);
  }
}

@mixin cus-dropdown-breakpoint($breakpoint) {
  @include media-breakpoint-down(#{$breakpoint}) {
    @include cus-dropdown($h6-font-size, $line-height-base, $dropdown-padding-md);
  }
}

.dropdown-item {
  @include nodrag;
}

.#{$dropdown-prefix} {
  &xxs {
    @include cus-dropdown($h9-font-size, $line-height-sm, $dropdown-padding-xxs);
  }

  &xs {
    @include cus-dropdown($h8-font-size, $line-height-sm, $dropdown-padding-xxs);
  }

  &sm {
    @include cus-dropdown($h7-font-size, $line-height-sm, $dropdown-padding-xxs);
  }

  &md {
    @include cus-dropdown($h6-font-size, $line-height-base, $dropdown-padding-xxs);
  }

  &lg {
    @include cus-dropdown($h5-font-size, $line-height-base, $dropdown-padding-xxs);
  }

  &breakpoint-xxs {
    @include cus-dropdown-breakpoint(xs);
  }

  &breakpoint-xs {
    @include cus-dropdown-breakpoint(xs);
  }

  &breakpoint-sm {
    @include cus-dropdown-breakpoint(sm);
  }

  &breakpoint-md {
    @include cus-dropdown-breakpoint(md);
  }

  &breakpoint-lg {
    @include cus-dropdown-breakpoint(lg);
  }
}

@mixin fibMoreCorrect {
  vertical-align: middle;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23008b53' class='bi bi-three-dots-vertical' viewBox='0 0 16 16'%3E%3Cpath d='M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z'/%3E%3C/svg%3E");
}

@mixin dropDownIcon {
  background-image: url("data:image/svg+xml,%3Csvg fill='currentColor' xmlns='http://www.w3.org/2000/svg' width='16' height='13.838' viewBox='0 0 16 13.838'%3E%3Cpath d='M15.78,12.753a1.529,1.529,0,0,1-1.311,2.314H1.53A1.529,1.529,0,0,1,.219,12.753L6.69,1.969a1.528,1.528,0,0,1,2.619,0L15.78,12.753Z' transform='translate(16 15.067) rotate(180)'/%3E%3C/svg%3E%0A") !important;
}

.cus-drop {
  //display: inline;
  //display: inline-flex;
  //display: inline-block;
  display: inline;
  position: relative;
  //margin-left: 0.5rem;
  //padding-left: 0.5rem;
  //line-height: 0;
  white-space: nowrap;
  //margin-left: 1rem;
  //width: calc(100% - 3rem);
  //width: 100%;
  padding-left: 1rem;
  margin-left: 0.5rem;

  &.otherOptions {
    padding-left: 0.325rem;
    margin-left: 0.325rem;

    &::after {
      display: none;
    }

    .item-drop {
      &:hover, &:active {
        cursor: auto;
        background: transparent !important;
      }
    }

    .control {
      &:after {
        content: "";
        display: inline-block;
        height: .5em;
        width: .5em;
        margin-left: .5em;
        @include dropDownIcon();
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        transition: transform .2s ease;
      }
    }
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    border-left: 1px #{$fib-correct} solid;
    height: 100%;
  }

  .selected-content {
    color: #{$body-color};
    //width: calc(100% - 3rem);
    min-width: 120px;
    overflow-y: hidden;
    overflow-x: auto;
    text-align: left;

    mjx-container {
      display: contents;
    }

    &:empty {
      &::after {
        content: "";
        position: relative;
      }
    }
  }

  input[type="checkbox"] {
    display: none;

    &:checked {
      ~ .drop-items {
        opacity: 1;
        max-height: 400px;
        padding: .6rem 0;
      }

      ~ .control {
        z-index: $z-index-cus-drop-down;

        &:after {
          //transform: rotate(180deg);

          transform: rotate(180deg) translateY(30%);

        }
      }

      ~ .overlay-close {
        transform: none;
        pointer-events: initial;
      }
    }
  }

  .control {
    position: relative;
    //display: inline-block;
    //display: flex;
    //display: inline-flex;
    //width: 100%;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-radius: 4px;
    transition: all .2s ease;
    overflow: hidden;
    //padding-left: 0.5rem;
    //padding-right: 0.5rem;

    &:empty {
      justify-content: end;
    }

    //@media (hover: hover) {
    //  &:hover {
    //    opacity: .75;
    //  }
    //}

    &:after {
      content: "";
      display: inline-block;
      height: 1em;
      width: 1em;
      //margin-left: .5em;
      //background-image: url("data:image/svg+xml,%3Csvg fill='%234eae84' xmlns='http://www.w3.org/2000/svg' width='16' height='13.838' viewBox='0 0 16 13.838'%3E%3Cpath d='M15.78,12.753a1.529,1.529,0,0,1-1.311,2.314H1.53A1.529,1.529,0,0,1,.219,12.753L6.69,1.969a1.528,1.528,0,0,1,2.619,0L15.78,12.753Z' transform='translate(16 15.067) rotate(180)'/%3E%3C/svg%3E%0A");
      @include fibMoreCorrect;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      transition: transform .2s ease;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .drop-items {
    cursor: pointer;
    position: absolute;
    max-width: calc(100% + 10vw);
    width: max-content;
    top: calc(100% + 1rem);
    //left: -2rem;
    padding: 0;
    margin: 0;
    transition: all .2s ease;
    background: #fff;
    box-shadow: 0 2px 10px rgb(0 0 0 / 61%);
    border-radius: 4px;
    overflow: auto;
    max-height: 0;
    opacity: 0;
    z-index: $z-index-cus-drop-down;
    line-height: 0;

    .dropdown-group-header {
      padding: 1rem;
      color: #555;
      font-size: 0.875rem;
      font-weight: 300;

      &:empty {
        display: none;
      }
    }

    ul {
      position: relative;
      margin-bottom: 0;
      padding: 0;
      text-align: left;
    }

    .item-drop {
      list-style: none;

      font-size: inherit;
      display: block;
      color: #555;
      text-decoration: none;
      padding: 0.5rem 1rem;
      transition: all .2s ease;
      line-height: normal;
      //white-space: pre-wrap;
      overflow: auto;
      //word-break: break-word;
      @include noselect;

      &.text-correct {
        color: #{$fib-correct};
      }

      &:hover, &:active {
        background: #eee;
      }

    }
  }

  .overlay-close {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9;
    transform: translate(100%, 0);
    pointer-events: none;
  }
}


//.nav-more {
//  button{
//    display: flex;
//    align-items: center;
//    height: 2.375rem;
//    &::after {
//      display: none;
//    }
//  }
//  svg {
//    height: 1.5rem
//  }
//
//  .dropdown-menu {
//    position: absolute;
//  }
//}
//
.no-arrow {
  .dropdown-toggle::after {
    display: none;
  }
}


//Simple dropdown
$color_1: #333;
$background-color_2: #e8e8e8;
$border-color_1: #555 transparent transparent transparent;
$border-color_2: transparent transparent #555 transparent;

.simple-dropdown {
  position: relative;
  display: inline-block;
  text-align: left;
  min-width: 130px;
  width: 100%;
  height: 34px;
  background: white;
  @include noselect;

  .dropdown-text {
    cursor: pointer;
    position: absolute;
    //text-indent: 10px;
    padding-left: 0.5rem;
    padding-right: 1rem;
    line-height: 32px;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-shadow: 0 1px 0 rgba(255, 255, 255, .9) inset, 0 1px 3px rgba(0, 0, 0, .1);
    width: 100%;
    color: $color_1;
    text-shadow: 0 1px #fff;

    &:after {
      position: absolute;
      right: 6px;
      top: 15px;
      content: '';
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 5px 4px 0 4px;
      border-color: $border-color_1;
    }
  }

  .dropdown-content {
    z-index: $z-index-simple-dropdown-content;
    //display: block;
    display: none;
    background-color: white;
    //&:hover {
    //  background: #e8e8e8;
    //}
    -webkit-transition: all .25s ease;
    -moz-transition: all .25s ease;
    -ms-transition: all .25s ease;
    -o-transition: all .25s ease;
    transition: all .25s ease;
    list-style-type: none;
    position: absolute;
    //padding: 0;
    margin: 0;
    opacity: 0;
    //visibility: hidden;
    border-radius: 3px;
    //text-indent: 10px;
    line-height: 32px;
    border: 1px solid #ccc;
    width: 100%;
    padding-left: 0;

    -webkit-box-shadow: 0 2px 2px rgb(10 16 20 / 24%), 0 0 2px rgb(10 16 20 / 12%);
    -moz-box-shadow: 0 2px 2px rgb(10 16 20 / 24%), 0 0 2px rgb(10 16 20 / 12%);
    box-shadow: 0 2px 2px rgb(10 16 20 / 24%), 0 0 2px rgb(10 16 20 / 12%);

    &.top {
      bottom: 100%;
    }

    &.bottom {
      top: 32px;
    }

    li {
      width: 100%;
      //padding-left: 0.5rem;
      //padding-right: 0.5rem;
      background-color: white;
      padding: 0.325rem 0.5rem;

      &:not(:last-child) {
        //@include media-breakpoint-down(md) {
        border-bottom: 1px #{$dim} dashed;
        //}
      }

      &:hover {
        background-color: #{$dim-100};
        @include btnAnimation;
      }
    }

    &:hover {
      //opacity: 1;
      //visibility: visible;
      //top: 42px;
      display: block;
    }
  }

  .dropdown-toggle {
    //font-size: 0;
    z-index: 1;
    cursor: pointer;
    position: absolute;
    top: 0;
    border: none;
    padding: 0;
    margin: 0 0 0 1px;
    background: transparent;
    //text-indent: -10px;
    height: 34px;
    width: 100%;

    &.focus {
      outline: 0;

      ~ {
        .dropdown-text {
          background-color: $background-color_2;
          box-shadow: 0 1px 3px rgba(0, 0, 0, .2) inset, 0 1px 0 rgba(255, 255, 255, 0.8);
          z-index: 2;

          &:after {
            border-width: 0 4px 5px 4px;
            border-color: $border-color_2;
          }
        }

        .dropdown-content {
          opacity: 1;
          //visibility: visible;
          display: block;

          &.bottom {
            top: 42px;
          }

          &.top {
            bottom: calc(100% + 0.5rem);
          }
        }
      }
    }

    &:hover {
      ~ {
        .dropdown-text {
          background-color: $background-color_2;
        }
      }
    }
  }
}

.dropdown-exercise-subject-theme, .dropdown-library-subject-theme {
  width: 400px;

  li {
    padding: 0.325rem;

    &:not(:last-child) {
      //@include media-breakpoint-down(md) {
      border-bottom: 1px #{$dim} dashed;
      //}
    }
  }

  @include media-breakpoint-down(md) {
    width: 100%;
  }

  .dropdown-content {
    @include media-breakpoint-down(md) {
      min-width: 300px;
      width: fit-content;
      right: 0;
    }
  }
}

.dropdown-exercise-subject-theme {
  .dropdown-content {
    @include media-breakpoint-down(md) {
      min-width: 100%;
      left: 0;
      right: unset;
    }
  }
}

.dropdown-exercise-tab-more-theme {
  min-width: 0;
  width: 2.5rem;
  height: 1.5rem;
  background-color: transparent;

  .svg-icon, svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  .dropdown-text {
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    &:after {
      display: none;
    }
  }

  .dropdown-content {
    right: 0;
    width: 300px;
  }
}


//// Clone from simple-dropdown
//.shared-general-more-dropdown {
//  position: relative;
//  width: 40px;
//  height: 40px;
//
//  @include noselect;
//
//  .dropdown-text {
//    cursor: pointer;
//    position: absolute;
//  }
//
//  .dropdown-content {
//    z-index: 10;
//    display: none;
//    background-color: white;
//
//    -webkit-transition: all .25s ease;
//    -moz-transition: all .25s ease;
//    -ms-transition: all .25s ease;
//    -o-transition: all .25s ease;
//    transition: all .25s ease;
//    list-style-type: none;
//    position: absolute;
//    margin: 0;
//    opacity: 0;
//    border-radius: 3px;
//    line-height: 32px;
//    border: 1px solid #ccc;
//    width: fit-content;
//    z-index: 100;
//    padding-left: 0;
//
//    li {
//      width: 100%;
//      background-color: white;
//      padding: 0.325rem 0.5rem;
//
//      &:not(:last-child) {
//        border-bottom: 1px #{$dim} dashed;
//      }
//
//      &:hover {
//        background-color: #{$dim-100};
//        @include btnAnimation;
//      }
//    }
//
//    &:hover {
//      display: block;
//    }
//  }
//
//  .dropdown-toggle {
//    //font-size: 0;
//    z-index: 1;
//    cursor: pointer;
//    position: absolute;
//    top: 0;
//    left: 0;
//    right: 0;
//    bottom: 0;
//    border: none;
//    padding: 0;
//    margin: 0 0 0 1px;
//    background: transparent;
//    //text-indent: -10px;
//    height: inherit;
//    width: inherit;
//
//    &:focus {
//      outline: 0;
//
//      ~ {
//        .dropdown-text {
//          box-shadow: 0 1px 3px rgba(0, 0, 0, .2) inset, 0 1px 0 rgba(255, 255, 255, 0.8);
//          z-index: 2;
//
//          &:after {
//            border-width: 0 4px 5px 4px;
//            border-color: $border-color_2;
//          }
//        }
//
//        .dropdown-content {
//          opacity: 1;
//          display: block;
//        }
//      }
//    }
//  }
//}

.more-dropdown {
  display: flex;
  width: 28px;
  height: 28px;
  min-width: unset;
  background-color: transparent;

  .dropdown-text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    padding: 0;
    color: #{$exercise-third-btn};
    background-color: transparent; //#{$exercise-third-btn-hover-light};
    box-shadow: none; //0px 0px 4px rgb(0 0 0 / 25%);
    border: none;
    font-size: 1rem;
    //&:hover{
    //  background-color: #{$exercise-third-btn};
    //  color: white;
    //}
    &:after {
      display: none;
    }

    .svg-icon {
      width: 1rem;
      height: 1rem;
    }

    svg {
      width: 1rem;
      height: 1rem;
    }
  }

  .dropdown-toggle {

    &.focus {
      outline: 0;

      ~ {
        .dropdown-text {
          background-color: transparent; // #{$exercise-third-btn-hover-light};
        }
      }
    }

    &:hover {
      ~ {
        .dropdown-text {
          background-color: transparent; //#{$exercise-third-btn-hover-light};
        }
      }
    }
  }

  .dropdown-content {
    width: 200px;
    right: 0;
    top: 40px;
    @include containerFadeAnimation;

    li {
      border: none !important;

      &.group {
        border-bottom: 1px #e2e2e2 solid !important;
      }

      &.disable {
        color: #{$dim-350};

        &:hover {
          background-color: transparent !important;
        }
      }
    }
  }
}


.switchProgress {
  position: static;
  display: inline-block;
}

.switchProgress > input[type="checkbox"] {
  position: absolute;
  left: -100vw;
}

.switchProgress > label,
.switchProgress > a[role="button"] {
  display: inline-block;
  padding: 6px;
  color: #{$dim-400};
  line-height: 1.5em;
  text-decoration: none;
  cursor: pointer;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;

  &:hover {
    background-color: #{$dim-100};
  }
}


.switchProgress > span {
  position: absolute;
  display: block;
  left: -100vw;
  top: calc(1.5em + 14px);
  width: 100%;
}

.switchProgress > input[type="checkbox"]:checked ~ span,
.switchProgress > span:target {
  left: 0;
}


.report-max-options-width {
  width: inherit;
  max-width: 100%;
}

//Report
.option {
  &-subject {
    width: 420px;
    @extend .report-max-options-width;
  }

  &-year-class {
    width: 280px;
    @extend .report-max-options-width;
  }

  &-year {
    width: 200px;
    @extend .report-max-options-width;
  }

  &-grade {
    width: 200px;
    @extend .report-max-options-width;
  }

  &-class {
    width: 200px;
    @extend .report-max-options-width;
  }

  &-student {
    width: 280px;
    @extend .report-max-options-width;
  }

  &-report {
    width: 420px;
    @extend .report-max-options-width;
  }

  &-metadata {
    width: 420px;
    @extend .report-max-options-width;
  }

  &-period {
    width: 200px;
    @extend .report-max-options-width;
  }

  &-progress-by-month {
    width: 200px;
    @extend .report-max-options-width;
  }
}