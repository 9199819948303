.dashboard {
  background-color: #{$dim-400};

  .navbar {
    background-color: #{$dim-200};
  }

  .sub-navbar {
    background-color: #{$dim-200};
  }
}

.dashboard-container {
  display: flex;
  gap: 1rem;
  @include media-breakpoint-down(md) {
    flex-direction: column;
  }

  .dashboard-left {
    width: 40%;

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  .dashboard-student-left {
    width: 30%;

    @include media-breakpoint-down(xl) {
      width: 100%;
    }
  }

  .dashboard-right {
    width: 60%;

    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }


  .dashboard-student-right {
    width: 70%;

    @include media-breakpoint-down(xl) {
      width: 100%;
    }
  }

  .subject-overview-container {
    flex-direction: column;
    align-items: center;
    @include media-breakpoint-down(xl) {
      flex-direction: row;
      align-items: start;
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      align-items: center;
    }
  }
}