.breadcrumb {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;

  .breadcrumb-item {
    display: inline;

    a {
      text-decoration: none;
    }

    + .breadcrumb-item::before {
      float: none;
    }

    @include media-breakpoint-down(lg) {
      &:not(:nth-last-child(-n+2)) {
        display: none;

        + .breadcrumb-item::before {
          content: "" !important;
        }
      }
      &:nth-last-child(2) {
        padding-left: 0;

        &::before {
          padding-right: 0;
        }
      }
    }

    @include media-breakpoint-down(md) {
      padding-left: 0;
      &:not(:nth-last-child(-n+1)) {
        display: none;
      }
      + .breadcrumb-item::before {
        content: "" !important;
        padding-right: 0;
      }
    }
  }
}