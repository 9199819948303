//.side-from-bottom {
//  &.fade {
//    .modal-dialog {
//      transform: translate(0, 100vh) !important;
//    }
//  }
//}

.side-from-bottom.fade .modal-dialog {
  transition: -webkit-transform .2s ease-out;
  transition: transform .2s ease-out;
  transition: transform .2s ease-out, -webkit-transform .3s ease-out;
  -webkit-transform: translateY(100vh);
  transform: translateY(100vh);
}

.side-from-bottom.show .modal-dialog {
  -webkit-transform: none;
  transform: none;
}

//Fix two modal
.modal {
  + .modal-backdrop {
    z-index: 1055;
  }
}

//Loading
.loading-modal {
  justify-content: center;

  .modal-content {
    width: auto;

    .loading-animate {
      width: 60px;
      height: 60px;
    }
  }
}

//Upload background
.bg-media-upload {
  position: fixed;
  bottom: 0.5rem;
  right: 0.5rem;
  left: auto;
  top: auto;
  width: auto;
  height: auto;
  display: flex !important;
  align-items: flex-end;
  justify-content: flex-end;
  border: 1px $dim-350 solid;
  border-radius: 0.375rem !important;
  overflow: hidden;

  .modal-dialog {
    transition: none !important;
    margin: unset;
  }

  .upload-progress-bar-container {
    width: 80px;
  }
}

.upload-progress-bar-container {
  display: block;
  width: 350px;
  max-width: 100%;
}