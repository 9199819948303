@media screen and (min-width: 1020px) {
    .cpMediaUIRow2 {flex-direction: row;}
    .cpMediaUIRow3 {width:45%; margin: 0;}

    .LDCanvasAnsTop { flex-direction: row; }
    .LDCanvasLayer {min-width: 70%; max-width: 70%; overflow: auto;}
    .LDAnsLayer { align-self: flex-start; min-width:30%; max-width:30%; height:100%; padding: 5px; overflow: auto;}
    .LDResultBottom {display:flex}
    .LDAnsBlockAns {display:none}
    .qtnOPDoRListContainer {
        display: grid; grid-column-gap: 0.5%; grid-template-columns: 33% 33% 33%; padding: 0px; 
        width: 100%; max-width: 100%;
    }

    .ansDetailHead { flex-direction: row; justify-content: space-between; }
    
    .ansDetail1 { justify-content: flex-start; width:auto; }
    .ansDetailScore { width: auto }
    .aDscoreType1 { display: none }
    
    .ansDetail2 { justify-content: flex-end; width:auto; margin-top:0;}
    .aDscoreType2 { display: flex } 
}