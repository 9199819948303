.demo-table {
  width: 100%;

  td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width: 40%;

    &:nth-child(-n+2) {
      min-width: 10%;
    }
  }
}

.semi-bold {
  font-weight: 500;

  * {
    font-weight: 500;
  }
}

.bold {
  font-weight: 600;

  * {
    font-weight: 600;
  }
}

.demo-typography {
}