.tab-to-dropdown {
  overflow-x: auto;
  font-size: #{$fs-5-size};

  .dropdown-item {
    .svg-icon {
      display: none;
    }
  }

  .tab-item {
    svg {
      width: #{$fs-5-size};
      height: #{$fs-5-size};
    }

    @include media-breakpoint-down(md) {
      font-size: #{$fs-6-size};
      svg {
        width: #{$fs-6-size};
        height: #{$fs-6-size};
      }
    }

    &.active {
      border-bottom: 0.225rem #{$sidenav-primary} solid;
    }
  }
}


.setting-tab-item {
  //border-bottom: 2px #{$dim-350} solid;

  &.active {
    &:after {
      position: absolute;
      width: 100%;
      content: "";
      bottom: -2px;
      left: 0;
      border-bottom: 2px #{$exercise-third-btn} solid;
      @include btnAnimation;
    }
  }
}

.exercise-edit-tab {
  .tab-item {
    color: #{$dim-350};
    border-bottom: 2px #{$dim-350} solid;

    &.active {
      color: #{$exercise-third-btn};
      border-bottom: 2px #{$exercise-third-btn} solid;
    }
  }
}