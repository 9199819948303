$body-min-width: 375;
body {
  min-width: #{$body-min-width}px;
  //overflow: hidden;
}

.main-container {
  //overflow: auto;
  //padding-bottom: 3rem;
}

.vh-50 {
  height: 50vh;
}

.h-0 {
  height: 0;
}

.overflow-y-md-hidden {
  @include media-breakpoint-up(md) {
    overflow: hidden !important;
  }
}

.overflow-y-md-auto {
  overflow: auto !important;
  @include media-breakpoint-down(md) {
    overflow: unset !important;
  }
}

.overflow-x {
  overflow-x: auto;
}

//mjx-container{
//  font-size: 100%!important;
//}