/**** adjust scroll-to-top position, for page exer open,edit ****/
@media screen and (min-width: 550px) {
    .pageExerEdit .scroll-to-top { margin: 0.8rem 1.6rem 0.8rem 1.6rem !important; } 
}


/*** for draw layer poc tool bar use***/
@media screen and (min-width: 768px) {
    .qtnOPDoDrawCont {flex-direction:row; justify-content: center; /*space-between;*/
        gap:5px; margin-bottom: 5px;}
    .DrawCanvasBtnBarHori {display: none;}
    .DrawCanvasBtnBarVert {display: flex;}
    .DrawCanvasBtnDL {padding:18px 8px; margin-left: 5px; margin-top:0px}
    .drawOverlaySticky { padding-top:8px; height: 60px; max-width: 800px; }
    .drawOverlayExtra { min-height: 70px;}
    .drawOverlayBarEx { max-width: 800px; }
    .rlpEx { padding:0 10px; }
    .btn768Show { display: flex; flex-shrink: 1; }
    .btn768Hide { display: none; }
    .workFollowName { font-size: 28px !important; }
}