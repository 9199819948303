// Prevent content out of the box
.prevention-area {
  word-break: break-word; //Text
  *:not(.ck) {
    //overflow-x: hidden;
    //overflow-y: scroll;
  }

  table, td, td, img, media, audio, iframe, div, span {
    max-width: 100%; //Table, Img, Media
  }
}