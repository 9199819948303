@media screen and (min-width: 1270px) {
    /*.qtnOPDoRListItem { width:30% }*/
    .cpMediaUIContainer { flex-direction: row;}
    .cpMediaUIRow.vis {border-radius: 6px 0 0 6px;}
    .cpMediaUIRow { width:50%; border-radius: 6px 0 0 6px;} 
    .cpMediaUIRow2 { flex-direction:row; width:50%; border-radius: 0 6px 6px 0;} 
    .cpMediaUIRow3{width:45%}

    .cpMediaUI1 {display:none; width:100%}
    .cpMediaUI2 {display:flex; width:100%}    

    .ATBody { padding-left:244px; }
    /*.ATLeftBar {display:flex}*/
    .ATLeftBar[vis='Y'] {display:flex; z-index: 1;}
    .ATLeftBar[vis='N'] {display:none}    
    .ATLeftBarFlowTop, .QtnOptionsFlowTop, .ExOptionsFlowTop {display:none;}
    .QtnOptionsBtn, .ExOptionsBtn {display:none}
    .QtnOptionsTop, .ExOptionsTop {display:flex;}

    .navsidebackR {display: none !important}
    .navsideR {display: none !important}

    .tabQContainer {padding-top: 0px;}    
    .ViewExCtnItemQtn {padding-top: 60px;}
    .ViewExCtnItem {padding: 0px 50px 5px 50px; margin-top: 50px;}
    .ViewExCtnItem2 {margin-top: 0px;}  

    .LDToolBtn { flex-direction: column }
    .LDMiniTxt { display:flex }
}