@import "mixins";

@mixin answerShadow {
  box-shadow: 0px 4px 4px $shadow;
}

@mixin selectedAnswer {
  .selected {
    .answer-border {
      &::after {
        @include answerShadow;
        border: 2px #{$mcq-active} solid;
      }
    }

    .answer-indicator {
      background-color: #{$mcq-active};
      color: white;
    }
  }
}

@mixin correctAnswer {
  .correct {

    //Show the correct no matter selected or not
    img, svg, video, audio {
      filter: grayscale(0);
    }

    &::after {
      opacity: 1;
    }

    &.selected {
      .answer-border {
        &::after {
          @include answerShadow;
        }
      }
    }

    &:not(.selected) {
      .answer-border {
        &::after {
          border: 2px #{$mcq-correct} dashed;
        }

        //&::before{
        //  background-image: none;
        //}
      }

      &.label-mode {
        .answer-indicator {
          background-color: white;
          color: #{$mcq-correct};
        }
      }
    }

    .answer-border {
      &::after {
        border: 2px #{$mcq-correct} solid;
      }
    }

    .answer-indicator {
      background-color: #{$mcq-correct};
      color: white;
    }
  }
}

@mixin incorrectAnswer {
  .incorrect {
    &.selected {
      //Only shows the incorrect on selected options
      img, svg, video, audio {
        filter: grayscale(0);
      }

      .answer-border {
        &::after {
          @include answerShadow;
          border: 2px #{$mcq-incorrect} solid;
        }

        //&::before{
        //  content:'';
        //  position: absolute;
        //  top: 11px;
        //  right: -11px;
        //  z-index: 1;
        //  background-image: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_304_4032)'%3E%3Cpath d='M11.2 22.4C17.3856 22.4 22.4 17.3856 22.4 11.2C22.4 5.01441 17.3856 0 11.2 0C5.01441 0 0 5.01441 0 11.2C0 17.3856 5.01441 22.4 11.2 22.4Z' fill='%23EB8A7C'/%3E%3Cpath d='M5.25094 17.15C4.78094 16.68 4.78094 15.92 5.25094 15.45L15.4509 5.24996C15.9209 4.77996 16.6809 4.77996 17.1509 5.24996C17.6209 5.71996 17.6209 6.47996 17.1509 6.94996L6.95094 17.15C6.48094 17.62 5.72094 17.62 5.25094 17.15Z' fill='white'/%3E%3Cpath d='M15.4509 17.15L5.25094 6.94996C4.78094 6.47996 4.78094 5.71996 5.25094 5.24996C5.72094 4.77996 6.48094 4.77996 6.95094 5.24996L17.1509 15.45C17.6209 15.92 17.6209 16.68 17.1509 17.15C16.6809 17.62 15.9209 17.62 15.4509 17.15Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_304_4032'%3E%3Crect width='22.4' height='22.4' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
        //  width: 23px;
        //  height: 23px;
        //}
      }

      .answer-indicator {
        background-color: #{$mcq-incorrect};
      }
    }
  }
}

@mixin attemptedAnswer {
  .attempted {
    &.selected {
      //Only shows the incorrect on selected options
      img, svg, video, audio {
        filter: grayscale(0);
      }

      .answer-border {
        &::after {
          @include answerShadow;
          border: 2px #{$mcq-attempted} solid;
        }

        //&::before{
        //  content:'';
        //  position: absolute;
        //  top: 11px;
        //  right: -11px;
        //  z-index: 1;
        //  background-image: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_304_4032)'%3E%3Cpath d='M11.2 22.4C17.3856 22.4 22.4 17.3856 22.4 11.2C22.4 5.01441 17.3856 0 11.2 0C5.01441 0 0 5.01441 0 11.2C0 17.3856 5.01441 22.4 11.2 22.4Z' fill='%23EB8A7C'/%3E%3Cpath d='M5.25094 17.15C4.78094 16.68 4.78094 15.92 5.25094 15.45L15.4509 5.24996C15.9209 4.77996 16.6809 4.77996 17.1509 5.24996C17.6209 5.71996 17.6209 6.47996 17.1509 6.94996L6.95094 17.15C6.48094 17.62 5.72094 17.62 5.25094 17.15Z' fill='white'/%3E%3Cpath d='M15.4509 17.15L5.25094 6.94996C4.78094 6.47996 4.78094 5.71996 5.25094 5.24996C5.72094 4.77996 6.48094 4.77996 6.95094 5.24996L17.1509 15.45C17.6209 15.92 17.6209 16.68 17.1509 17.15C16.6809 17.62 15.9209 17.62 15.4509 17.15Z' fill='white'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_304_4032'%3E%3Crect width='22.4' height='22.4' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
        //  width: 23px;
        //  height: 23px;
        //}
      }

      .answer-indicator {
        background-color: #{$mcq-attempted};
      }
    }
  }
}

// Question Type - preview
//MCQ,Polling
.mcq, .polling {
  //List, Grid, Label
  .answer-container {
    &::after {
      content: "";
      opacity: 0;
      animation: all 0.3 ease-in-out;

      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;

      border-radius: 0.5rem;
    }
  }

  .answer-border {
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 0.5rem;
    }
  }


  .answer-indicator {
    background-color: #{$mcq-default};
    color: white;
  }

  .label-mode {
    .answer-border {
      &::after {
        border: 2px #{$mcq-default} solid;
      }
    }

    .answer-indicator {
      background-color: white;
      color: #{$mcq-active};
    }
  }

  @include selectedAnswer;


  //Table
  .mcq-table {
    .checkbox-theme-answer-select-table {
      //$color, $bgColor, $borderColor , $checkedColor, $checkedBgColor, $checkedBorderColor
      @include cus-input-checkbox-colortheme(transparent, transparent, $checkbox-dim, transparent, $mcq-active, $mcq-active);
    }


    //Table(MC)
    &.table-mc {
      .indicator {
        padding: 10px;
        position: relative;
        border-radius: 0.25rem;
        overflow: hidden;
        color: #{$mcq-active};
        white-space: nowrap;

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 2px #{$mcq-default} solid;
          border-radius: 0.25rem;
        }


        &.selected {
          color: white;
          background-color: #{$mcq-active};

          &::after {
            @include answerShadow();
            border: 2px #{$mcq-active} solid;
          }

          &.correct {
            &::after {
              border: 2px #{$mcq-correct} solid;
            }


            color: white;
            background-color: #{$mcq-correct};

          }

          &.incorrect {
            &::after {
              border: 2px #{$mcq-incorrect} solid;
            }


            color: white;
            background-color: #{$mcq-incorrect};

          }
        }
      }
    }
  }
}

@mixin fibAnswerIndicator {
  content: "";
  color: white;
  display: inline-block;

  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;


  height: 1.5em;
  width: 1.5em;
  margin-left: 0.825em;
  background-color: white;
  border-radius: 50%;
  background-size: 70%;
  background-position: center;
  margin-bottom: 0.25em;
}

@mixin fibCorrectAnswer {

  vertical-align: middle;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg width='100%25' height='100%25' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd%3Bclip-rule:evenodd%3Bstroke-linejoin:round%3Bstroke-miterlimit:2%3B'%3E%3Cg transform='matrix(1.38679 0 0 1.38679 -3.44122 -2.40095)'%3E%3Cpath d='M13.854 3.646C13.948 3.74 14.001 3.867 14.001 4C14.001 4.133 13.948 4.26 13.854 4.354L6.854 11.354C6.76 11.448 6.633 11.501 6.5 11.501C6.367 11.501 6.24 11.448 6.146 11.354L2.646 7.854C2.552 7.76 2.499 7.633 2.499 7.5C2.499 7.225 2.725 6.999 3 6.999C3.133 6.999 3.26 7.052 3.354 7.146L6.5 10.293L13.146 3.646C13.24 3.552 13.367 3.499 13.5 3.499C13.633 3.499 13.76 3.552 13.854 3.646Z' style='fill-rule:nonzero%3B' fill='%234eae84'/%3E%3C/g%3E%3C/svg%3E");

}

@mixin fibIncorrectAnswer {

  vertical-align: middle;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg width='100%25' height='100%25' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd%3Bclip-rule:evenodd%3Bstroke-linejoin:round%3Bstroke-miterlimit:2%3B'%3E%3Cg transform='matrix(1.60092 0 0 1.58537 -4.80719 -4.68274)'%3E%3Cpath d='M4.646 4.646C4.74 4.552 4.867 4.499 5 4.499C5.133 4.499 5.26 4.552 5.354 4.646L8 7.293L10.646 4.646C10.74 4.552 10.867 4.499 11 4.499C11.275 4.499 11.501 4.725 11.501 5C11.501 5.133 11.448 5.26 11.354 5.354L8.707 8L11.354 10.646C11.448 10.74 11.501 10.867 11.501 11C11.501 11.275 11.275 11.501 11 11.501C10.867 11.501 10.74 11.448 10.646 11.354L8 8.707L5.354 11.354C5.26 11.448 5.133 11.501 5 11.501C4.725 11.501 4.499 11.275 4.499 11C4.499 10.867 4.552 10.74 4.646 10.646L7.293 8L4.646 5.354C4.552 5.26 4.499 5.133 4.499 5C4.499 4.867 4.552 4.74 4.646 4.646Z' style='fill-rule:nonzero%3B' fill='%23e94929'/%3E%3C/g%3E%3C/svg%3E");

}

@mixin fibAttemptedAnswer {

  vertical-align: middle;

  //icon attempted
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='16' fill='none' viewBox='0 0 20 16'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath fill='%233a6bb9' d='m6.37 12.18.67.9-1.56 2.66c-.09.15-.23.25-.41.26h-.03c-.16 0-.31-.08-.41-.21L.11 9.8a.526.526 0 0 1 .09-.74c.22-.18.54-.14.72.09l4.07 5.39 1.38-2.36ZM14.24.79l-5.56 9.48-.67-.89L13.36.26c.15-.25.46-.33.7-.18.24.15.32.47.18.72V.79Z'/%3E%3Cpath fill='%233a6bb9' d='M10.73 16c-.16 0-.31-.07-.41-.2L5.79 9.81a.545.545 0 0 1 .09-.74c.22-.18.55-.14.72.09l4.07 5.38L19.05.25c.15-.25.46-.33.7-.18.24.15.32.47.18.72l-8.76 14.95c-.09.15-.24.24-.41.25h-.03V16Z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%233a6bb9' d='M0 0h20v16H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");

  //background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath fill='%233a6bb9' d='M15.45 1.44c.19.2.19.51 0 .71l-1.04 1.04-2-2L13.45.15c.2-.2.51-.2.71 0l1.29 1.29ZM13.7 3.9l-2-2-6.81 6.81c-.06.06-.1.12-.12.2l-.8 2.41c-.04.13.03.27.16.32.05.02.11.02.16 0l2.41-.81c.07-.02.14-.07.2-.12l6.8-6.81Z'/%3E%3Cpath stroke='%233a6bb9' stroke-linecap='round' stroke-linejoin='round' d='M15 6.96v6.48c0 1.01-.81 1.83-1.81 1.83H2.31c-1 0-1.81-.82-1.81-1.83V2.5C.5 1.49 1.31.67 2.31.67h6.44'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath  d='M0 0h15.6v15.77H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  //background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='17' height='17' fill='none' viewBox='0 0 17 17'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath fill='%231C1C1B' d='m4.86 12.47.64 1.11-1.03 2.17c-.09.18-.26.29-.46.3h-.02c-.19 0-.36-.1-.46-.26L.07 9.77a.526.526 0 0 1 .19-.72c.25-.14.58-.06.72.2l2.97 5.14.91-1.92Zm1.72-1.14-.56 1.15-.63-1.1.55-1.16.64 1.11ZM11.61.75 7.1 10.23l-.64-1.1L10.66.3c.12-.27.43-.38.7-.25.26.12.37.44.25.7Z'/%3E%3Cpath fill='%231C1C1B' d='m16 .75-7.14 15a.52.52 0 0 1-.45.3h-.02c-.19 0-.37-.1-.46-.26l-1.91-3.31-.63-1.1-.93-1.61a.529.529 0 0 1 .92-.52l.56.97.64 1.11 1.76 3.06L15.05.3c.12-.27.44-.38.7-.25.26.12.38.44.25.7Z'/%3E%3Cpath fill='%231C1C1B' d='m16 .75-7.14 15a.52.52 0 0 1-.45.3h-.02c-.19 0-.37-.1-.46-.26l-1.91-3.31-.63-1.1-.93-1.61a.529.529 0 0 1 .92-.52l.56.97.64 1.11 1.76 3.06L15.05.3c.12-.27.44-.38.7-.25.26.12.38.44.25.7Z'/%3E%3Cpath fill='%231C1C1B' d='m16 .75-7.14 15a.52.52 0 0 1-.45.3h-.02c-.19 0-.37-.1-.46-.26l-1.91-3.31-.63-1.1-.93-1.61a.529.529 0 0 1 .92-.52l.56.97.64 1.11 1.76 3.06L15.05.3c.12-.27.44-.38.7-.25.26.12.38.44.25.7Z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h16.06v16.05H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
}

@mixin fibCorrectAnswerChecked {

  vertical-align: middle;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg width='100%25' height='100%25' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd%3Bclip-rule:evenodd%3Bstroke-linejoin:round%3Bstroke-miterlimit:2%3B'%3E%3Cg transform='matrix(1.38679 0 0 1.38679 -3.44122 -2.40095)'%3E%3Cpath d='M13.854 3.646C13.948 3.74 14.001 3.867 14.001 4C14.001 4.133 13.948 4.26 13.854 4.354L6.854 11.354C6.76 11.448 6.633 11.501 6.5 11.501C6.367 11.501 6.24 11.448 6.146 11.354L2.646 7.854C2.552 7.76 2.499 7.633 2.499 7.5C2.499 7.225 2.725 6.999 3 6.999C3.133 6.999 3.26 7.052 3.354 7.146L6.5 10.293L13.146 3.646C13.24 3.552 13.367 3.499 13.5 3.499C13.633 3.499 13.76 3.552 13.854 3.646Z' style='fill-rule:nonzero%3B' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");

}

@mixin fibIncorrectAnswerChecked {

  vertical-align: middle;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg width='100%25' height='100%25' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd%3Bclip-rule:evenodd%3Bstroke-linejoin:round%3Bstroke-miterlimit:2%3B'%3E%3Cg transform='matrix(1.60092 0 0 1.58537 -4.80719 -4.68274)'%3E%3Cpath d='M4.646 4.646C4.74 4.552 4.867 4.499 5 4.499C5.133 4.499 5.26 4.552 5.354 4.646L8 7.293L10.646 4.646C10.74 4.552 10.867 4.499 11 4.499C11.275 4.499 11.501 4.725 11.501 5C11.501 5.133 11.448 5.26 11.354 5.354L8.707 8L11.354 10.646C11.448 10.74 11.501 10.867 11.501 11C11.501 11.275 11.275 11.501 11 11.501C10.867 11.501 10.74 11.448 10.646 11.354L8 8.707L5.354 11.354C5.26 11.448 5.133 11.501 5 11.501C4.725 11.501 4.499 11.275 4.499 11C4.499 10.867 4.552 10.74 4.646 10.646L7.293 8L4.646 5.354C4.552 5.26 4.499 5.133 4.499 5C4.499 4.867 4.552 4.74 4.646 4.646Z' style='fill-rule:nonzero%3B' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");

}

@mixin fibAttemptedAnswerChecked {
  //icon attempted
  vertical-align: middle;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='16' fill='none' viewBox='0 0 20 16'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath fill='%23fff' d='m6.37 12.18.67.9-1.56 2.66c-.09.15-.23.25-.41.26h-.03c-.16 0-.31-.08-.41-.21L.11 9.8a.526.526 0 0 1 .09-.74c.22-.18.54-.14.72.09l4.07 5.39 1.38-2.36ZM14.24.79l-5.56 9.48-.67-.89L13.36.26c.15-.25.46-.33.7-.18.24.15.32.47.18.72V.79Z'/%3E%3Cpath fill='%23fff' d='M10.73 16c-.16 0-.31-.07-.41-.2L5.79 9.81a.545.545 0 0 1 .09-.74c.22-.18.55-.14.72.09l4.07 5.38L19.05.25c.15-.25.46-.33.7-.18.24.15.32.47.18.72l-8.76 14.95c-.09.15-.24.24-.41.25h-.03V16Z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath fill='%23fff' d='M0 0h20v16H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  //background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'%3E%3Cg clip-path='url(%23a)'%3E%3Cpath fill='%23fff' d='M15.45 1.44c.19.2.19.51 0 .71l-1.04 1.04-2-2L13.45.15c.2-.2.51-.2.71 0l1.29 1.29ZM13.7 3.9l-2-2-6.81 6.81c-.06.06-.1.12-.12.2l-.8 2.41c-.04.13.03.27.16.32.05.02.11.02.16 0l2.41-.81c.07-.02.14-.07.2-.12l6.8-6.81Z'/%3E%3Cpath stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' d='M15 6.96v6.48c0 1.01-.81 1.83-1.81 1.83H2.31c-1 0-1.81-.82-1.81-1.83V2.5C.5 1.49 1.31.67 2.31.67h6.44'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='a'%3E%3Cpath  d='M0 0h15.6v15.77H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");

}

@mixin fibAttemptedCorrectAnswer {

  vertical-align: middle;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg width='100%25' height='100%25' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd%3Bclip-rule:evenodd%3Bstroke-linejoin:round%3Bstroke-miterlimit:2%3B'%3E%3Cg transform='matrix(1.38679 0 0 1.38679 -3.44122 -2.40095)'%3E%3Cpath d='M13.854 3.646C13.948 3.74 14.001 3.867 14.001 4C14.001 4.133 13.948 4.26 13.854 4.354L6.854 11.354C6.76 11.448 6.633 11.501 6.5 11.501C6.367 11.501 6.24 11.448 6.146 11.354L2.646 7.854C2.552 7.76 2.499 7.633 2.499 7.5C2.499 7.225 2.725 6.999 3 6.999C3.133 6.999 3.26 7.052 3.354 7.146L6.5 10.293L13.146 3.646C13.24 3.552 13.367 3.499 13.5 3.499C13.633 3.499 13.76 3.552 13.854 3.646Z' style='fill-rule:nonzero%3B' fill='%233a6bb9'/%3E%3C/g%3E%3C/svg%3E");

}


@mixin fibAttemptedIncorrectAnswer {

  vertical-align: middle;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg width='100%25' height='100%25' viewBox='0 0 16 16' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xml:space='preserve' xmlns:serif='http://www.serif.com/' style='fill-rule:evenodd%3Bclip-rule:evenodd%3Bstroke-linejoin:round%3Bstroke-miterlimit:2%3B'%3E%3Cg transform='matrix(1.60092 0 0 1.58537 -4.80719 -4.68274)'%3E%3Cpath d='M4.646 4.646C4.74 4.552 4.867 4.499 5 4.499C5.133 4.499 5.26 4.552 5.354 4.646L8 7.293L10.646 4.646C10.74 4.552 10.867 4.499 11 4.499C11.275 4.499 11.501 4.725 11.501 5C11.501 5.133 11.448 5.26 11.354 5.354L8.707 8L11.354 10.646C11.448 10.74 11.501 10.867 11.501 11C11.501 11.275 11.275 11.501 11 11.501C10.867 11.501 10.74 11.448 10.646 11.354L8 8.707L5.354 11.354C5.26 11.448 5.133 11.501 5 11.501C4.725 11.501 4.499 11.275 4.499 11C4.499 10.867 4.552 10.74 4.646 10.646L7.293 8L4.646 5.354C4.552 5.26 4.499 5.133 4.499 5C4.499 4.867 4.552 4.74 4.646 4.646Z' style='fill-rule:nonzero%3B' fill='%233a6bb9'/%3E%3C/g%3E%3C/svg%3E");

}

@mixin fibMoreCorrect {
  vertical-align: middle;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23008b53' class='bi bi-three-dots-vertical' viewBox='0 0 16 16'%3E%3Cpath d='M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z'/%3E%3C/svg%3E");
}

//@mixin fibMoreIncorrect{
//  vertical-align: middle;
//  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%23e94929' class='bi bi-three-dots-vertical' viewBox='0 0 16 16'%3E%3Cpath d='M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z'/%3E%3C/svg%3E");
//}
@mixin fibMoreAttempted {
  vertical-align: middle;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='%231655bb' class='bi bi-three-dots-vertical' viewBox='0 0 16 16'%3E%3Cpath d='M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z'/%3E%3C/svg%3E");
}

//FIB/label-the-diagram -preview
$fib-field-bottom-space: 0.325;
.fib, .label-the-diagram {
  .fib-field-container {
    border: 1px #{$fib-active-border} solid !important;
    padding-top: 0.5rem;
    padding-bottom: #{0.5}rem; //+ $fib-field-bottom-space
    //padding-bottom: 1rem;
    //background-color: #{$fib-default-bg};
    border-radius: 5px !important;
    white-space: break-spaces;
    overflow: hidden;
    font-weight: 400;

    .answer-space {
      line-height: 0 !important;
      @include noselect();
    }

    .fib-field-border {
      line-height: 0;
      //border-bottom: 1px #{$fib-underline} solid !important;
      //padding-bottom: #{$fib-field-bottom-space}rem;
    }


    &.correct {
      color: #{$fib-correct};
      border: 1px #{$fib-correct} solid !important;
      background-color: #{$fib-correct-bg};
    }

    &.incorrect {
      color: #{$fib-incorrect};
      border: 1px #{$fib-incorrect} solid !important;
      background-color: #{$fib-incorrect-bg};
    }

    &.attempted {
      color: #{$fib-attempted};
      border: 1px #{$fib-attempted} solid !important;
      background-color: #{$fib-attempted-bg};
    }
  }

  [data-type="fib-field"] {
    display: inline;
    text-align: center !important;
    //border-bottom: 1px #{$fib-underline} solid !important;
    outline: 0px solid transparent;
    word-break: break-word;
    line-height: normal;
    white-space: pre-wrap;
    //padding-bottom: 0.325rem;

    &[contenteditable=true] {
      user-modify: read-write-plaintext-only;
      -webkit-user-modify: read-write-plaintext-only;
      -moz-user-modify: read-write-plaintext-only;
      -ms-user-modify: read-write-plaintext-only;
    }

    &:empty {
      padding-left: 3rem;
      padding-right: 3rem;
    }

    &.ck-content, span {
      min-height: 0 !important;
    }

    &.ck-content {
      display: inline-flex;
      border: none !important;
      //border-bottom: 1px black solid;
      //padding: #{$fib-field-bottom-space}rem 0rem 0rem 0rem !important;
      padding: 0 !important;
      line-height: normal;

      .ck-widget {
        overflow: auto;
        width: 100%;
      }

      .ck-math-widget {
        margin-top: 0rem !important;
      }
    }


    //.ck-widget_selected {
    //  outline: none !important;
    //}
  }

  .fib-field-container-math {
    //padding-top: 0rem;
    //padding-bottom: 0.625rem;

    [data-type="fib-field"] {
      //margin-top: 0.325rem;
      //padding-top: 0.325rem !important;
      //padding-bottom: 0rem!important;
    }
  }

  .fib-field-container-dropdown {
    .answer-space {
      display: none;
    }
  }

  .fib-field-container-math, .fib-field-container-dropdown {
    display: inline-flex;
    vertical-align: middle;
    max-width: 100%;
    overflow: auto;
    white-space: nowrap !important;


    [data-type="fib-field"] {
      display: inline-block;
      border-radius: 0 !important;
      min-width: 100px;
      //margin-top: 0.325rem;
      //padding: 0!important;//0.325rem 0 0.325rem 0 !important;

      p {
        margin: 0 !important;
      }
    }
  }

  .fib-field-container-dropdown {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    display: inline-block;
    vertical-align: middle;
    //padding-bottom: 0.825rem;
    overflow: initial;
    line-height: normal;

    .fib-field-border {
      //display: grid;
      //border-bottom: 1px #{$fib-underline} solid !important;
      //border-radius: 0.5rem;
      //width: calc(100% - 1rem);
      //padding-top: 0.325rem;
      //padding-bottom: 0.325rem;

    }

    [data-type="fib-field"] {
      line-height: normal;
      padding: 0 !important; //0.325rem 0 0.325rem 0 !important;

    }

    //dropdown-component
    .drop {
      //display: inline;
      display: inline-flex;
      //display: inline-block;
      position: relative;
      //line-height: 0;
      white-space: nowrap;
      //width: calc(100% - 3rem);
      width: 100%;

      .selected-content {
        color: #{$body-color};
        //width: calc(100% - 3rem);
        min-width: 120px;
        overflow-y: hidden;
        overflow-x: auto;
        text-align: left;
        width: calc(100% - 1rem);

        mjx-container {
          display: contents;
        }

        &:empty {
          &::after {
            content: "";
            position: relative;
          }
        }
      }

      input[type="checkbox"] {
        display: none;

        &:checked {
          ~ .drop-items {
            opacity: 1;
            max-height: 400px;
            padding: .6rem 0;
          }

          ~ .control {
            z-index: $z-index-fib-dropdown;

            &:after {
              transform: rotate(180deg);
            }
          }

          ~ .overlay-close {
            transform: none;
            pointer-events: initial;
          }
        }
      }

      .control {
        position: relative;
        //display: inline-block;
        //display: flex;
        display: inline-flex;
        //width: 100%;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        border-radius: 4px;
        transition: all .3s ease;
        overflow: hidden;
        padding-left: 0.5rem;
        padding-right: 0.5rem;

        &:empty {
          justify-content: end;
        }

        //@media (hover: hover) {
        //  &:hover {
        //    opacity: .75;
        //  }
        //}

        &:after {
          content: "";
          display: inline-block;
          height: .5em;
          width: .5em;
          margin-left: .5em;
          @include dropDownIcon();
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          transition: transform .3s ease;
        }
      }

      .drop-items {
        cursor: pointer;
        position: absolute;
        max-width: calc(100% + 10vw);
        width: max-content;
        top: calc(100% + #{1+$fib-field-bottom-space}rem);
        //left: -2rem;
        padding: 0;
        margin: 0;
        transition: all .3s ease;
        background: #fff;
        box-shadow: 0 2px 10px rgb(0 0 0 / 61%);
        border-radius: 4px;
        overflow: auto;
        max-height: 0;
        opacity: 0;
        z-index: $z-index-fib-dropdown;
        line-height: 0;

        ul {
          position: relative;
          margin-bottom: 0;
          padding: 0;
          text-align: left;
        }

        .item-drop {
          list-style: none;

          font-size: inherit;
          display: block;
          color: #555;
          text-decoration: none;
          padding: 0.5rem 1rem;
          transition: all .3s ease;
          line-height: normal;
          //white-space: pre-wrap;
          //overflow: auto;
          overflow-x: auto;
          overflow-y: hidden;
          //word-break: break-word;
          @include noselect;

          &:hover, &:active {
            background: #eee;
          }

        }
      }

      .overlay-close {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 9;
        transform: translate(100%, 0);
        pointer-events: none;
      }
    }
  }


  .fib-answer-container {
    font-weight: 400;
    padding: 0.5rem 1rem;

    &.fib-correct {
      .cus-drop {
        .control {
          &::after {
            @include fibMoreCorrect;
          }
        }
      }
    }

    &.fib-attempted {
      .cus-drop {
        &::after {
          border-left: 1px #{$fib-attempted} solid;
        }

        .control {
          &::after {
            @include fibMoreAttempted;
          }
        }
      }

    }

    input[type="checkbox"] {
      &:checked {
        ~ .control {
          &:after {
            transform: translate(-50%, -50%);
          }
        }
      }
    }

    &:not(.fib-model-answer-container) {
      .control {
        padding-left: 0.5rem;

        &::after {
          margin-left: 0.25rem;
        }
      }
    }
  }
}

.fib-manual-mark {
  padding-top: calc(0.5rem - 1px);
  padding-left: calc(0.5rem - 1px);
  padding-right: calc(0.5rem - 1px);
  padding-bottom: calc(0.5rem - 1px);
  margin-left: 0.25rem;
  line-height: normal;
  display: inline-block;
  border-radius: 0.5rem;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1rem;

  &.correct {
    color: #{$fib-correct};
    border: 2px #{$fib-correct} dashed;
    @include fibCorrectAnswer;

    &.checked {
      background-color: #{$fib-correct};
      @include fibCorrectAnswerChecked;
    }
  }

  &.incorrect {
    color: #{$fib-incorrect};
    border: 2px #{$fib-incorrect} dashed;
    @include fibIncorrectAnswer;

    &.checked {
      background-color: #{$fib-incorrect};
      @include fibIncorrectAnswerChecked;
    }
  }

  &.attempted {
    color: #{$fib-attempted};
    border: 2px #{$fib-attempted} dashed;
    @include fibAttemptedAnswer;

    &.checked {
      background-color: #{$fib-attempted};
      @include fibAttemptedAnswerChecked;
    }
  }
}

.fib-manual-mark-group-with-text {
  display: inline-grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.fib-manual-mark-with-text {
  padding-top: calc(0.5rem - 1px);
  padding-left: calc(0.5rem - 1px);
  padding-right: calc(0.5rem - 1px);
  padding-bottom: calc(0.5rem - 1px);
  margin-left: 0.5rem;
  line-height: normal;
  display: inline-block;
  border-radius: 0.5rem;
  min-width: 2rem;
  min-height: 2rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1rem;

  svg {
    width: 1.2rem;
    height: 1.2rem;
  }

  @include media-breakpoint-down(md) {
    .indicator-name {
      display: none;
    }
  }

  &.checked {
    svg, .indicator-name {
      color: white;
    }
  }

  &.correct {
    color: #{$fib-correct};
    border: 2px #{$fib-correct} dashed;

    &.checked {
      background-color: #{$fib-correct};
    }
  }

  &.incorrect {
    color: #{$fib-incorrect};
    border: 2px #{$fib-incorrect} dashed;

    &.checked {
      background-color: #{$fib-incorrect};
    }
  }

  &.attempted {
    color: #{$fib-attempted};
    border: 2px #{$fib-attempted} dashed;

    &.checked {
      background-color: #{$fib-attempted};
    }
  }
}

.modal-answer {
  display: inline;
  border-radius: 0.5rem;
  padding-top: calc(0.5rem - 1px);
  padding-left: calc(0.5rem - 1px);
  padding-right: calc(0.5rem - 1px);
  padding-bottom: calc(0.5rem - 1px);
  margin-left: 0.5rem;
  white-space: nowrap;
  border: 2px #{$fib-correct} solid;
  color: $fib-correct;
}


//Openended -preview
.openended {
  .correct {
    color: #{$openended-correct}
  }

  .incorrect {
    color: #{$openended-incorrect}
  }

  .dim {
    color: #{$openended-dim}
  }
}

// Question Type - result
.show-result {
  //Question Type - MCQ
  .mcq {
    //List, Grid, Label
    .answer-container {
      img, svg, video, audio {
        filter: grayscale(1);
      }
    }

    .answer-indicator {
      background-color: #{$mcq-dim}
    }

    .label-mode {
      &:not(.selected) {
        &:not(.correct) {
          .answer-border {
            &::after {
              border: 2px #{$mcq-dim} solid;
            }
          }

          .answer-indicator {
            color: #{$mcq-dim};
            background-color: white;
          }
        }
      }
    }

    @include correctAnswer;

    @include incorrectAnswer;


    //Table
    .mcq-table {
      .checkbox-theme-answer-select-table {
        &.correct {
          @include cus-input-checkbox-colortheme(transparent, $mcq-correct, $mcq-correct, transparent, $mcq-correct, $mcq-correct);
        }

        &.missing-correct {
          @include cus-input-checkbox-colortheme(transparent, $mcq-correct-missing, $mcq-correct, transparent, $mcq-correct-missing, $mcq-correct);

          &::after {
            border-style: dashed;
          }
        }

        &.incorrect {
          @include cus-input-checkbox-colortheme(transparent, $mcq-incorrect, $mcq-incorrect, transparent, $mcq-incorrect, $mcq-incorrect);
        }
      }


      &.table-mc {
        .indicator {
          &.missing-correct {
            color: #{$mcq-correct};
            background-color: #{$white};

            &::after {
              border: 2px #{$mcq-correct} dashed;
            }
          }


          &.dim {
            img, svg, video, audio {
              filter: grayscale(1);
            }


            filter: grayscale(1);

          }
        }
      }

    }


  }

  //Question Type - FIB/label-the-diagram
  .fib, .label-the-diagram {
    .fib-correct, .fib-incorrect, .fib-attempted, .fib-undefined {
      font-weight: bolder;
    }

    .fib-answer-block {
      //max-width: 100%;
      ////overflow-x: auto;
      ////overflow-y: hidden;
      //display: inline-block;
      //vertical-align: middle;
      .fib-answer-container {
        max-width: 100%;
        //overflow-x: auto;
        //overflow-y: hidden;
        display: inline-block;
        //vertical-align: middle;
        line-height: normal;
      }

      .fib-selected-content {
        //display: contents;

        max-width: 100%;
        overflow-x: auto;
        overflow-y: hidden;
        display: inline-block;
        vertical-align: bottom;
        line-height: normal;
      }

      mjx-container {
        display: flex;
        //display: contents;
      }
    }

    //.fib-answer-block {
    //  display: inline;
    //
    //  &.empty {
    //    display: inline-flex;
    //
    //    .fib-indicator {
    //      //margin-left: 2rem;
    //
    //      &::after {
    //        margin-bottom: 0;
    //      }
    //    }
    //  }
    //
    //  line-height: normal;
    //
    //  .fib-correct, .fib-incorrect {
    //    display: inline-flex;
    //    max-width: 100%;
    //  }
    //
    //  .fib-selected-content {
    //    display: inline-flex;
    //    overflow-x: auto;
    //    overflow-y: hidden;
    //
    //    p {
    //      margin-bottom: 0;
    //    }
    //  }
    //}

    .fib-correct, .fib-incorrect {
      .fib-selected-content {
        margin-right: 0.5rem;
      }
    }


    .fib-indicator {
      position: relative;
      margin-right: 1.5rem; //1rem;
      white-space: nowrap;

      &::after {
        content: "";
        width: 1em;
        height: 1em;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      + .cus-drop {
        padding-left: 0.5rem;

        &.otherOptions {
          padding-left: 0rem;
        }
      }
    }

    .fib-correct {
      display: inline;
      line-height: normal;
      background-color: #{$fib-correct-bg};
      border: 1px #{$fib-correct} solid;
      color: #{$fib-correct};


      .fib-indicator {
        //display: flex;
        //justify-content: center;
        //align-items: center;
        //position: relative;

        &::after {
          color: #{$fib-correct};
          @include fibCorrectAnswer;
        }

      }
    }

    .fib-incorrect {
      display: inline;
      line-height: normal;
      background-color: #{$fib-incorrect-bg};
      border: 1px #{$fib-incorrect} solid;
      color: #{$fib-incorrect};

      p {
        display: inline;
      }

      .fib-indicator {
        &::after {
          color: #{$fib-incorrect};
          @include fibIncorrectAnswer;

        }
      }

      .cus-drop {
        &.otherOptions {
          &::after {
            display: none;
          }
        }
      }
    }

    .fib-undefined {
      background-color: #{$fib-default-bg};
      border: 1px #{$fib-active} solid;
      color: #{$body-color};
    }

    .fib-attempted {
      &:not(.fib-correct):not(.fib-incorrect):not(.fib-undefined) {
        background-color: #{$fib-attempted-bg};
        border: 1px #{$fib-attempted} solid;
        color: #{$fib-attempted};

        .fib-indicator {
          &::after {
            color: #{$fib-attempted};
          }
        }

        //.cus-drop {
        //  .control {
        //    //@include FIBMoreAttempted;
        //  }
        //
        //  &::after {
        //    border-left: 1px #{$fib-attempted} solid;
        //  }
        //}
      }

      &:not(.fib-correct):not(.fib-incorrect) {
        //.fib-indicator {
        //  display: none;
        //}

        .fib-selected-content {
          margin-right: 0;
        }
      }
    }

    .fib-answer-container {
      font-weight: 400;
      padding: 0.5rem 1rem;
      white-space: nowrap;
    }

    .fib-model-answer-container {
      white-space: nowrap;
      color: #{$fib-correct};
      border: 1px #{$fib-correct} solid;
      padding-bottom: calc(0.5rem - 0px);
      padding-top: calc(0.5rem - 0px);
      padding-left: calc(1rem - 0px);
      padding-right: calc(1rem - 0px);

      .drop-items {
        border: 1px #{$fib-correct} solid;
      }


    }

    .item-drop {
      pointer-events: none;
      cursor: default;
      //color: var(--bs-dropdown-header-color);
    }

    .drop-items {
      pointer-events: none;
      cursor: default;
    }
  }

  .polling {

    @include attemptedAnswer;

  }
}


.fib-manual-mark-layout {
  .fib-field-container-dropdown {
    .answer-space {
      display: inline;
    }
  }
}