.manual-mark-indicator {
  color: white;

  &.correct {
    border: 1px #{$fib-correct} solid;
    background-color: #{$fib-correct};
  }

  &.incorrect {
    border: 1px #{$fib-incorrect} solid;
    background-color: #{$fib-incorrect};
  }

  &.attempted {
    border: 1px #{$fib-attempted} solid;
    background-color: #{$fib-attempted};
  }

  &.marking {
    color: #{$preview-primary};
    border: 1px #{$mcq-default} solid;
    background-color: #{$mcq-default};
  }
}