.submitting {
  &:after {
    content: "";
    background-color: rgba(0, 0, 0, 0.25);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    @include containerFadeAnimation;
  }
}