.rounded-light {
  border-radius: 4px;
}

.rounded-light-top {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;;
}

.assignment-settings-border {
  border-top: 1px transparent dashed;

  @include media-breakpoint-down(lg) {
    &:not(.hide-border) {
      border-top: 1px #{$dim-300} dashed;
    }
  }
}

.dash-container {
  border-style: dashed !important;
}

.border-select-question {
  background: lighten($preview-primary, 6%);
  border: 0.8px solid rgb(255 255 255 / 33%) !important
}