//.question-tool-bar {
//  div[role="button"] {
//    padding: 0.5rem 0.325rem;
//    color: #{$preview-primary};
//
//    &:hover {
//      background: #{lighten($preview-primary, 50)};
//    }
//  }
//}

$number-indicator-width: 38;
$number-indicator-height: 38;
$number-indicator-border-width: 2;
//#preview {
#question-selector-container {
  > div {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(#{$number-indicator-width}px, max-content));
  }

  @include popover($preview-primary, $preview-bg-color, $preview-primary);

  .number-indicator {
    font-size: #{$h8-font-size};
    border-radius: 50%;

    width: #{$number-indicator-width}px;
    height: #{$number-indicator-height}px;
    position: relative;
    border-width: #{$number-indicator-border-width}px;

    span {
      position: absolute;
    }

    &.group {
      overflow: initial !important;

      &:not(.done) {
        + .done {
          &::before {
            background-color: #{$mcq-dim};
          }
        }
      }

      &.done {
        &::before {
          background-color: #{$mcq-active};
        }

        &::after {
          background-color: #{$mcq-active};
        }

        + :not(.done) {
          &::before {
            background-color: #{$mcq-active};
          }
        }
      }

      &::before {
        content: "";
        width: 2.5rem;
        height: 0.325rem;
        background-color: #{$mcq-dim};
        position: relative;
        margin-left: -1.6rem;
        z-index: -1;
      }

      &::after {
        content: "";
        width: 2.5rem;
        height: 0.325rem;
        background-color: #{$mcq-dim};
        position: relative;
        margin-right: -1.6rem;
        z-index: -1;
      }

      &.first {
        &::before {
          width: 0rem;
        }

        &::after {
          margin-right: -2.6rem;
        }
      }

      &.last {
        &::before {
          margin-left: -2.6rem;
        }

        &::after {
          width: 0rem;
        }
      }
    }
  }

  &.doing {
    .number-indicator {
      &:hover {
        color: white !important;
        background-color: #{$mcq-default} !important;
        border-color: #{$mcq-default} !important;
      }

      &.current {
        border-color: #{$white} !important;
        box-shadow: 0 0 0 #{$number-indicator-border-width}px #{$mcq-active}, 0 0 0 #{$number-indicator-border-width}px #{$mcq-active} !important;
      }

      &.draggable {
        cursor: move;

        &:not(.sortable-chosen) {
          background-color: white !important;
          color: #{$mcq-dim} !important;
          border-style: dashed;
        }

        .sortable-chosen {
          background-color: $mcq-dim !important;
        }
      }
    }
  }

  &.check-answer {
    .number-indicator {
      &.current {
        border-color: #{$white} !important;
        box-shadow: 0 0 0 #{$number-indicator-border-width}px #{$mcq-active}, 0 0 0 #{$number-indicator-border-width}px #{$mcq-active} !important;;
      }
    }
  }
}

//}

@mixin question-style() {
  padding: 3rem !important;

  @include media-breakpoint-down(sm) {
    padding: 1.5rem !important;
  }
}

.inline-comments {
  .accordion-button {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
}

//.question-content {
//  .ck.ck-editor__editable_inline {
//    //@include question-style();
//
//    //> :first-child {
//    //  margin-top: 0rem !important;
//    //}
//    //
//    //> :last-child {
//    //  margin-bottom: 0rem !important;
//    //}
//  }
//}

//.question-table-content {
//  @include question-style();
//}

.preview-question-content {
  @include question-style();
}