.avatar-container {
  --avatar-size: 2rem;

  background-color: #ccc;
  border-radius: 50%;
  height: var(--avatar-size);
  text-align: center;
  width: var(--avatar-size);

  .avatar-initials {
    font-size: calc(var(--avatar-size) / 3);
    line-height: 1;
    position: relative;
    top: calc(var(--avatar-size) / 10);
  }
}