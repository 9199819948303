$lineWidth: 1.3px;

.report-min-height {
  min-height: 300px
}

.disable-presentation-area {
  .presentation-area {
    max-width: none !important;
  }
}

.sample-avg-line {
  width: 1.8rem;
  height: 4.8px;
  border-bottom: 3px dashed $report-average-line;
  padding-bottom: 0.125rem;
}

.sample-passing-line {
  width: 1.8rem;
  height: 4.8px;
  border-bottom: 3px dashed $report-passing-line;
  padding-bottom: 0.125rem;
}

.sample-correct-cube {
  width: 1rem;
  height: 1rem;
  background: $report-correct-bar;
}

.sample-incorrect-cube {
  width: 1rem;
  height: 1rem;
  background: $report-incorrect-bar;
}

.sample-attempted-cube {
  width: 1rem;
  height: 1rem;
  background: $report-attempted;
}

.sample-not-attempted-cube {
  width: 1rem;
  height: 1rem;
  background: $report-not-attempted-bar;
}

.sample-line {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}


.report-sort-horizontal {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.report {
  border-collapse: separate;
  border-spacing: 0;

  .sticky-header {
    @include media-breakpoint-up(md) {
      position: -webkit-sticky;
      position: sticky;
      background: white;
      left: 0;
      z-index: 1;
    }
  }

  &.performance-overview-chart {
    .header-name {
      border-right: 1px solid $dim-400;
    }

    thead {
      th {
        border-bottom: 1px solid $dim-400;
      }
    }

    tbody {
      tr:first-child {
        td {
          padding-top: 0.325rem;
        }
      }
    }

    td:nth-child(-n + 3), td:nth-last-child(-n + 1), th:nth-child(-n + 3), th:nth-last-child(-n + 1) {
      padding-left: 0.825rem;
      padding-right: 0.825rem;
    }

    td {
      padding-bottom: 0.5rem;
    }

    tr:last-child {
      td {
        padding-bottom: 0;
      }
    }

    .score-percentage-field {
      width: 500px;
      min-width: 500px;
      max-width: 500px;
      padding-left: 0;
      padding-right: 0;

      @include media-breakpoint-down(lg) {
        width: 300px;
        min-width: 300px;
        max-width: 300px;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        min-width: 300px;
        max-width: 300px;
      }
    }

    .average-line, .passing-line {
      position: absolute;
      top: 0;
      height: 100%;
      width: 0px;
      //z-index: 1;
    }

    .average-line {
      border: $lineWidth dashed $report-average-line;
    }

    .passing-line {
      border: $lineWidth dashed $report-passing-line;
    }

    .minibar-container {
      height: 2rem;
    }
  }

  &.question-performance-chart {
    .max-title {
      max-width: 300px;
      overflow-wrap: break-word;
      overflow: auto;

      @include media-breakpoint-down(xl) {
        max-width: 200px;
      }
      @include media-breakpoint-down(lg) {
        max-width: 250px;
      }
      @include media-breakpoint-down(md) {
        max-width: 200px;
      }
      @include media-breakpoint-down(sm) {
        max-width: 150px;
      }
    }
  }

  &.performance-detail-chart {
    .header-score {
      padding-right: 1.5rem !important;

      + td {
        padding-left: 0.5rem !important;
      }
    }

    .header-colspan {
      + td {
        padding-left: 0.5rem;
      }
    }

    thead {
      .right-border {
        border-right: 1px solid $dim-400;
      }

      .bottom-border {
        border-bottom: 1px solid $dim-400;
      }

      .space {
        padding: 0.5rem 1rem 0.5rem 2rem;
      }

      td {
        &:nth-child(n+3) {
          padding: 0.5rem 0;
        }
      }
    }

    tbody {

      td {
        padding: 0.5rem 2rem;

        &:nth-child(3) {
          border-right: 1px solid $dim-400;
          padding: 0.5rem 1rem 0.5rem 2rem;
        }


        &:nth-child(n+4) {
          padding: 0.15rem 0;
        }
      }
    }

    .data-field {
      width: 70px;
      text-align: center;
    }
  }

  &.performance-metadata-sets-chart {
    .sticky-header {
      &.header-qno {
        border-right: 1px solid $dim-400;
        width: 600px;
        min-width: 600px;

        @include media-breakpoint-down(lg) {
          width: 400px;
          min-width: 400px;
        }
      }
    }

    &.mini-layout {
      .sticky-header {
        @include media-breakpoint-up(md) {
          position: relative;
        }

        &.header-qno {
          @include media-breakpoint-down(xxl) {
            width: 450px;
            min-width: 450px;
          }
          @include media-breakpoint-down(xl) {
            width: 750px;
            min-width: 750px;
          }
          @include media-breakpoint-down(lg) {
            width: 600px;
            min-width: 600px;
          }
          @include media-breakpoint-down(md) {
            width: 400px;
            min-width: 400px;
          }
        }
      }
    }

    thead {
      th {
        border-bottom: 1px solid $dim-400;
      }
    }

    td {
      padding: 0.5rem;
      vertical-align: top;
    }

    tbody {
      td {
        border-bottom: 1px solid $dim-200;
      }
    }

    .question-no-th {
      &.zh {
        &.text-rotate {
          div {
            width: 50px;
          }
        }
      }

      div {
        span {
          left: auto;
        }
      }
    }

    .correctly-th, .average-follow-up-th {
      width: 60px;

      div {
        width: 60px;
      }
    }

    .correctness-th, .average-th, .no-of-studnet-follow-up-th {
      width: 150px;

      &.wider {
        min-width: 150px;
      }

      //&.zh {
      //  width: 80px;
      //
      //}
    }

    .avatar-th {
      width: 35px;

      div {
        width: 35px;
      }
    }
  }

  //Studnet Follow Up Exercise Chart
  &.follow-up-exercise-chart {
    .sticky-header {
      &.header-qno {
        border-right: 1px solid $dim-400;
        width: 900px;
        min-width: 900px;

        @include media-breakpoint-down(lg) {
          width: 400px;
          min-width: 400px;
        }
      }
    }

    &.mini-layout {
      .sticky-header {
        @include media-breakpoint-up(md) {
          position: relative;
        }

        &.header-qno {
          @include media-breakpoint-down(xxl) {
            width: 450px;
            min-width: 450px;
          }
          @include media-breakpoint-down(xl) {
            width: 750px;
            min-width: 750px;
          }
          @include media-breakpoint-down(lg) {
            width: 600px;
            min-width: 600px;
          }
          @include media-breakpoint-down(md) {
            width: 400px;
            min-width: 400px;
          }
        }
      }
    }
  }

  // &.report-table-progress-chart &.follow-up-exercise-chart { //shared
    &.report-table-progress-chart, &.follow-up-exercise-chart { //shared
    width: 100%;

    &.metadata-progress-chart {
      min-height: 450px;

      tbody {
        vertical-align: top;
      }

      th {
        padding-left: 0.825rem * 4;
      }

      .correctness-average-bar {
        top: 20%;
        height: 60%;
      }

      &.whole-key-stage {
        display: flex;
        flex-direction: column;

        .average-score-chart-row {
          .left-wrapper {
            display: flex;
          }

          .right-wrapper {
            flex: 0 1 auto;
          }

          .title-index {
            .index {
              &:not(:empty) {
                margin-right: 0.5rem;
              }
            }
          }

          margin-bottom: 1rem;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .row-data-wrapper {
          position: relative;

          .graph-bg {
            z-index: 0;
            width: calc(100% - 924px);
            height: 100%;
            position: absolute;
            left: 924px;
            top: 0;
            background-color: $white;
            justify-content: space-between;
            display: flex;
            border-top: 2px solid $dim-400;
            border-bottom: 2px solid $dim-400;

            @include media-breakpoint-down(xxl) {
              left: 824px;
              width: calc(100% - 824px);
            }

            @include media-breakpoint-down(lg) {
              left: 624px;
              width: calc(100% - 624px);
            }

            .x-axis-number {
              color: $black;
            }

            .graph-grid-lines-wrapper {
              width: 100%;
              display: flex;
              justify-content: space-between;

              > span {
                position: relative;
                left: 0;
                top: 0;
                border: 1px solid $dim-150;
                width: 0px;
                height: 100%;

                &:first-child {
                  border-color: $dim-400;
                }

                &:last-child {
                  border-color: $dim-400;
                }
              }
            }
          }

          .score-percentage-field {
            .status-line.dot-line,
            .horizontal-line,
            .arrow,
            .score-box {
              pointer-events: none;
            }
          }

            .score-popup-wrapper {
              position: absolute;
              z-index: 22;
              opacity: 0;
              visibility: hidden;
              transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out, visibility 0.2s ease-in-out;
              transform: translateY(10px);
              pointer-events: none;

              &.show {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                pointer-events: auto;
              }

            .progress-chart-popup {
              background: white;
              border: 1px solid $dim-400;
              border-radius: 4px;
              padding: 1rem;
              box-shadow: 0 2px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);
              font-size: 13.2px;
              width: 230px;

              .title-container {
                display: flex;

                .title {
                  font-size: 16px;
                  margin-bottom: 1rem;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 3;
                  line-clamp: 3;
                  -webkit-box-orient: vertical;
                }
              }

              h3, h4 {
                font-size: 13.2px;
                margin-bottom: 0.5rem;
              }

              p {
                margin-bottom: 0.5rem;
              }

              .class-average-label {
                margin-bottom: 0;
              }

              ul {
                list-style-type: none;
                padding-left: 0;
                margin: 0.5rem 0;
              }

              li {
                display: flex;
                align-items: center;
                margin-bottom: 0.25rem;
              }

              .color-box {
                width: 7px;
                height: 13px;
                margin-right: 5px;
              }

              .score {
                margin-left: 10px;
              }

              .class-average {
                margin-left: 0.5rem;
              }
            }
          }
        }

        thead {
          display: grid;

          .col-average-score-percentage-2 {
            width: 100%;
          }
        }

        .tr-legend {
          min-width: 1760px;

          @include media-breakpoint-down(xxl) {
            min-width: 1660px;
          }

          @include media-breakpoint-down(lg) {
            min-width: 1460px;
          }

          .average-score-progress-legend {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin: 10px 0;

            .legend-item {
              display: flex;
              align-items: center;
              margin-right: 1rem;

              .color-box {
                width: 15px;
                height: 15px;
                margin-right: 5px;
              }

              .year-label {
                font-size: 14px;
              }

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }

        tbody {
          display: flex;

          tr {
            display: flex;
            flex-grow: 1;

            td {
              flex-grow: 1;
            }
          }
        }

        .field {
          margin: 0;
        }

        .horizontal-line {
          position: absolute;
          height: 2px;
          top: 50%;
          transform: translateY(-50%);
        }

        .arrow {
          position: absolute;
          top: 50%;
          width: 10px;
          height: 10px;
          border-top: 2px solid;
          border-right: 2px solid;

          &.right {
            right: 1px;
            transform: translateY(-50%) rotate(45deg);

            &.from-zero {
              right: 8px;
            }
          }

          &.left {
            left: 13px;
            transform: translateY(-50%) rotate(-135deg);

            &.to-zero {
              left: 8px;
            }
          }
        }
      }
    }

    &.class-progress-chart {
      tr {
        td {
          text-align: center;
        }
      }

      .score-percentage-field {
        .average-line {
          border: $lineWidth dashed $report-uncategories-line !important;
        }

        .status-line {
          border: $lineWidth solid $report-uncategories-line !important;;
        }
      }
    }

    tr {
      th, td {
        padding: 0.825rem;
      }

      th {
        border-bottom: 1px solid $dim-400;
        white-space: nowrap;
        //position: sticky;
        top: 0;
        //z-index: 1;
        background: white;
        text-align: center;
      }

      td {
        &:not(:first-child) {
          text-align: center;
        }
      }

      &:not(:last-child) {
        td {
          border-bottom: 1px solid $dim-200;
        }
      }
    }

    .average-line, .passing-line, .status-line {
      position: absolute;
      top: 0;
      height: 100%;
      width: 0px;
      //z-index: 1;
    }

    .average-line {
      border: $lineWidth dashed $report-average-line;
    }

    .passing-line {
      border: $lineWidth dashed $report-passing-line;
    }

    .status-line {
      border-width: $lineWidth;
      border-style: solid;

      &.dot-line {
        width: 12px !important;
        height: 12px !important;
        border-width: 0px;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
      }

      &.dash-line {
        border-style: dashed;
      }

      &.grid-line {
        border-color: $dim-150;
      }

      &.class-average-line {
        display: none;

        &.show {
          display: block;
          height: calc(100% + 1rem);
          transform: translateY(-65%);

          &.selected-student {
            top: 50%;
            height: 20px;
            width: 4px;
            border-style: solid;
            transform: translateY(-50%);
          }
        }
      }
    }

    .score-box {
      display: none;

      &.show {
        display: block;
      }
    }

    .score-percentage-field, .minibar-container {
      width: 100%;

      &.score-visible-on {
        height: 3.5rem;
      }

      .bottom-black-line {
        position: absolute;
        bottom: -1px;
        left: 0;
        right: 0;
        height: 2px;
        background-color: $dim-400;
        z-index: 1;
      }
    }
  }

  //table column
  .col {
    &-assignment {
      min-width: 500px;
      @include media-breakpoint-down(xl) {
        min-width: 300px;
      }
      @include media-breakpoint-down(lg) {
        min-width: 250px;
      }
    }

    &-assigned-by {
      min-width: 150px
    }

    &-class {
      min-width: 70px
    }

    &-result-publish {
      min-width: 150px;
    }

    &-submitted {
      min-width: 80px;
    }

    &-score-percentage {
      min-width: 400px;
    }

    &-avg-score-percentage {
      min-width: 70px;
    }

    &-action {
      min-width: 50px;
    }

    &-class-no {
      min-width: 100px;
    }

    &-student-name {
      min-width: 100px;
      max-width: 150px;
    }

    &-assignments-score {
      min-width: 300px;
    }

    &-score-range {
      min-width: 300px;
    }

    //&-no-of-question-answered {
    //  min-width: 80px;
    //}

    &-metadata {
      word-wrap: break-word;
      width: 700px; //calc(100% - 600px);
      max-width: 700px;
      min-width: 700px;

      @include media-breakpoint-down(xxl) {
        width: 600px;
        max-idth: 600px;
        min-width: 600px;
      }

      @include media-breakpoint-down(lg) {
        width: 400px;
        max-idth: 400px;
        min-width: 400px;
      }
    }

    &-no-of-question-answered {
      width: 250px; //calc(100% - 250px);
      min-width: 250px
    }

    $col-average-score-percentage-2: 550px;
    $col-whole-key-stage: 1016px;

    &-average-score-percentage-2 {
      width: calc(100% - $col-average-score-percentage-2);
      min-width: $col-average-score-percentage-2;
    }

    &-fix-metadata {
      display: flex;
      width: 700px; //calc(100% - 600px);
      max-width: 700px;
      min-width: 700px;

      @include media-breakpoint-down(xxl) {
        width: 600px;
        max-idth: 600px;
        min-width: 600px;
      }

      @include media-breakpoint-down(lg) {
        width: 400px;
        max-idth: 400px;
        min-width: 400px;
      }
    }

    &-fix-no-of-question-answered {
      display: flex;
      justify-content: center;
      width: 250px; //calc(100% - 250px);
      min-width: 250px;
      align-items: center;
    }

    &-fix-average-score-percentage-2 {
      display: flex;
      justify-content: start;
      width: calc(100% - $col-average-score-percentage-2);
      min-width: $col-average-score-percentage-2;

      &.col-whole-key-stage {
        width: 100%;
        min-width: $col-whole-key-stage;

        .score-on-first-row-gap {
          display: block;
          height: 20px;
        }

        .average-score-wrapper {
          margin-bottom: 1rem;

          &:first-child {
            .col-average-score-title {
              align-items: start;
            }
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      &.selected-class {
        > div:nth-child(n+2) {
          .field {
            height: 1.8rem;

            &.score-visible-on {
              height: 3.5rem;
            }
          }

          .col-mini-student-no, .col-mini-student-name, .col-mini-average-percentage {
            font-size: 0.825rem;
          }
        }
      }
    }

    $col-header-expand: 20px;
    $col-mini-student-no: 50px;
    $col-mini-student-name: 150px;
    //$col-mini-average-percentage: 50px;

    $col-mini-class-no: 100px;
    $col-mini-student: $col-mini-student-no + $col-mini-student-name;
    $col-expand: $col-mini-class-no;
    $col-mini-average-percentage: 50px;

    $col-average-score-title: $col-mini-student-no + $col-mini-student-name;
    $col-average-score-mini-title: $col-mini-class-no;

    &-header-expand {
      width: $col-header-expand;
    }

    &-average-score-title-tmp-space {
      width: $col-average-score-title;
      flex: 0 0 $col-average-score-title;
      display: flex;
    }

    &-average-score-title {
      width: $col-average-score-title;
      flex: 0 0 $col-average-score-title;
      display: flex;
      justify-content: end;
      align-items: center;

      &.mini-title {
        width: $col-average-score-mini-title;
        flex: 0 0 $col-average-score-mini-title;
      }
    }

    &-expand {
      width: $col-expand;
      flex: 0 0 $col-expand;
      display: flex;
      justify-content: end;
    }

    &-mini-class-no {
      text-align: end;
      width: $col-mini-class-no;
      flex: 0 0 $col-mini-class-no;
    }


    &-mini-student {
      text-align: start;
      word-wrap: break-word;
      width: $col-mini-student;
      flex: 0 0 $col-mini-student;
    }

    //&-mini-student-no {
    //  text-align: end;
    //  width: $col-mini-student-no;
    //  flex-basis: $col-mini-student-no;
    //}

    //&-mini-student-name {
    //  text-align: end;
    //  width: $col-mini-student-name;
    //  flex-basis: $col-mini-student-name;
    //}

    &-mini-average-percentage {
      text-align: start;
      width: $col-mini-average-percentage;
      flex: 0 0 $col-mini-average-percentage;
    }
  }

  // Field
  .field {
    height: 2rem;
    margin: 0.325rem;

    .field-center {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 0.325rem 0.5rem;
    }

    &.field-marks {
      min-width: 2rem;
      margin: 0.325rem 0.125rem;
    }
  }

  //Minibar
  .minibar-container {
    width: 100%;
    height: 100%;
    background: $dim-150;
    position: relative;

    .bg-value {
      &.value-top {
        position: absolute;
        top: 0;
        left: 0;
        //text-shadow: 2px 2px #{$dim-400};
        color: white;
        //justify-content: end;
      }

      justify-content: start;
      padding: 0 1rem;
      display: flex;
      align-items: center;
      min-width: 80px;
      width: 100%;
      height: 100%;
    }

    .divided-value {
      padding: unset;
      min-width: unset;
    }

    &.showFail {
      background: $report-incorrect-bar;
      color: white;
    }

    .correctness-average-bar {
      overflow: hidden;
      position: absolute;
      width: 100%;
      height: 100%;
      max-width: 100% !important;
      top: 0;
      left: 0;
    }

    .correctness-bar, .p-bar {
      background: $report-correctness-bar;
    }

    .average-bar, .avg-bar {
      background: $report-average-bar;
    }

    .fail-bar, .f-bar {
      background: $report-incorrect-bar;
    }

    .attempted-bar, .a-bar {
      background: $report-attempted-bar;
    }

    .manual-bar, .n-bar, .s-bar {
      background: $dim-400;
    }

    .uc-bar { //uncategories
      background: $report-uncategories-bar;
    }

    .test-bar {
      background: $dim-350;
    }

    .indicator-value {
      position: absolute;
      font-size: 1rem;
      z-index: 2;
      text-align: center;
      color: white;
      bottom: -100%;
      margin-left: -12px;

      &::before,
      &::after {
        content: "";
        position: absolute;
        border-style: solid;
        display: block;
      }

      &::before {
        border-width: 7px 10px 0 10px;
        top: -5px;
        left: 12px;
        transform: translateX(-50%) rotate(180deg);
      }

      &::after {
        border-width: 6px 9px 0 9px;
        top: -2px;
        left: 11px;
        transform: translateX(-50%) rotate(180deg);
      }

      &.p-bar {
        &::before,
        &::after {
          border-color: $report-correctness-bar transparent;
        }
      }


      &.f-bar {
        &::before,
        &::after {
          border-color: $report-incorrect-bar transparent;
        }
      }

      &.a-bar {
        &::before,
        &::after {
          border-color: $report-attempted-bar transparent;
        }
      }

      &.n-bar, &.s-bar {
        &::before,
        &::after {
          border-color: $dim-400 transparent;
        }
      }


      &.avg-bar {
        &::before,
        &::after {
          border-color: $report-average-bar transparent;
        }
      }
    }

    .indicator-range {
      position: absolute;
      z-index: 1;
      text-align: center;
      bottom: -70%;
      margin-left: -12px;
    }
  }

  .side-percentage {
    width: 4.5rem;
  }

  //Text
  .text-status {
    &-p {
      //pass
      color: #{$report-correct};
    }

    &-f {
      //fail
      color: #{$report-incorrect};
    }

    &-a {
      //attempted
      color: #{$report-attempted};
    }

    &-n {
      //null
      color: #{$dim-400};
    }

    &-avg {
      color: #{$report-average-bar};
    }
  }

  //Line
  .line-status {
    &-p {
      //pass
      border-color: #{$report-correct};
    }

    &-f {
      //fail
      border-color: #{$report-incorrect};
    }

    &-a {
      //attempted
      border-color: #{$report-attempted};
    }

    &-n {
      //null
      border-color: #{$dim-400};
    }

    &-avg {
      border-color: #{$report-average-bar};
    }
  }

  //Dot
  .dot-status {
    &-p {
      //pass
      border-color: #{lighten($report-correct,40%)};
      background-color: #{lighten($report-correct,20%)};
    }

    &-f {
      //fail
      border-color: #{lighten($report-incorrect,40%)};
      background-color: #{lighten($report-incorrect,20%)};
    }

    &-a {
      //attempted
      border-color: #{lighten($report-attempted,40%)};
      background-color: #{lighten($report-attempted,20%)};
    }

    &-n {
      //null
      border-color: #{lighten($dim-400,40%)};
      background-color: #{lighten($dim-400,20%)};
    }

    &-avg {
      border-color: #{lighten($report-average-bar,40%)};
      background-color: #{lighten($report-average-bar,20%)};
    }
  }

  //Question
  .question {
    &-p {
      //pass
      background: lighten($report-correct-light, 10%);
      border: 1px #{$report-correct-text} solid;

      .status-text {
        color: #{$report-correct-text};
      }

      .total {
        color: #{$report-question-total};
      }
    }

    &-f {
      //fail
      background: lighten($report-incorrect-light, 10%);
      border: 1px #{$report-incorrect-text} solid;

      .status-text {
        color: #{$report-incorrect-text};
      }

      .total {
        color: #{$report-question-total};
      }
    }

    &-a {
      //attempted
      background: lighten($report-attempted-light, 10%);
      border: 1px #{$report-attempted-text} solid;

      .status-text {
        color: #{$report-attempted-text};
      }

      .total {
        color: #{$report-question-total};
      }
    }
  }

  //Status box
  .status {
    &-p {
      //pass
      background: #{$report-correct-light};
      color: #{$report-correct};

      &-light {
        background: lighten($report-correct-light, 8%);

        &::before,
        &::after {
          content: "";
          position: absolute;
          border-style: solid;
          display: block;
          border-color: lighten($report-correct-light, 8%) transparent;
        }

        &::before {
          border-width: 7px 10px 0 10px;
          top: -5px;
          left: 50%;
          transform: translateX(-50%) rotate(180deg);
        }

        &::after {
          border-width: 6px 9px 0 9px;
          top: -2px;
          left: 50%;
          transform: translateX(-50%) rotate(180deg);
        }

      }
    }

    &-f {
      //fail
      background: #{$report-incorrect-light};
      color: #{$report-incorrect};

      &-light {
        background: lighten($report-incorrect-light, 8%);

        &::before,
        &::after {
          content: "";
          position: absolute;
          border-style: solid;
          display: block;
          border-color: lighten($report-incorrect-light, 8%) transparent;
        }

        &::before {
          border-width: 7px 10px 0 10px;
          top: -5px;
          left: 50%;
          transform: translateX(-50%) rotate(180deg);
        }

        &::after {
          border-width: 6px 9px 0 9px;
          top: -2px;
          left: 50%;
          transform: translateX(-50%) rotate(180deg);
        }
      }
    }

    &-a {
      //attempted
      background: #{$report-attempted-light};
      color: #{$report-attempted};

      &-light {
        background: lighten($report-attempted-light, 8%);

        &::before,
        &::after {
          content: "";
          position: absolute;
          border-style: solid;
          display: block;
          border-color: lighten($report-attempted-light, 8%) transparent;
        }

        &::before {
          border-width: 7px 10px 0 10px;
          top: -5px;
          left: 50%;
          transform: translateX(-50%) rotate(180deg);
        }

        &::after {
          border-width: 6px 9px 0 9px;
          top: -2px;
          left: 50%;
          transform: translateX(-50%) rotate(180deg);
        }
      }
    }

    &-n {
      //null
      background: #{$dim-150};
      color: #{$dim-400};
    }

    &-m {
      background: #{$report-alert};
      color: #{$report-alert-light};
    }

    &-s {
      background: #{$dim-150};
      color: #{$dim-400};
    }

    &-z {
      //zero data
      background: #{$dim-150};
      color: #{$dim-800};
    }
  }

  //Table Header
  .text-rotate {
    div {
      width: 120px;
      height: 140px;
      position: relative;

      span {
        bottom: 5px;
        left: 50%;
        position: absolute;
        transform: rotate(-45deg);
        transform-origin: center left;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
      }
    }
  }

  .sticky-header {
    $no-width: 140px;
    $name-width: 250px;
    $score-width: 140px;

    &.header-no {
      min-width: $no-width;
      left: 0;

      + .header-name {
        left: $no-width;

        + .header-score {
          left: $no-width+$name-width;
        }
      }
    }

    &.header-name {
      min-width: $name-width;
    }

    &.header-score {
      min-width: $score-width;
    }

    &.header-colspan {
      min-width: $no-width+$name-width+$score-width;
    }
  }
}


//Donut Chart
.performance-overview-donut {
  display: flex;
  justify-content: center;
  margin: 0 auto;

  &.donut-avg {
    max-width: 380px;
    width: 100%;
    @include media-breakpoint-down(xxl) {
      max-width: 300px;
    }
    @include media-breakpoint-down(lg) {
      max-width: 350px;
    }
    @include media-breakpoint-down(md) {
      max-width: 350px;
    }
    @include media-breakpoint-down(sm) {
      max-width: 350px;
    }
  }

  &.donut-submitted, &.donut-manual, &.donut-passing {
    max-width: 350px;
    width: 100%;
  }
}

.donut {
  .donut-segment {
    transform-origin: center;
  }

  //.donut-segment-2 {
  //  animation: donut1 3s;
  //}

  .donut-percent {
    animation: donutfadelong 1s;
  }

  @keyframes donutfadelong {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  //@keyframes donut1 {
  //  0% {
  //    stroke-dasharray: 0, 100;
  //  }
  //  100% {
  //    stroke-dasharray: 69, 31;
  //  }
  //}


  .donut-text {
    font-family: Arial, Helvetica, sans-serif;
  }

  .donut-percent {
    font-size: 0.5em;
    line-height: 1;
    transform: translateY(0.5em);
    font-weight: bold;
  }

  .donut-data {
    font-size: 0.18em;
    line-height: 1;
    transform: translateY(0.5em);
    text-align: center;
    text-anchor: middle;
    //color: #666;
    //fill: #666;
    animation: donutfadelong 1s;
  }

  .marks {
    transform: translate(20px, 20px);
  }
}

//Single Range Chart
.single-range-chart {
  width: 100%;
  height: 2.5rem;
  position: relative;
  background: $dim-150;

  .line {
    position: absolute;
    height: 100%;
    border-width: $lineWidth;
    border-style: dashed;
  }

  .range-bar {
    position: absolute;
    height: 100%;
    top: 0;
    background: $report-average-bar;
    min-width: 1.5%;
  }

  .range-bar-indicator {
    position: absolute;
    bottom: -50%;
    color: $dim-400;
  }

  .range-indicator-container {
    position: absolute;
    bottom: -15%;
    display: flex;
    justify-content: center;
  }

  .range-indicator {
    position: absolute;
    font-size: 1rem;
    z-index: 1;
    text-align: center;
    color: white;
    //margin-left: auto;
    //margin-right: auto;
    //left: 0;
    //right: 0;
    background: $report-average-bar;
    width: fit-content;

    &::before,
    &::after {
      content: "";
      position: absolute;
      border-style: solid;
      display: block;
      border-color: $report-average-bar transparent;
    }

    &::before {
      border-width: 7px 10px 0 10px;
      top: -5px;
      left: 50%;
      transform: translateX(-50%) rotate(180deg);
    }

    &::after {
      border-width: 6px 9px 0 9px;
      top: -2px;
      left: 50%;
      transform: translateX(-50%) rotate(180deg);
    }

    &.shiftLeft {
      &::before, &::after {
        left: calc(50% + 25px);
      }
    }

    &.shiftRight {
      &::before, &::after {
        left: calc(50% - 10px);
      }
    }
  }
}


//Question Correctness and Scores
.question-cube {
  width: 7rem;
  height: 5rem;
}

//Report student Summary
.report-result-col {
  &.result-col-left, &.result-col-right {
    flex-grow: 1;
    @include media-breakpoint-down(md) {
      flex-basis: 100%
    }
  }

  &.result-col-left {
    width: 40%;
    flex-basis: 40%;

    &.result-col-top {
      @include media-breakpoint-down(lg) {
        width: 100%;
        flex-basis: 100%;
      }
    }

    &.result-col-bottom {
      @include media-breakpoint-down(xl) {
        width: 100%;
        flex-basis: 100%;
      }
    }
  }

  &.result-col-right {
    width: 60%;
    flex-basis: 60%;

    &.result-col-top {
      @include media-breakpoint-down(lg) {
        width: 100%;
        flex-basis: 100%;
      }
    }

    &.result-col-bottom {
      @include media-breakpoint-down(xl) {
        width: 100%;
        flex-basis: 100%;
      }
    }
  }

  .wrap-250 {
    height: 250px;
    min-width: 250px;
  }

  .min-wrap-300 {
    height: 300px;
    min-width: 300px;
  }

  .min-width-wrap-300 {
    min-width: 300px;
  }

  .width-wrap-220 {
    width: 220px;
  }
}

.result-summary-table {
  td {
    padding-bottom: 0.825rem;
  }
}

.result-assignment {
  width: 100%;
  height: 100%;
  min-height: 200px;
  position: relative;
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .result-hand, .result-bg {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  .result-hand {
    bottom: 0;
    width: 250px;
    height: 250px;

    .svg-icon {
      height: 100%;
    }
  }

  .result-bg {
    top: 0;
    width: 250px;
    height: 250px;

    .svg-icon {
      height: 100%;
    }
  }
}

//Student assignment overview
.report-student-assignment-overview {
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .donutContainer {
    max-width: 250px;
    margin: 0 auto;
  }
}

//Chart Shared
.text-two-line {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

//Report student Summary
.report-progress-col {
  overflow: hidden;

  &.result-col-left, &.result-col-right {
    flex-grow: 1;
    @include media-breakpoint-down(md) {
      flex-basis: 100%
    }
  }

  &.result-col-left {
    width: 40%;
    flex-basis: 40%;

    &.class-col {
      width: 40%;
      flex-basis: 40%;

      @include media-breakpoint-down(xl) {
        width: 40%;
        flex-basis: 40%;
      }
    }

    &.selected-student-col {
      width: 40%;
      flex-basis: 40%;
    }

    &.result-col-top {
      @include media-breakpoint-down(lg) {
        width: 100%;
        flex-basis: 100%;
      }
    }

    &.result-col-bottom {
      @include media-breakpoint-down(lg) {
        width: 100%;
        flex-basis: 100%;
      }
    }
  }

  &.result-col-right {
    width: 60%;
    flex-basis: 60%;

    &.class-col {
      width: 60%;
      flex-basis: 60%;

      @include media-breakpoint-down(xl) {
        width: 60%;
        flex-basis: 60%;
      }
    }

    &.selected-student-col {
      width: 60%;
      flex-basis: 60%;
    }

    &.result-col-top {
      @include media-breakpoint-down(lg) {
        width: 100%;
        flex-basis: 100%;
      }
    }

    &.result-col-bottom {
      @include media-breakpoint-down(lg) {
        width: 100%;
        flex-basis: 100%;
      }
    }
  }

  .width-wrap-220 {
    width: 220px;
  }

  //Fix Height
  .h-35 {
    height: 35px;
  }

  .max-size-chart-500 {
    height: 500px;

    &:not(.radial-chart-container) {
      overflow-x: auto;
    }


    @include media-breakpoint-down(lg) {
      height: 490px;
    }

    @include media-breakpoint-down(md) {
      height: 480px;
    }

    @include media-breakpoint-down(sm) {
      height: 500px;
    }

    svg {
      height: 100%;
    }

    &.max-width {
      max-width: 500px;
    }
  }

  .max-size-chart-400 {
    height: 400px;

    &:not(.radial-chart-container) {
      overflow-x: auto;
    }

    // @include media-breakpoint-down(lg) {
    //   height: 490px;
    // }

    // @include media-breakpoint-down(md) {
    //   height: 480px;
    // }

    // @include media-breakpoint-down(sm) {
    //   height: 500px;
    // }

    svg {
      height: 100%;
    }

    &.max-width {
      max-width: 400px;
    }
  }

}

//Metadata Radial Chart
.radial-chart-slider {
  display: flex;
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  user-select: none;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  //Fix bouncing mobile scroll
  overscroll-behavior: none;
  //aspect-ratio: 1;
  width: 100%;

  //&.draggable {
  //  &:hover {
  //    cursor: grab;
  //  }
  //
  //  &:active {
  //    cursor: grabbing;
  //  }
  //}

  section {
    border-right: 1px solid white;
    //padding: 2rem;
    min-width: 100%;
    height: 100%;
    scroll-snap-align: start;
    text-align: center;
    position: relative;
  }
}

.radial-chart-container {
  position: relative;
  //aspect-ratio: 1;
  width: 100%;

  .radial-chart-btn {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    span {
      background: $dim-200;
      color: $body-color;
    }

    &.prev {
      left: 0;
    }

    &.next {
      right: 0;
    }
  }

  .radial-chart-dot-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
    position: absolute;
    bottom: 0rem;
    //@include media-breakpoint-down(md) {
    //  bottom: 0rem;
    //}
  }

  .radial-chart-dot-btn {
    width: 1rem;
    height: 1rem;
    background: $dim-250;
    border-radius: 0.5rem;
    transition: width 0.215s ease-in-out;

    &:hover {
      background: $dim-350;
    }

    &.active {
      width: 4rem;
      background: $dim-400;

      @include media-breakpoint-down(md) {
        width: 3rem;
      }
    }
  }
}

.radial-chart-menu-container {
  display: flex;
  flex-direction: column;
  gap: 0.825rem;
  position: absolute;
  top: 3rem;
  left: 0;
  width: 300px;
  max-height: 400px;
  background: white;
  padding: 1rem;
  border: 1px $dim-400 solid;
  white-space: pre-wrap;
  overflow-y: auto;
  z-index: 2;
  border-radius: 4px;

  -webkit-box-shadow: 0 2px 2px rgb(10 16 20 / 24%), 0 0 2px rgb(10 16 20 / 12%);
  -moz-box-shadow: 0 2px 2px rgb(10 16 20 / 24%), 0 0 2px rgb(10 16 20 / 12%);
  box-shadow: 0 2px 2px rgb(10 16 20 / 24%), 0 0 2px rgb(10 16 20 / 12%);

  @include containerFadeAnimation;

  .radial-chart-menu-item {
    cursor: pointer;

    &.active, &:hover {
      font-weight: bold;
      text-decoration: underline;
    }
  }

}

// Subject Overview
.subject-overview-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  gap: 1.5rem;
  padding-bottom: 1rem;

  @include media-breakpoint-down(xl) {
    flex-direction: column;
    justify-content: start;
    align-items: center;
  }
  @include media-breakpoint-down(lg) {
    flex-direction: row;
    justify-content: center;
    align-items: start;
  }
  @include media-breakpoint-down(md) {
    flex-direction: row;
    justify-content: center;
    align-items: start;
  }
  @include media-breakpoint-down(sm) {
    flex-direction: column;
    justify-content: start;
    align-items: center;
  }
}

.subject-overview-status {
  @media screen and (max-width: 1369px) {
    flex-direction: row !important;
    width: 100% !important;
    > span {
      width: fit-content !important;
    }
  }
}

.disable-touch-scroll {
  touch-action: pan-x;
  overflow-x: hidden;

  #root {
    touch-action: inherit;
  }
}

//Progress by month
.progress-by-month-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;

  svg {
    min-height: 400px
  }
}

.progress-by-month-indicator-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;

  .indicator {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    &:hover {
      background-color: $dim-200;
      @include btnAnimation;
    }

    &.inactive {
      -webkit-filter: grayscale(60%);
      -moz-filter: grayscale(60%);
      -ms-filter: grayscale(60%);
      -o-filter: grayscale(60%);
      filter: grayscale(60%);
    }

    .ind-color {
      width: 0.825rem;
      height: 0.825rem;
    }

    .ind-text {
      font-size: 0.825rem;
    }
  }
}


// Progress Report
.flow-container {
  flex-grow: 1;
  overflow-x: auto;
  overflow-y: hidden;
  height: 100%;

  &.whole-key-stage {
    overflow-y: auto;
    position: relative;
  }

  .score-visibility-toggle {
    display: flex;
    gap: 15px;
    justify-content: flex-end;
    min-width: 1755px;

    @include media-breakpoint-down(xxl) {
      min-width: 1655px;
    }

    @include media-breakpoint-down(lg) {
      min-width: 1455px;
    }

    @include media-breakpoint-down(sm) {
      flex-direction: column;
      gap: 10px;
      position: sticky;
      left: 0;
      min-width: auto;
    }

    .switch-component {
      flex-direction: row-reverse;
      padding-left: 0;

      input {
        margin-left: 0;
      }
    }
  }
}

.indicator-container {
  //fill: rgb(244 244 244);
  //border: 1px solid #cccccc;
  //stroke: #e0e0e0;
}