$font-range: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10;
.content-ui {
  //Base
  font-size: calc(#{$fs-6-size} + 4px) !important;

  @include media-breakpoint-down(md) {
    font-size: calc(#{$fs-6-size} + 2px) !important;
  }

  strong {
    font-weight: 500;

    //@include media-breakpoint-down(md) {
    //  font-weight: 600;
    //}
  }

  @each $size in $font-range {
    h#{$size}, .h#{$size} {
      font-weight: 500;
    }

    h#{$size}, .h#{$size}, .fs-#{$size} {
      font-size: calc(#{map-get($font-sizes, $size)} + 4px) !important;

      //@include media-breakpoint-down(md) {
      //  font-weight: 600;
      //}
      @include media-breakpoint-down(md) {
        font-size: calc(#{map-get($font-sizes, $size)} + 2px) !important
      }
    }
  }

  //Fib
  &.content-fib {
    //line-height: 3rem;
    line-height: 350%;
    word-break: break-word;

    > p {
      > .answer-space {
        display: inline;
        //padding-left: 0.325rem;
        //padding-right: 0.325rem;
        width: 0.325rem;

        &:first-child {
          width: 0;
          padding: 0 !important;
          overflow: hidden;
        }
      }
    }

  }

  //.fs-1 {
  //  //@include media-breakpoint-down(xl) {
  //    font-size: calc(#{$fs-1-response} + 4px) !important;
  //  //}
  //  @include media-breakpoint-down(md) {
  //    font-size: calc(#{$fs-1-response} + 2px) !important;
  //  }
  //}
  //
  //.fs-2 {
  //  //@include media-breakpoint-down(xl) {
  //    font-size: calc(#{$fs-2-response} + 4px) !important;
  //  //}
  //  @include media-breakpoint-down(md) {
  //    font-size: calc(#{$fs-2-response} + 2px) !important;
  //  }
  //}
  //
  //.fs-3 {
  //  //@include media-breakpoint-down(xl) {
  //    font-size: calc(#{$fs-3-response} + 4px) !important;
  //  //}
  //  @include media-breakpoint-down(md) {
  //    font-size: calc(#{$fs-2-response} + 2px) !important;
  //  }
  //}
  //
  //.fs-4 {
  //  //@include media-breakpoint-down(xl) {
  //    font-size: calc(#{$fs-4-response} + 4px) !important;
  //  //}
  //  @include media-breakpoint-down(md) {
  //    font-size: calc(#{$fs-2-response} + 2px) !important;
  //  }
  //}
}

.content-preview {
  width: 100%;
  display: grid;
  grid-template-columns:100%;
  justify-content: center;
  align-items: center;
  margin: auto 0;
  margin-bottom: 3rem;
  height: 90vh;
}

.exercise-content-form {
  border-collapse: separate;
  border-spacing: 0 1rem;

  tr {
    td {
      vertical-align: top;
    }

    td:first-child {
      width: 120px;
    }
  }
}